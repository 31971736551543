import {t} from 'ttag';

const getCheckerFeatureList = (
  packet_name: string,
  lang: string,
  isBitrix24: boolean
) => {
  const checks = (
    packet_name == 'Enterprise' ? 10000 : packet_name == 'Pro' ? 4000 : 1000
  ).toLocaleString(lang);

  return [
    {
      name: t`${checks} checks per month`,
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Checking in the contact card`,
      Start: true,
      Pro: true,
      Enterprise: true
    },
    {
      name: t`Checking in the business processes`,
      Start: false,
      Pro: true,
      Enterprise: true,
      skip: !isBitrix24
    },
    {
      name: t`Checking in Salesbot`,
      Start: false,
      Pro: true,
      Enterprise: true
    }
    // {
    //   name: t`Searching by name`,
    //   Start: false,
    //   Pro: true,
    //   Enterprise: true
    // }
  ].filter(item => !item.skip);
};

export default getCheckerFeatureList;
