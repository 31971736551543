import React from 'react';
import PropTypes from 'prop-types';

const Variable = ({variable, onSelect}) => (
  <button
    className="variable-list__item"
    type="button"
    onClick={() => onSelect(`%${variable}%`)}
  >
    {variable}
  </button>
);

Variable.propTypes = {
  variable: PropTypes.string,
  onSelect: PropTypes.func
};

Variable.defaultProps = {
  variable: '',
  onSelect: () => {}
};

export default Variable;
