import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {Input} from 'components/form/form';
import {isolateNumbers} from 'common/actions';

const PhoneCode = () => {
  const [field, , helpers] = useField<string>('phoneCode');

  return (
    <div>
      <Input
        {...field}
        maxLength={15}
        placeholder={t`Phone code`}
        type="tel"
        isMin
        onChange={value => helpers.setValue(isolateNumbers(value))}
      />
    </div>
  );
};

export default PhoneCode;
