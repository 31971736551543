import React from 'react';
import {t} from 'ttag';

import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from 'components/table/table';
import Icons from 'components/icons/icons';
import Wrapper from 'components/wrapper/wrapper';
import Text from 'components/text/text';
import * as AppEnv from 'app-env';

interface CheckRes {
  codeList: AppEnv.CheckCode[];
}

const CheckRes = ({codeList}: CheckRes) => (
  <Table>
    <TableHead>
      <TableCell>{t`Phone number`}</TableCell>
      <TableCell>{t`Registration`}</TableCell>
      <TableCell>{t`Differences`}</TableCell>
    </TableHead>

    <TableBody>
      {codeList.map((item, i) => (
        <TableRow key={i}>
          <TableCell>{item.phone}</TableCell>

          <TableCell>
            <Wrapper alignItems="center" gap={0} noWrap>
              {item.success ? <Icons.Check /> : <Icons.Times color="light" />}
              <Text color="dark">{item.success ? t`Yes` : t`No`}</Text>
            </Wrapper>
          </TableCell>

          <TableCell>
            <Wrapper alignItems="center" gap={0} noWrap>
              {item.success ? <Icons.Check /> : <Icons.Times color="light" />}
              <Text color="dark">{item.is_different ? t`Yes` : t`No`}</Text>
            </Wrapper>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default CheckRes;
