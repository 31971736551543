import React, {useRef} from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';
import {normalizePhoneNumber} from '../../../../../../../../common/actions.js';

const Phone = () => {
  const ref = useRef();

  const [field, meta, helpers] = useField('phone');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      ref={ref}
      type="tel"
      placeholder="Номер телефона"
      isMin
      isValid={isValid}
      onChange={value => setValue(normalizePhoneNumber(value))}
    />
  );
};

export default Phone;
