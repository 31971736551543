import React from 'react';
import ReactCodeInput from 'react-code-input';
import {useField} from 'formik';

import {isolateNumbers} from '../../../../../../../../../common/actions.js';

const Code = () => {
  const [field, meta, helpers] = useField('code');
  const isValid = !(meta.touched && meta.error);

  return (
    <ReactCodeInput
      {...field}
      className=" "
      fields={5}
      isValid={isValid}
      inputStyleInvalid={{borderColor: '#ff8242'}}
      onChange={value => helpers.setValue(isolateNumbers(value))}
    />
  );
};

export default Code;
