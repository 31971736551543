import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';

import Wrapper from '../wrapper/wrapper.jsx';
import Grid from '../grid/grid.jsx';
import Preloader from '../preloader/preloader.jsx';
import EmptyBox from '../empty-box/empty-box.jsx';
import {useAppContext} from '../../app-context.js';
import {useRequest, useWebSocket} from '../../common/hooks';
import {getTariffListReq} from '../../common/api/api.js';
import {
  getPipelineListReq as getAmocrmPipelineListReq,
  getManagerListReq as getAmoManagerListReq
} from '../../common/api/amo.js';
import {
  getManagerListReq as getAvitoManagerListReq,
  getPipelineListReq as getAvitoPipelineListReq
} from '../../common/api/avito.js';
import {
  getManagerListReq as getMondayManagerListReq,
  fetchBoards
} from '../../common/api/monday.js';
import {fetchPipelines} from '../../common/api/pipedrive.js';
import {getEmployeeListReq} from '../../common/api/api.js';

import {filterArray} from '../../common/actions.js';

import Advices from './advices/advices.jsx';
import Actions from './actions/actions.jsx';
import FormObserver from './form-observer/form-observer.jsx';
import Connection from './connection/connection.jsx';
import ConnectionListContext from './connection-list-context.js';

import {fetchStages} from '../../common/api/pipedrive.js';

const ConnectionList = ({
  client,
  connectionList,
  isConnector,
  onCreate,
  onError,
  setConnectionList
}) => {
  const {user, lang} = useAppContext();

  const [visibleConnectionList, setVisibleConnectionList] = useState();
  const [tariffList, setTariffList] = useState();

  const [stageList, setStageList] = useState();
  const [selectedConnectionIdList, setSelectedConnectionIdList] = useState([]);
  const [openConnectionId, setOpenConnectionId] = useState(-1);
  const [settingsId, setSettingsId] = useState(-1);

  const [connectionRequiringPreset, setConnectionRequiringPreset] =
    useState(-1);

  const [isSelection, setIsSelection] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [pipelineList, setPipelineList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  const {request, crmRequest} = useRequest();

  if (!client.isCustomer) {
    useWebSocket(connectionList, setConnectionList, connection => {
      setOpenConnectionId(-1);

      if (client.crm !== 'LK' && !isConnector) {
        setSettingsId(connection.id);
      }
    });
  }

  const getTariffList = async () => {
    const isIntegratorAndPartner = !!(user.is_integration && user.is_partner);

    const res = await request(
      getTariffListReq({
        country: user.country,
        crm: client.crm,
        domain: client.domain,
        isPartner: client.isCustomer || isIntegratorAndPartner ? 1 : 0,
        lang: lang
      })
    );

    setTariffList(res);
  };

  const getPipelineList = async () => {
    let pipelines_params = {};

    if (client.crm === 'AMO' || client.crm === 'TELEGRAM') {
      pipelines_params = getAmocrmPipelineListReq(client.domain);
    } else if (client.crm == 'AVITO') {
      pipelines_params = getAvitoPipelineListReq(client.domain);
    } else if (client.crm === 'MONDAY' || client.crm == 'TGMONDAY') {
      pipelines_params = fetchBoards(client.domain);
    } else if (client.crm == 'PIPEDRIVE') {
      pipelines_params = fetchPipelines(client.domain);
    }

    let pipelines = await crmRequest({
      ...pipelines_params,
      crm: client.crm,
      domain: client.isCustomer ? client.domain : undefined
    });

    if (client.crm == 'MONDAY' || client.crm == 'TGMONDAY') {
      pipelines = pipelines?.result || [];
    }

    if (pipelines.error_text) onError(pipelines.error_text);
    setPipelineList(Array.isArray(pipelines) ? pipelines : []);
  };

  const getManagerList = async () => {
    const req =
      client.crm == 'AMO' ? getAmoManagerListReq : getAvitoManagerListReq;

    const res = await crmRequest({
      ...req(client.domain),
      crm: client.crm,
      noNotice: client.isCustomer
    });

    setManagerList(res);
  };

  const getMondayManagerList = async () => {
    const res = await crmRequest({
      ...getMondayManagerListReq(client.domain),
      crm: client.crm,
      noNotice: client.isCustomer
    });

    setManagerList(res || []);
  };

  const getPipedriveManagerList = async () => {
    const res = await request(getEmployeeListReq(client.id));
    setManagerList(Array.isArray(res) ? res : []);
  };

  useEffect(() => {
    if (
      client.crm == 'AMO' ||
      client.crm == 'AVITO' ||
      client.crm == 'MONDAY' ||
      client.crm == 'TELEGRAM' ||
      client.crm == 'TGMONDAY' ||
      client.crm == 'PIPEDRIVE'
    ) {
      getPipelineList();

      if (
        client.crm === 'AMO' ||
        client.crm == 'AVITO' ||
        client.crm === 'TELEGRAM'
      ) {
        getManagerList();
      } else if (client.crm == 'MONDAY' || client.crm == 'TGMONDAY') {
        getMondayManagerList();
      } else if (client.crm == 'PIPEDRIVE') getPipedriveManagerList();
    }

    setIsMounted(true);
  }, []);

  const getStageList = async () => {
    const res = await crmRequest({
      crm: 'PIPEDRIVE',
      ...fetchStages(client.domain)
    });

    const newStageList = Array.isArray(res) ? res : [];
    setStageList(newStageList);
  };

  useEffect(() => {
    if (client.crm != 'PIPEDRIVE') return;
    getStageList();
  }, []);

  useEffect(() => {
    getTariffList();
  }, [user.country]);

  const handleChange = values => {
    let filteredConnectionList = connectionList
      ? [...connectionList]
      : undefined;

    if (values.search) {
      filteredConnectionList = filterArray(values.search, connectionList, [
        'id',
        'label',
        'phone',
        'name'
      ]);
    }

    if (values.category !== 'all') {
      filteredConnectionList = filteredConnectionList.filter(item => {
        switch (values.category) {
          case 'inactive':
            return !item.phone;
          case 'unconfigured':
            return item.is_configured === false;
          case 'unpaid':
            return !item.is_paid;
          default:
            return true;
        }
      });
    }

    setVisibleConnectionList(filteredConnectionList);
  };

  const isLoaded = visibleConnectionList && isMounted;

  return (
    <ConnectionListContext.Provider
      value={{
        connectionList,
        visibleConnectionList,
        client,
        tariffList,
        selectedConnectionIdList,
        openConnectionId,
        connectionRequiringPreset,
        isConnector,
        isSelection,
        pipelineList,
        managerList,
        setConnectionList,
        onCreate,
        setVisibleConnectionList,
        setSelectedConnectionIdList,
        setOpenConnectionId,
        setConnectionRequiringPreset,
        setIsSelection,
        setSettingsId,
        settingsId,
        stageList
      }}
    >
      <div>
        <Wrapper gap={16} isColumn>
          <Wrapper gap={16} isColumn>
            <Advices />

            <Formik initialValues={{search: '', category: 'all'}}>
              <>
                <Actions />
                <FormObserver onChange={handleChange} />
              </>
            </Formik>
          </Wrapper>

          {isLoaded ? (
            visibleConnectionList.length ? (
              <Grid elements={2}>
                {connectionList
                  .sort((a, b) => a.id - b.id)
                  .map(item => (
                    <Connection
                      key={item.id}
                      connection={item}
                      isVisible={
                        !!visibleConnectionList.find(
                          visible => visible.id === item.id
                        )
                      }
                    />
                  ))}
              </Grid>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Wrapper>
      </div>
    </ConnectionListContext.Provider>
  );
};

ConnectionList.propTypes = {
  client: PropTypes.shape({
    crm: PropTypes.string,
    domain: PropTypes.string,
    isCustomer: PropTypes.bool,
    id: PropTypes.number
  }),
  connectionList: PropTypes.array,
  isConnector: PropTypes.bool,
  onCreate: PropTypes.func,
  onError: PropTypes.func,
  setConnectionList: PropTypes.func
};

ConnectionList.defaultProps = {
  client: {},
  connectionList: null,
  isConnector: false,
  onCreate: () => {},
  onError: () => {},
  setConnectionList: () => {}
};

export default ConnectionList;
