import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {Input} from '../../../form/form.jsx';

const Searching = () => {
  const [field, , helpers] = useField('search');

  return (
    <Input
      {...field}
      placeholder={t`Search`}
      isSearch
      onChange={helpers.setValue}
    />
  );
};

export default Searching;
