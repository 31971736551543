import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from '../../text/text.jsx';

const Shift = ({options, value, onChange}) => (
  <div className="shift">
    {options.map((item, i) => (
      <button
        key={i}
        className={cn('shift__button', {
          shift__button_active: item.value === value
        })}
        onClick={() => onChange(item.value)}
      >
        <Text
          isSpan
          isSmall
        >
          {item.label}
        </Text>
      </button>
    ))}
  </div>
);

Shift.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({value: PropTypes.string, label: PropTypes.node})
  ),
  value: PropTypes.string,
  onChange: PropTypes.func
};

Shift.defaultProps = {
  options: [],
  value: '',
  onChange: () => {}
};

export default Shift;
