import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface MainContext {
  checkList: AppEnv.Check[] | undefined;
  setCheckList: AppEnv.SetState<AppEnv.Check[] | undefined>;
}

const MainContext = React.createContext<MainContext>({} as MainContext);

export const useMainContext = () => useContext(MainContext);
export default MainContext;
