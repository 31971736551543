import React, {useState, useEffect} from 'react';
import {t} from 'ttag';

import Section from '../../../components/section/section.jsx';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '../../../components/table/table.jsx';
import Background from '../../../components/background/background.jsx';
import Text from '../../../components/text/text.jsx';
import Button from '../../../components/button/button';
import Icons from '../../../components/icons/icons.jsx';
import Preloader from '../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../../app-context.js';
import {useRequest} from '../../../common/hooks';
import {getDenyListReq} from '../../../common/api/api.js';

import Denied from './denied/denied.jsx';
import Editor from './editor/editor.jsx';

const DenyList = () => {
  const {user} = useAppContext();

  const [denyList, setDenyList] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();

  const getDenyList = async () => {
    const res = await request(getDenyListReq(user.id));
    setDenyList(res);
  };

  useEffect(() => {
    getDenyList();
  }, []);

  return (
    <Section title={t`Deny list`}>
      {denyList ? (
        <>
          <Background style={{marginBottom: 16}}>
            <Text color="dark">
              {t`Messages from blocked contacts will not come to CRM.`}
            </Text>
          </Background>

          <Button style={{marginBottom: 16}} onClick={() => setIsOpen(true)}>
            <Icons.Plus />
            {t`Add a contact`}
          </Button>

          {denyList.length > 0 ? (
            <Table>
              <TableHead>
                <TableCell>{t`Date added`}</TableCell>
                <TableCell>{t`Contact`}</TableCell>
                <TableCell />
              </TableHead>

              <TableBody>
                {denyList
                  .sort((a, b) => b.date_add - a.date_add)
                  .map((item, i) => (
                    <TableRow key={i}>
                      <Denied
                        denied={item}
                        denyList={denyList}
                        setDenyList={setDenyList}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyBox />
          )}
        </>
      ) : (
        <Preloader />
      )}

      {isOpen && (
        <Editor setDenyList={setDenyList} onClose={() => setIsOpen(false)} />
      )}
    </Section>
  );
};

export default DenyList;
