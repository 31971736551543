import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';
import {normalizePhoneNumber} from '../../../../../../../../common/actions.js';

const Phone = () => {
  const [field, meta, helpers] = useField('phone');

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      type="tel"
      placeholder="Номер телефона"
      isValid={isValid}
      onChange={value => helpers.setValue(normalizePhoneNumber(value))}
    />
  );
};

export default Phone;
