import React from 'react';
import Partner from './partner/partner.jsx';
import {useAppContext} from '../../../../../app-context.js';
import Creating from './creating/creating.jsx';

const Partnership = () => {
  const {user} = useAppContext();
  const {partner} = user;

  return Object.keys(partner).length ? <Partner /> : <Creating />;
};

export default Partnership;
