import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';

const BankAuthority = () => {
  const [field, meta, helpers] = useField('bank_authority');

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      placeholder="Кем выдан"
      isValid={isValid}
      isMax
      onChange={helpers.setValue}
    />
  );
};

export default BankAuthority;
