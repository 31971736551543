import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Wrapper = ({
  alignItems,
  children,
  className,
  gap,
  isClickable,
  isColumn,
  justifyContent,
  mobileWrap,
  noWrap,
  onClick,
  style,
  width
}) => (
  <div
    className={cn('wrapper', {
      'wrapper_mobile-wrap': mobileWrap,
      [`wrapper_align-items-${alignItems}`]: alignItems,
      [`wrapper_gap-${gap}`]: gap,
      [`wrapper_justify-content-${justifyContent}`]: justifyContent,
      [`wrapper_width-${width}`]: width,
      [className]: className,
      wrapper_clickable: isClickable,
      wrapper_column: isColumn,
      wrapper_nowrap: noWrap
    })}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

Wrapper.propTypes = {
  alignItems: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  gap: PropTypes.number,
  isClickable: PropTypes.bool,
  isColumn: PropTypes.bool,
  justifyContent: PropTypes.string,
  mobileWrap: PropTypes.bool,
  noWrap: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  width: PropTypes.oneOf(['', 'max-content'])
};

Wrapper.defaultProps = {
  alignItems: '',
  children: <></>,
  className: '',
  gap: 8,
  isClickable: false,
  isColumn: false,
  justifyContent: '',
  mobileWrap: false,
  noWrap: false,
  onClick: () => {},
  style: {},
  width: ''
};

export default Wrapper;
