import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Text = ({
  children,
  className,
  color,
  maxHeight,
  isSpan,
  isSmall,
  isBold,
  isDedicated,
  isCrossing,
  noWrap,
  isOneLine,
  style
}) => {
  const htmlClassName = cn('text', {
    [className]: className,
    text_s: isSmall,
    [`text_${color}`]: color,
    [`text_max-height`]: maxHeight,
    text_bold: isBold,
    text_dedicated: isDedicated,
    text_crossing: isCrossing,
    text_nowrap: noWrap,
    'one-line': isOneLine
  });

  const htmlStyle = {
    ...style,
    maxHeight
  };

  return (
    <>
      {isSpan ? (
        <span
          className={htmlClassName}
          style={htmlStyle}
        >
          {children}
        </span>
      ) : (
        <p
          className={htmlClassName}
          style={htmlStyle}
        >
          {children}
        </p>
      )}
    </>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  maxHeight: PropTypes.number,
  isSpan: PropTypes.bool,
  isSmall: PropTypes.bool,
  isBold: PropTypes.bool,
  isDedicated: PropTypes.bool,
  isCrossing: PropTypes.bool,
  noWrap: PropTypes.bool,
  isOneLine: PropTypes.bool,
  style: PropTypes.object
};

Text.defaultProps = {
  children: <></>,
  className: '',
  color: null,
  maxHeight: null,
  isSpan: false,
  isSmall: false,
  isBold: false,
  isDedicated: false,
  isCrossing: false,
  noWrap: false,
  isOneLine: false,
  style: {}
};

export default Text;
