import React from 'react';
import Output from './output/output.jsx';
import LinkingPopup from './linking-popup/linking-popup.jsx';
import {useConnectionListContext} from '../../../connection-list-context.js';
import {useConnectionContext} from '../../connection-context.js';

const PhoneNumber = () => {
  const {openConnectionId} = useConnectionListContext();

  const {connection} = useConnectionContext();

  return (
    <>
      <Output />
      {openConnectionId === connection.id && <LinkingPopup />}
    </>
  );
};

export default PhoneNumber;
