import React, {useState, useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {t} from 'ttag';

import ErrorPage from 'components/error-page/error-page';
import * as AppEnv from 'app-env';

import CheckerContext from './checker-context';
import Home from './home/home';
import List from './list/list';
import Tab from './tab/tab';
import Userfields from './userfields/userfields';

interface UrlParams {
  data?: string;
}

const Checker = () => {
  const [data, setData] = useState<AppEnv.CheckerData>();
  const location = useLocation();

  const getData = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams: UrlParams = Object.fromEntries(urlSearchParams.entries());

    if (urlParams.data) {
      try {
        const res: AppEnv.CheckCode[] | AppEnv.CheckerData = JSON.parse(
          urlParams.data
        );

        setData(Array.isArray(res) ? {code: res} : res);
      } catch {
        //
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const isUserfields = location.pathname.includes('/userfields');

  return (
    <CheckerContext.Provider value={{data, setData}}>
      {isUserfields ? (
        <Userfields />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="list" element={<List />} />
          <Route path="tab" element={<Tab />} />
          <Route path="*" element={<ErrorPage title={t`Nothing to show`} />} />
        </Routes>
      )}
    </CheckerContext.Provider>
  );
};

export default Checker;
