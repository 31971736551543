import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import update from 'immutability-helper';

import Page from '../../components/page/page.jsx';
import QR from '../../components/qr/qr.jsx';
import Popup from '../../components/popup/popup.jsx';
import TelegramAuthentication from '../../components/telegram-authentication/telegram-authentication.jsx';
import Icons from '../../components/icons/icons.jsx';
import Preloader from '../../components/preloader/preloader.jsx';
import EmptyBox from '../../components/empty-box/empty-box.jsx';
import {useRequest, useWebSocket} from '../../common/hooks';
import {getConnectionStatusReq, getChatInfoReq} from '../../common/api/api.js';
import Text from '../../components/text/text.jsx';
import Link from '../../components/link/link.jsx';

const Scanner = () => {
  const {chat_key} = useParams();
  const [connectionList, setConnectionList] = useState([]);
  const {request} = useRequest();

  useWebSocket(connectionList, setConnectionList, () => {
    setConnectionList(prevValue =>
      update(prevValue, {[0]: {st: {state: {$set: 'CONNECTED'}}}})
    );
  });

  const getConnectionStatus = async () => {
    let res = await request(getConnectionStatusReq({chat_key}));

    if (
      !res.state ||
      res.state === 'init' ||
      res.state === 'authorizationStateWaitPhoneNumber' ||
      res.state === 'authorizationStateWaitOtherDeviceConfirmation'
    ) {
      res = await request(
        getConnectionStatusReq({
          chat_key,
          full: 1
        })
      );
    }

    setConnectionList(prevValue =>
      update(prevValue, {[0]: {st: {$set: _.pick(res, ['state', 'qrCode'])}}})
    );
  };

  const getConnection = async () => {
    const res = await request(getChatInfoReq(chat_key));
    if (res) setConnectionList([res]);
    getConnectionStatus();
  };

  useEffect(() => {
    getConnection();

    const timeout = setTimeout(() => {
      const [connection] = connectionList;

      if (
        (connection?.version == 'telegram' ||
          connection?.version == 'whatcrm') &&
        !connection?.st?.qrCode
      ) {
        getConnectionStatus();
      }
    }, 8000);

    return () => clearTimeout(timeout);
  }, []);

  const [connection] = connectionList;

  const qr =
    connection?.version == 'avito'
      ? `https://avito.whatcrm.net/v1/auth?state=${chat_key}`
      : connection?.version == 'telegram' || connection?.version == 'whatcrm'
        ? connection?.st?.qrCode
        : undefined;

  const isScanned =
    connection?.st &&
    (connection?.st.state === 'LOADING_SCREEN' ||
      connection?.st.state === 'READY' ||
      connection?.st.state === 'CONNECTED');

  const isPending =
    connection?.st &&
    (connection?.st.state === 'PENDING' ||
      connection?.st.state === 'NAVIGATION');

  return (
    <Page isCenter isTransparent>
      {connection?.st ? (
        Object.keys(connection?.st).length ? (
          isScanned ? (
            <Icons.Check style={{width: 164, height: 164}} />
          ) : connection?.st.state === 'authorizationStateWaitPassword' ? (
            <Popup withBackground={false} isClosable={false}>
              <TelegramAuthentication
                chat_key={chat_key}
                passwordHint={connection?.st.passwordHint}
              />
            </Popup>
          ) : (
            <>
              <QR
                qr={qr}
                isPending={isPending}
                style={{marginBottom: 16}}
                onClick={getConnectionStatus}
              />

              {connection?.version == 'avito' && (
                <Text>
                  <Link
                    href={`https://avito.whatcrm.net/v1/auth?state=${chat_key}`}
                    target="_blank"
                  >
                    {'avito.whatcrm.ru'}
                  </Link>
                </Text>
              )}
            </>
          )
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader isMin />
      )}
    </Page>
  );
};

export default Scanner;
