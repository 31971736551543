import React from 'react';
import PropTypes from 'prop-types';

const Background = ({children, style}) => (
  <div
    className="background"
    style={style}
  >
    {children}
  </div>
);

Background.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
};

Background.defaultProps = {
  children: <></>,
  style: {}
};

export default Background;
