import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import ReactCollapsible from 'react-collapsible';
import {
  TableRow,
  TableCell
} from '../../../../../../components/table/table.jsx';
import ConnectionList from '../../../../../../components/connection-list/connection-list.jsx';
import {useCustomerContext} from '../customer-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {getConnectionListReq} from '../../../../../../common/api/api.js';
import {
  getConnectionCondition,
  scrollToPosition
} from '../../../../../../common/actions.js';

const AdditionalInfo = ({isOpen, setError}) => {
  const {customer} = useCustomerContext();

  const [connectionList, setConnectionList] = useState();

  const ref = useRef();

  const {request} = useRequest();

  const getConnectionList = async () => {
    const res = await request(
      getConnectionListReq({integration_id: customer.id})
    );

    const instances = Object.entries(res).map(item => ({
      id: parseInt(item[0]),
      ...item[1],
      ...getConnectionCondition(item[1])
    }));

    setConnectionList(instances);
  };

  useEffect(() => {
    getConnectionList();
  }, []);

  useEffect(() => {
    if (isOpen) {
      const {top} = ref.current.getBoundingClientRect();
      scrollToPosition(top);
    }
  }, [isOpen]);

  return (
    <TableRow ref={ref} isOpen={isOpen} isCollapsible={true}>
      <TableCell isFullWidth>
        <ReactCollapsible transitionTime={200} open={isOpen}>
          <ConnectionList
            connectionList={connectionList}
            client={{
              crm: customer.type_crm,
              domain: customer.domain,
              isCustomer: true,
              id: customer.id
            }}
            setConnectionList={setConnectionList}
            onError={setError}
          />
        </ReactCollapsible>
      </TableCell>
    </TableRow>
  );
};

AdditionalInfo.propTypes = {
  isOpen: PropTypes.bool,
  setError: PropTypes.func
};

AdditionalInfo.defaultProps = {
  isOpen: false,
  setError: () => {}
};

export default AdditionalInfo;
