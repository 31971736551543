import React, {useEffect, useState} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import {fetchShortCode, putShortCode} from 'common/api/pipedrive';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import PhoneCodeEditor, {
  PhoneCodeEditorValues
} from '../components/phone-code-editor/phone-code-editor';

interface Res {
  short_code?: string;
}

const Pipedrive = () => {
  const {setNotificationList, user} = useAppContext();
  const [shortCode, setShortCode] = useState<string>();
  const {crmRequest} = useRequest();

  const getShortCode = async () => {
    const res: Res = await crmRequest({
      crm: user.crm,
      ...fetchShortCode(user.domain)
    } as any); // eslint-disable-line

    setShortCode(res.short_code || '');
  };

  useEffect(() => {
    getShortCode();
  }, []);

  const handleSubmit = async (values: PhoneCodeEditorValues) => {
    const phoneCode = values.phoneCode.trim();

    const res: Res = await crmRequest({
      crm: user.crm,
      ...putShortCode(user.domain, phoneCode || null)
    } as any); // eslint-disable-line

    if (!Object.keys(res).length) return true;

    // eslint-disable-next-line
    setNotificationList((prevValue: any) =>
      update(prevValue, {
        $push: [
          {
            title: res.short_code
              ? t`The phone code has been updated`
              : t`The phone code has been removed`
          }
        ]
      })
    );

    return true;
  };

  return <PhoneCodeEditor phoneCode={shortCode} onSubmit={handleSubmit} />;
};

export default Pipedrive;
