import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {SingleSelect} from 'components/form/form';
import * as Title from 'components/title/title';
import {useConnectionListContext} from 'components/connection-list/connection-list-context';

interface PipelineSettings {
  pipe_id?: number;
  status_id?: number;
}

const PipeId = () => {
  const [field, , helpers] = useField<PipelineSettings[]>('pipeline_settings');
  const {pipelineList} = useConnectionListContext();

  const handleChange = (value: number) => {
    // eslint-disable-next-line
    const {statuses} = pipelineList?.find((item: any) => item.id == value) || {
      statuses: []
    };

    helpers.setValue([{pipe_id: value, status_id: statuses[0]?.id}]);
  };

  // eslint-disable-next-line
  const options = pipelineList?.map((item: any) => ({
    label: item.name,
    value: item.id
  }));

  const value = field.value[0]?.pipe_id;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Pipeline`}</Title.H3>

      <SingleSelect
        options={options}
        placeholder={t`Pipeline`}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default PipeId;
