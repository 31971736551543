import React, {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import Popup from '../../components/popup/popup.jsx';
import Start from './start/start.jsx';
import Registration from './registration/registration.jsx';
import Verification from './verification/verification.jsx';
import Page from '../../components/page/page.jsx';
import {useAppContext} from '../../app-context.js';
import LoginContext from './login-context.js';

const Login = () => {
  const {user} = useAppContext();

  const [email, setEmail] = useState();
  const [isRegistered, setIsRegistered] = useState();

  const location = useLocation();

  return (
    <>
      {user.domain ? (
        <Navigate to={{pathname: '/', search: location.search}} />
      ) : (
        <LoginContext.Provider
          value={{
            email,
            isRegistered,
            setEmail,
            setIsRegistered
          }}
        >
          <Page isCenter isTransparent>
            <Popup isClosable={false} withBackground={false}>
              {email ? (
                isRegistered ? (
                  <Verification onCancel={() => setEmail()} />
                ) : (
                  <Registration />
                )
              ) : (
                <Start />
              )}
            </Popup>
          </Page>
        </LoginContext.Provider>
      )}
    </>
  );
};

export default Login;
