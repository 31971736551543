import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../title/title.jsx';
import {Input} from '../../../../../../form/form.jsx';

const Domain = () => {
  const [field, meta, helpers] = useField('domain');

  const isValid = !(meta.touched && meta.error);

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Domain`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Domain`}
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </>
  );
};

export default Domain;
