import React, {forwardRef, useRef} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/button/button';
import Icons from 'components/icons/icons';

const Field = forwardRef(
  (
    {
      isClearable,
      isDisabled,
      isMin,
      isMulti,
      isOpen,
      isPreviewBold,
      onChange,
      onClick,
      placeholder,
      preview
    },
    ref
  ) => {
    const clearRef = useRef();
    const isClearShown = isClearable && !!preview;

    const handleClick = e => {
      if (e.target != clearRef.current && !clearRef.current?.contains(e.target))
        onClick();
    };

    return (
      <div
        className={cn('field select__field', {
          field_active: isOpen,
          select__field_disabled: isDisabled,
          select__field_min: isMin
        })}
        ref={ref}
        onClick={handleClick}
      >
        <span
          className={cn('one-line', {
            select__placeholder: !preview,
            select__preview_bold: isPreviewBold,
            select__preview_flex: isMulti,
            select__preview_min: preview && isMin,
            select__preview: preview
          })}
        >
          {preview || placeholder}
        </span>

        {isClearShown && (
          <Button
            color="transparent"
            ref={clearRef}
            style={{marginRight: -8}}
            onClick={() => onChange(undefined)}
          >
            <Icons.Times />
          </Button>
        )}
      </div>
    );
  }
);

Field.propTypes = {
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMin: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOpen: PropTypes.bool,
  isPreviewBold: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  preview: PropTypes.node
};

Field.defaultProps = {
  isClearable: false,
  isDisabled: false,
  isMin: false,
  isMulti: false,
  isOpen: false,
  isPreviewBold: false,
  onChange: () => {},
  onClick: () => {},
  placeholder: '',
  preview: ''
};

Field.displayName = 'Field';
export default Field;
