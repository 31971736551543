import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../components/title/title.jsx';
import {Input} from '../../../../../../components/form/form.jsx';

const CompanyName = () => {
  const [field, , helpers] = useField('title');

  const {setValue} = helpers;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Company name`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Company name`}
        onChange={setValue}
      />
    </div>
  );
};

export default CompanyName;
