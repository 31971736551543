import React from 'react';
import {useField} from 'formik';
import Label from './label/label.jsx';
import {SingleSelect} from '../../../../../../components/form/form.jsx';
import {useCustomerListContext} from '../../customer-list-context.js';

const CRM = () => {
  const {crmList} = useCustomerListContext();

  const [field, , helpers] = useField('crm');

  const {setValue} = helpers;

  const options = Object.entries(crmList).map(([key, values]) => ({
    value: key,
    label: <Label crm={values} />
  }));

  return (
    <SingleSelect
      {...field}
      options={options}
      onChange={setValue}
    />
  );
};

export default CRM;
