import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';

const BankDate = () => {
  const [field, meta, helpers] = useField('bank_date');

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      type="date"
      placeholder="Дата выдачи"
      isValid={isValid}
      onChange={helpers.setValue}
    />
  );
};

export default BankDate;
