import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../components/dropdown/dropdown.jsx';
import Field from '../components/field/field.jsx';
import GroupList from './group-list/group-list.jsx';
import OptionList from './option-list/option-list.jsx';
import Select from '../components/select/select.jsx';
import {useOnClickOutside, useOnKeyDown} from '../../../../common/hooks';
import {isIncludes} from '../../../../common/actions.js';

import SingleSelectContext from './single-select-context.js';
import {optionsPropTypes} from './prop-types.js';

const SingleSelect = ({
  isClearable,
  isDisabled,
  isFullWidth,
  isMin,
  isPreviewBold,
  isSearchAvailable,
  onChange,
  onClick,
  options,
  placeholder,
  style,
  value
}) => {
  const [isOpen, setIsOpen] = useState();
  const [matchingOptionIds, setMatchingOptionIds] = useState([]);

  const fieldRef = useRef();
  const ref = useRef();

  useOnClickOutside({onClick: () => setIsOpen(false), ref});
  useOnKeyDown({onEscape: () => setIsOpen(false)});

  const handleClick = () => {
    onClick();
    if (isDisabled) return;

    setIsOpen(!isOpen);
    setMatchingOptionIds([]);
  };

  const handleSearch = searchQuery =>
    setMatchingOptionIds(
      options.reduce((acc, item) => {
        if (isIncludes(item.label, searchQuery)) {
          acc.push(item.value);
        }

        return acc;
      }, [])
    );

  const handleChange = value => {
    onChange(value);
    setIsOpen(false);
  };

  const groupList = options.reduce((acc, item) => {
    if ('group' in item && !acc.includes(item.group)) acc.push(item.group);
    return acc;
  }, []);

  const selected = options.find(item => item.value === value);
  const preview = selected ? selected.preview || selected.label : null;

  return (
    <Select ref={ref} style={style}>
      <Field
        placeholder={placeholder}
        preview={preview}
        ref={fieldRef}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMin={isMin}
        isOpen={isOpen}
        isPreviewBold={isPreviewBold}
        onChange={handleChange}
        onClick={handleClick}
      />

      {isOpen && (
        <Dropdown
          fieldRef={fieldRef}
          isFullWidth={isFullWidth}
          isSearchAvailable={isSearchAvailable}
          onSearch={handleSearch}
        >
          <SingleSelectContext.Provider
            value={{handleChange, matchingOptionIds, value}}
          >
            {groupList.length > 0 ? (
              <GroupList groupList={groupList} options={options} />
            ) : (
              <OptionList options={options} />
            )}
          </SingleSelectContext.Provider>
        </Dropdown>
      )}
    </Select>
  );
};

SingleSelect.propTypes = {
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isMin: PropTypes.bool,
  isPreviewBold: PropTypes.bool,
  isSearchAvailable: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: optionsPropTypes,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SingleSelect.defaultProps = {
  inMin: false,
  isClearable: false,
  isDisabled: false,
  isFullWidth: false,
  isPreviewBold: false,
  isSearchAvailable: false,
  onChange: () => {},
  onClick: () => {},
  options: [],
  placeholder: '',
  style: {},
  value: ''
};

export default SingleSelect;
