import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import Info from './info/info.jsx';
import Settings from './settings/settings.jsx';
import UserList from './user-list/user-list.jsx';
import Nav, {NavBar, NavTab} from '../../../../components/nav/nav.jsx';
import Preloader from '../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../components/empty-box/empty-box.jsx';
import IntegratorContext from './integrator-context.js';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks';
import {getIntegratorReq} from '../../../../common/api/api.js';

const Integrator = () => {
  const {user} = useAppContext();

  const [integrator, setIntegrator] = useState();
  const [tab, setTab] = useState('info');

  const {request} = useRequest();

  const getIntegrator = async () => {
    const res = await request(getIntegratorReq(user.id));
    setIntegrator(res);
  };

  useEffect(() => {
    getIntegrator();
  }, []);

  const options = [
    {
      value: 'info',
      label: t`Basic information`
    },
    {
      value: 'settings',
      label: t`Settings`
    },
    {
      value: 'user-list',
      label: t`Users`
    }
  ];

  return (
    <IntegratorContext.Provider value={{integrator, setIntegrator}}>
      <div>
        {user.widget_code ? (
          integrator ? (
            Object.keys(integrator).length ? (
              <Nav options={options} value={tab} onChange={setTab}>
                <NavBar style={{marginBottom: 16}} />

                <NavTab tab="info">
                  <Info />
                </NavTab>

                <NavTab tab="settings">
                  <Settings />
                </NavTab>

                <NavTab tab="user-list">
                  <UserList />
                </NavTab>
              </Nav>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )
        ) : (
          <EmptyBox />
        )}
      </div>
    </IntegratorContext.Provider>
  );
};

export default Integrator;
