import PropTypes from 'prop-types';

export const optionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.node,
    preview: PropTypes.node,
    group: PropTypes.string
  })
);
