import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Select from '../components/select/select.jsx';
import Field from '../components/field/field.jsx';
import Dropdown from '../components/dropdown/dropdown.jsx';
import OptionList from './option-list/option-list.jsx';
import {useOnClickOutside, useOnKeyDown} from '../../../../common/hooks';
import {isIncludes} from '../../../../common/actions.js';

const MultiSelect = ({
  options,
  placeholder,
  isDisabled,
  isSearchAvailable,
  style,
  onChange,
  onClick
}) => {
  const [matchingOptionIds, setMatchingOptionIds] = useState([]);
  const [isOpen, setIsOpen] = useState();

  const ref = useRef();
  const fieldRef = useRef();

  useOnClickOutside({ref, onClick: () => setIsOpen(false)});
  useOnKeyDown({onEscape: () => setIsOpen(false)});

  const handleClick = () => {
    onClick();

    if (!isDisabled) {
      setIsOpen(!isOpen);
      setMatchingOptionIds([]);
    }
  };

  const handleSearch = searchQuery =>
    setMatchingOptionIds(
      options.reduce((acc, item) => {
        if (
          isIncludes(item.comment, searchQuery) ||
          isIncludes(item.label, searchQuery)
        ) {
          acc.push(item.value);
        }

        return acc;
      }, [])
    );

  const handleChange = ({value, isChecked}) => {
    const index = options.findIndex(item => item.value === value);

    onChange(
      update(options, {
        [index]: {
          isChecked: {
            $set: isChecked
          }
        }
      })
    );
  };

  const toggleAll = isChecked =>
    onChange(options.map(item => ({...item, isChecked: isChecked})));

  const firstSelected = options.find(item => item.isChecked)?.label;
  const totalSelected = options.filter(item => item.isChecked).length;

  const preview = firstSelected ? (
    <>
      <span className="one-line">{firstSelected}</span>

      {totalSelected > 1 && <span>{`+ ${totalSelected - 1}`}</span>}
    </>
  ) : null;

  return (
    <Select ref={ref} style={style}>
      <Field
        ref={fieldRef}
        preview={preview}
        placeholder={placeholder}
        isOpen={isOpen}
        isDisabled={isDisabled}
        isMulti
        onClick={handleClick}
      />

      {isOpen && (
        <Dropdown
          fieldRef={fieldRef}
          isMulti
          isSearchAvailable={isSearchAvailable}
          onSearch={handleSearch}
        >
          <OptionList
            matchingOptionIds={matchingOptionIds}
            options={options}
            onChange={handleChange}
            toggleAll={toggleAll}
          />
        </Dropdown>
      )}
    </Select>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
      isChecked: PropTypes.bool
    })
  ),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSearchAvailable: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

MultiSelect.defaultProps = {
  options: [],
  placeholder: '',
  isDisabled: false,
  isSearchAvailable: false,
  style: {},
  onChange: () => {},
  onClick: () => {}
};

export default MultiSelect;
