import React from 'react';
import {useFormikContext, useField} from 'formik';
import {SearchSelect} from '../../../../../../../form/form.jsx';

const INN = () => {
  const {setFieldValue} = useFormikContext();
  const [field, meta, helpers] = useField('inn');

  const {value} = field;
  const {error, touched} = meta;
  const {setValue} = helpers;

  const handleChange = party => {
    const {data} = party;
    const address = data.address.data;

    const values = {
      name: data.name?.short_with_opf,
      cpp: data.kpp || '',
      inn: data.inn,
      ambassadors: data.management?.name || data.name?.full,
      contract_owner: data.management?.name || data.name?.full,
      j_city: address.city_with_type || '',
      j_country: address.country || '',
      j_house:
        [address.house_type, address.house, address.block_type, address.block]
          .filter(item => item)
          .join(' ') || '',
      j_index: address.postal_code || '',
      j_region: address.region_with_type || '',
      j_street: address.street_with_type || ''
    };

    Object.entries(values).map(item => setFieldValue(item[0], item[1]));
  };

  const isValid = !(error && touched);

  return (
    <SearchSelect
      input={{
        ...field,
        inputMode: 'tel',
        placeholder: 'ИНН',
        isMin: true,
        isValid,
        maxLength: 12,
        onChange: setValue
      }}
      query={value}
      path="party"
      onChange={handleChange}
    />
  );
};

export default INN;
