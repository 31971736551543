import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {t} from 'ttag';

import Popup from 'components/popup/popup.jsx';
import * as Title from 'components/title/title.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';
import Text from 'components/text/text.jsx';
import LocaleString from 'components/locale-string/locale-string.jsx';
import UnsubscribeGuide from 'components/unsubscribe-guide/unsubscribe-guide.jsx';
import Link from 'components/link/link.jsx';
import {Checkbox} from 'components/form/form.jsx';
import Line from 'components/line/line.jsx';
import Button from 'components/button/button';
import {useAppContext} from 'components/../app-context.js';
import {usePaymentContext} from 'components/payment/payment-context';
import {useTransitionContext} from '../transition-context';
import * as AppEnv from 'app-env';

import getNextPaymentDate from './get-next-payment-date';

interface Confirmation {
  isSubscription: boolean;
  onClick: (arg: boolean) => void;
}

const Confirmation = ({isSubscription, onClick}: Confirmation) => {
  const {user, licenseAgreement, lang} = useAppContext();
  const {selectedTariffId, connectionIdList, tariffList, client} =
    usePaymentContext();
  const {method} = useTransitionContext();

  const [isAccepted, setIsAccepted] = useState(false);

  const selectedTariff = Object.values(tariffList || {})
    .map(item => Object.values(item))
    .flat()
    .find(item => (item as AppEnv.TariffOption).id === selectedTariffId) as
    | AppEnv.TariffOption
    | undefined;

  const isDiscount = user.is_review === 2 && !client.isCustomer;

  const connectionСount = connectionIdList?.length || 1;

  const summa = selectedTariff
    ? isDiscount
      ? parseFloat(selectedTariff.summa_review)
      : parseFloat(selectedTariff.summa)
    : 0;

  const amount = connectionСount * summa;

  const label = (
    <>
      {t`I accept the`}{' '}
      <Link href={licenseAgreement} target="_blank">
        {t`license agreement`}
      </Link>
    </>
  );

  const isOneTimePaymentGuideShown = !isSubscription && lang != 'ru';
  const system = method === 'telphin' ? undefined : method || 'stripe';
  const isSubscriptionGuideShown = isSubscription && system;

  return (
    <Popup onClick={() => onClick(false)}>
      <Title.H1 style={{marginBottom: isOneTimePaymentGuideShown ? 8 : 16}}>
        {isSubscription
          ? t`Confirmation of the start of a subscription`
          : t`Confirmation of payment`}
      </Title.H1>

      {isSubscriptionGuideShown ? (
        <Wrapper gap={8} isColumn style={{marginBottom: 16}}>
          <Text>
            {t`You have selected the recurring payment option. Your subscription will automatically renew once every set period specified in your rate plan, and your account will be charged accordingly.`}
          </Text>

          <Text>
            {t`You will have access to our service for the duration of your subscription.`}
          </Text>

          <Text>
            {t`Next payment`}:{' '}
            <LocaleString
              amount={amount}
              type="currency"
              currency={selectedTariff?.currency}
            />
            ,{' '}
            {moment
              .unix(getNextPaymentDate(selectedTariff?.quantity || 30))
              .format('L')}
            .
          </Text>

          <UnsubscribeGuide system={system} />
        </Wrapper>
      ) : (
        isOneTimePaymentGuideShown && (
          <Text style={{marginBottom: 16}}>
            {t`Note — For one-time payments, our platform does not provide invoices or receipts in PDF format. Thank you for your understanding.`}
          </Text>
        )
      )}

      <Checkbox
        label={label}
        isChecked={isAccepted}
        isMaxWidth
        onChange={setIsAccepted}
      />

      <Line />

      <Wrapper>
        <Button isDisabled={!isAccepted} onClick={() => onClick(true)}>
          {t`Continue`}
        </Button>

        <Button color="white" onClick={() => onClick(false)}>
          {t`Cancel`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

Confirmation.propTypes = {
  isSubscription: PropTypes.bool,
  onClick: PropTypes.func
};

Confirmation.defaultProps = {
  isSubscription: false,
  onClick: () => {}
};

export default Confirmation;
