import React, {useState} from 'react';
import {t} from 'ttag';

import {NavTab} from 'components/nav/nav';
import Section from 'components/section/section';
import PaymentHistory from 'components/payment-history/payment-history';
import SubscriptionList from 'components/subscription-list/subscription-list';
import * as AppEnv from 'app-env';

interface Billing {
  paymentHistory: AppEnv.Payment[] | undefined;
  subscriptionList: AppEnv.Subscription[] | undefined;
}

const Billing = ({paymentHistory, subscriptionList}: Billing) => {
  const [tab, setTab] = useState('payment-history');

  const options = [
    {label: t`Payment history`, value: 'payment-history'},
    {label: t`Subscriptions`, value: 'subscription-list'}
  ];

  const navParams = {onChange: setTab, options, value: tab};

  return (
    <Section navParams={navParams} title={t`Billing`}>
      <NavTab tab="payment-history">
        <PaymentHistory paymentHistory={paymentHistory} />
      </NavTab>

      <NavTab tab="subscription-list">
        <SubscriptionList subscriptionList={subscriptionList} />
      </NavTab>
    </Section>
  );
};

export default Billing;
