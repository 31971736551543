import React from 'react';
import {t} from 'ttag';

import * as Title from 'components/title/title';
import Line from 'components/line/line';
import Link from 'components/link/link';
import List from 'components/list/list';
import Wrapper from 'components/wrapper/wrapper';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useLinkingContext} from '../../linking-context';
import {useRequest} from 'common/hooks';
import {getConnectionStatusReq} from 'common/api/api';

interface Code {
  code: string;
}

const Code = ({code}: Code) => {
  const {connection} = useConnectionContext();
  const {setLinkingType} = useLinkingContext();

  const {request} = useRequest();

  const handleClick = async () => {
    const res = await request(
      getConnectionStatusReq({chat_key: connection.chat_key, full: 1})
    );

    if (Object.keys(res).length) setLinkingType('scanning');
  };

  const list = [
    t`Open WhatsApp on your phone`,
    t`Go to Settings → Linked Devices → Link a Device → Link with phone number`,
    t`Enter the code`
  ];

  return (
    <>
      <Title.H2 style={{marginBottom: 16}}>
        {t`Enter the code in WhatsApp`}
      </Title.H2>

      <p className="code" style={{marginBottom: 16}}>
        <span>{code.slice(0, 4)}</span>
        <span>{code.slice(4)}</span>
      </p>

      <List items={list} />

      <Line />

      <Wrapper justifyContent="space-between">
        <div />

        <Link isFake onClick={handleClick}>
          {t`Log in by QR code`}
        </Link>
      </Wrapper>
    </>
  );
};

export default Code;
