import React from 'react';
import {useField} from 'formik';
import countries from 'i18n-iso-countries';
import {t} from 'ttag';
import * as Title from '../../../../../../components/title/title.jsx';
import {SingleSelect} from '../../../../../../components/form/form.jsx';
import {useAppContext} from '../../../../../../app-context.js';

const Country = () => {
  const {lang} = useAppContext();

  const [field, , helpers] = useField('country');

  const {setValue} = helpers;

  // eslint-disable-next-line
  countries.registerLocale(require(`i18n-iso-countries/langs/${lang}.json`));

  const options = Object.entries(countries.getNames(lang)).map(item => ({
    value: item[0],
    label: item[1]
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Country`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Country`}
        isSearchAvailable
        onChange={setValue}
      />
    </div>
  );
};

export default Country;
