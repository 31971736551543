import React from 'react';
import cn from 'classnames';
import * as Title from 'components/title/title.jsx';
import LocaleString from 'components/locale-string/locale-string.jsx';
import {useAppContext} from 'components/../app-context.js';
import {usePaymentContext} from 'components/payment/payment-context';
import {useGetTariffName} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Option {
  option: AppEnv.TariffOption;
}

const Option = ({option}: Option) => {
  const {user} = useAppContext();
  const {client, selectedTariffId, setSelectedTariffId} = usePaymentContext();

  const getTariffName = useGetTariffName();

  const isDiscount = user.is_review === 2 && !client.isCustomer;
  const amount = isDiscount ? option.summa_review : option.summa;

  return (
    <div
      className={cn('tariff-plan__options-item', {
        'tariff-plan__options-item_selected': option.id === selectedTariffId
      })}
      onClick={() => setSelectedTariffId(option.id)}
    >
      <Title.H3 color="green" style={{marginBottom: 8}}>
        {getTariffName(option)}
      </Title.H3>

      <Title.H2>
        <LocaleString
          amount={amount}
          type="currency"
          currency={option.currency}
        />
      </Title.H2>
    </div>
  );
};

export default Option;
