import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {t} from 'ttag';

import {SaveButton} from '../../../../../../../button/button';
import * as Title from '../../../../../../../title/title.jsx';
import Article from '../../../../../../../article/article.jsx';
import Line from '../../../../../../../line/line.jsx';
import Link from '../../../../../../../link/link.jsx';
import Name from '../../../../../name/name.jsx';
import Text from '../../../../../../../text/text.jsx';
import Wrapper from '../../../../../../../wrapper/wrapper.jsx';
import {useConnectionContext} from '../../../../../connection-context.js';
import {useLinkingContext} from '../linking-context.js';
import {useRequest} from '../../../../../../../../common/hooks';
import {
  destroyInstanceReq,
  getConnectionStatusReq,
  setAuthenticationPhoneNumberReq
} from '../../../../../../../../common/api/api.js';
import {isolateNumbers} from '../../../../../../../../common/actions.js';

import Phone from './phone/phone.jsx';
import Code from './code/code';

const Login = () => {
  const {connection} = useConnectionContext();
  const {setLinkingType} = useLinkingContext();

  const [code, setCode] = useState('');
  const {request} = useRequest();

  const handleSubmit = async values => {
    if (connection.version == 'telegram') {
      await request(destroyInstanceReq(connection.chat_key));
      await request(getConnectionStatusReq({chat_key: connection.chat_key}));
    }

    const res = await request(
      setAuthenticationPhoneNumberReq(
        connection.chat_key,
        isolateNumbers(values.phone)
      )
    );

    if (res.code) setCode(res.code);
    return true;
  };

  const initialValues = {
    phone: ''
  };

  const validationSchema = yup.object().shape({
    phone: yup.string().required()
  });

  return (
    <>
      <Name style={{marginBottom: 16}} />

      {code ? (
        <Code code={code} />
      ) : (
        <>
          <Article>
            <Title.H2 style={{marginBottom: 8}}>
              {t`Enter the phone number`}
            </Title.H2>

            <Text style={{marginBottom: 8}}>
              {connection.version == 'telegram'
                ? t`Telegram will send a code to this number.`
                : connection.version == 'whatcrm'
                  ? t`WhatsApp will generate a code for that number.`
                  : ''}
            </Text>
          </Article>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Phone />
              <Line />

              <Wrapper justifyContent="space-between" alignItems="flex-end">
                <SaveButton>{t`Continue`}</SaveButton>

                <Link isFake onClick={() => setLinkingType('scanning')}>
                  {t`Log in by QR code`}
                </Link>
              </Wrapper>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};

export default Login;
