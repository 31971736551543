import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useFormikContext} from 'formik';

import {useConnectionListContext} from '../connection-list-context.js';

const FormObserver = ({onChange}) => {
  const {connectionList} = useConnectionListContext();
  const {values} = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [connectionList]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return <></>;
};

FormObserver.propTypes = {
  onChange: PropTypes.func
};

FormObserver.defaultProps = {
  onChange: () => {}
};

export default FormObserver;
