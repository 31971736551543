import React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import * as yup from 'yup';
import {Formik, Form} from 'formik';

import {SaveButton} from '../../../../../../button/button';
import Background from '../../../../../../background/background.jsx';
import Grid from '../../../../../../grid/grid.jsx';
import Line from '../../../../../../line/line.jsx';
import Text from '../../../../../../text/text.jsx';
import Wrapper from '../../../../../../wrapper/wrapper.jsx';
import {useAppContext} from '../../../../../../../app-context.js';
import {useRequest} from '../../../../../../../common/hooks';
import {createTelphinClientReq} from '../../../../../../../common/api/api.js';
import {trimValues} from '../../../../../../../common/actions.js';

import Ambassadors from './ambassadors/ambassadors.jsx';
import Bank from './bank/bank.jsx';
import BIK from './bik/bik.jsx';
import ContractOwner from './contract-owner/contract-owner.jsx';
import CPP from './cpp/cpp.jsx';
import Docs from './docs/docs.jsx';
import FAddress from './f-address/f-address.jsx';
import INN from './inn/inn.jsx';
import JAddress from './j-address/j-address.jsx';
import Mail from './mail/mail.jsx';
import Name from './name/name.jsx';
import Phone from './phone/phone.jsx';
import Score from './score/score.jsx';

const LegalEntity = () => {
  const {user, setUser} = useAppContext();
  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const getAddress = prefix =>
      [
        trimmedValues[`${prefix}_index`],
        trimmedValues[`${prefix}_country`],
        trimmedValues[`${prefix}_region`],
        trimmedValues[`${prefix}_city`],
        trimmedValues[`${prefix}_street`],
        trimmedValues[`${prefix}_house`],
        trimmedValues[`${prefix}_flat`]
      ].join(', ');

    const data = {
      ..._.pick(trimmedValues, [
        'ambassadors',
        'bank',
        'bik',
        'contract_owner',
        'docs',
        'inn',
        'mail',
        'name',
        'phone',
        'score'
      ]),
      cpp: trimmedValues.cpp.length ? trimmedValues.cpp : '111111111',
      f_address: getAddress(trimmedValues.equal_addresses ? 'j' : 'f'),
      j_address: getAddress('j')
    };

    const res = await request(createTelphinClientReq(user.id, data));
    const code = res.client_code || res.client;

    if (code) {
      setUser(
        update(user, {
          telphin_code: {$set: code},
          telphin_email: {$set: data.mail}
        })
      );
    }

    return true;
  };

  const initialValues = {
    ambassadors: '',
    bank: '',
    bik: '',
    contract_owner: '',
    cpp: '',
    docs: '3',
    equal_addresses: false,
    f_city: '',
    f_country: '',
    f_flat: '',
    f_house: '',
    f_index: '',
    f_region: '',
    f_street: '',
    inn: '',
    j_city: '',
    j_country: '',
    j_flat: '',
    j_house: '',
    j_index: '',
    j_region: '',
    j_street: '',
    mail: '',
    name: '',
    phone: '',
    score: ''
  };

  const validationSchema = yup.object().shape({
    ambassadors: yup.string().trim().required(),
    bank: yup.string().trim().required(),
    bik: yup.string().trim().required(),
    contract_owner: yup.string().trim().required(),
    cpp: yup
      .string()
      .trim()
      .matches(/^(\d{0}|\d{9})$/),
    docs: yup.string().trim().required(),
    equal_addresses: yup.bool(),
    f_city: yup.string().when('equal_addresses', {
      is: false,
      then: () => yup.string().trim().required()
    }),
    f_country: yup.string().when('equal_addresses', {
      is: false,
      then: () => yup.string().trim().required()
    }),
    f_flat: yup.string(),
    f_house: yup.string().when('equal_addresses', {
      is: false,
      then: () => yup.string().trim().required()
    }),
    f_index: yup.string().when('equal_addresses', {
      is: false,
      then: () => yup.string().trim().required().length(6)
    }),
    f_region: yup.string().when('equal_addresses', {
      is: false,
      then: () => yup.string().trim().required()
    }),
    f_street: yup.string().when('equal_addresses', {
      is: false,
      then: () => yup.string().trim().required()
    }),
    inn: yup
      .string()
      .matches(/^(\d{10}|\d{12})$/)
      .required(),
    j_city: yup.string().trim().required(),
    j_country: yup.string().trim().required(),
    j_flat: yup.string(),
    j_house: yup.string().trim().required(),
    j_index: yup.string().trim().required().length(6),
    j_region: yup.string().trim().required(),
    j_street: yup.string().trim().required(),
    mail: yup.string().email().required(),
    name: yup.string().trim().required(),
    phone: yup
      .string()
      .test(
        value =>
          value &&
          ((value[0] === '+' && value.length > 11) ||
            (value[0] !== '+' && value.length > 10))
      )
      .required(),
    score: yup.string().trim().required()
  });

  return (
    <>
      <Background style={{marginBottom: 16}}>
        <Text color="dark">
          {
            'Вы можете выполнить поиск по ИНН и БИК. Если ККП отсутствует, оставьте поле пустым.'
          }
        </Text>
      </Background>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <Grid elements={2} gap={16}>
              <Wrapper alignItems="flex-start" gap={16} isColumn>
                <Docs />

                <Ambassadors />
                <ContractOwner />

                <Wrapper alignItems="center" noWrap>
                  <Mail />
                  <Phone />
                </Wrapper>
              </Wrapper>

              <Wrapper alignItems="flex-start" gap={16} isColumn>
                <Name />

                <Wrapper alignItems="center" noWrap>
                  <CPP />
                  <INN />
                </Wrapper>

                <Wrapper alignItems="center" noWrap>
                  <BIK />
                  <Bank />
                </Wrapper>

                <Score />
              </Wrapper>
            </Grid>

            <JAddress />
            <FAddress />

            <Line style={{margin: 0}} />

            <SaveButton>Отправить</SaveButton>
          </Wrapper>
        </Form>
      </Formik>
    </>
  );
};

export default LegalEntity;
