import {t} from 'ttag';
import axios from 'axios';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface RequestParams {
  data?: object;
  method: 'post';
  params?: object;
}

const useCheckerRequest = () => {
  const {user, setNotificationList} = useAppContext();

  const notify = (text = t`Something went wrong.`) => {
    setNotificationList(
      (
        prevValue: any // eslint-disable-line
      ) => update(prevValue, {$push: [{title: t`Error`, text}]})
    );

    return null;
  };

  const request = async <T>(
    path: string,
    {data, method, params}: RequestParams
  ): Promise<T | null> => {
    const key = user.domain.includes('bitrix24')
      ? 'REACT_APP_BWACHECKER'
      : 'REACT_APP_AWACHECKER';

    const instance = axios.create({baseURL: process.env[key]});

    const res = await instance(path, {data, method, params})
      .then(res => {
        if (
          res.data.code &&
          ('error_code' in res.data.code || 'error_text' in res.data.code)
        )
          return notify(res.data.code.error_text);
        else if ('success' in res.data && !res.data.success)
          return notify(res.data.message);

        return res.data;
      })
      .catch(e => notify(`${t`Error`} ${e.code}`));

    return res;
  };

  const postCheck = (domain: string, phone: string) =>
    request<AppEnv.Check>('check', {data: {domain, phone}, method: 'post'});

  const postCreate = (
    domain: string,
    phone: string,
    placement: 'CONTACT' | 'DEAL' | 'LEAD'
  ) =>
    request<AppEnv.CheckEntity>('create', {
      data: {domain, phone, placement},
      method: 'post'
    });

  interface PostSettings {
    message: string | null;
    success: 0 | 1;
  }

  const postSettings = (
    domain: string,
    placement: 'bizproc' | 'call' | 'phone',
    value: boolean
  ) =>
    request<PostSettings>('settings', {
      data: {domain, placement, value: value.toString()},
      method: 'post'
    });

  return {
    postCheck,
    postCreate,
    postSettings
  };
};

export default useCheckerRequest;
