import React from 'react';
import PropTypes from 'prop-types';

const Video = ({src, style}) => (
  <video
    className="video"
    src={src}
    controls
    style={style}
  />
);

Video.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object
};

Video.defaultProps = {
  src: '',
  style: {}
};

export default Video;
