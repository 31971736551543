import React, {useState} from 'react';
import update from 'immutability-helper';
import moment from 'moment';
import {t} from 'ttag';
import Entry from './entry/entry.jsx';
import Grid from '../../../../../grid/grid.jsx';
import Line from '../../../../../line/line.jsx';
import Button from '../../../../../button/button';
import Confirmation from '../../../../../confirmation/confirmation.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {cancelPartnershipReq} from '../../../../../../common/api/api.js';

const Partner = () => {
  const {user, setUser, setNotificationList} = useAppContext();
  const {partner} = user;

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {request} = useRequest();

  const cancelPartnership = async () => {
    const res = await request(cancelPartnershipReq(user.id));

    if (res.success) {
      setUser(prevValue =>
        update(prevValue, {
          partner: {$set: []}
        })
      );

      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [{title: t`You have refused a partnership`}]
        })
      );
    }
  };

  return (
    <>
      <Grid
        elements={2}
        gap="24-56"
        width="max-content"
        style={{marginBottom: 40}}
      >
        <Entry label={t`Company`} value={partner.title} />

        <Entry label={t`Domain`} value={partner.domain} />

        <Entry label={t`Email`} value={partner.email} />

        <Entry label={t`Phone number`} value={partner.phone} />

        <Entry
          label={t`Start date of partnership`}
          value={moment.unix(partner.date_add).format('L')}
        />
      </Grid>

      <Line />

      <Button onClick={() => setIsConfirmationOpen(true)}>
        {t`Refuse a partnership`}
      </Button>

      {isConfirmationOpen && (
        <Confirmation
          title={t`Are you sure you want to refuse a partnership?`}
          onClick={res =>
            res ? cancelPartnership() : setIsConfirmationOpen(false)
          }
        />
      )}
    </>
  );
};

export default Partner;
