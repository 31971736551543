import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import CustomerList from './customer-list/customer-list.jsx';
import Integrator from './integrator/integrator.jsx';
import Section from '../../../components/section/section.jsx';
import {NavTab} from '../../../components/nav/nav.jsx';
import {useAppContext} from '../../../app-context.js';

const Partners = () => {
  const {user} = useAppContext();

  const [tab, setTab] = useState();

  const isCustomerListShown =
    user.is_partner > 0 &&
    (user.domain != 'metallobazans.bitrix24.ru' || user.is_admin > 0);

  useEffect(() => {
    setTab(
      isCustomerListShown
        ? 'customer-list'
        : user.is_integration
          ? 'integrator'
          : ''
    );
  }, []);

  const title =
    isCustomerListShown && user.is_integration
      ? t`For partners and integrators`
      : isCustomerListShown
        ? t`Partner program`
        : user.is_integration
          ? t`Integrator`
          : null;

  const options = [];

  if (isCustomerListShown) {
    options.push({
      value: 'customer-list',
      label: t`Partner program`
    });
  }

  if (user.is_integration) {
    options.push({
      value: 'integrator',
      label: t`Integrator`
    });
  }

  const navParams = {
    options: options,
    value: tab,
    onChange: setTab
  };

  return (
    <>
      {title && (
        <Section title={title} navParams={navParams}>
          {isCustomerListShown && (
            <NavTab tab="customer-list">
              <CustomerList />
            </NavTab>
          )}

          {!!user.is_integration && (
            <NavTab tab="integrator">
              <Integrator />
            </NavTab>
          )}
        </Section>
      )}
    </>
  );
};

export default Partners;
