import React from 'react';
import {useLocation, Link} from 'react-router-dom';
import Menu from './menu/menu.jsx';
import {SingleSelect} from '../../components/form/form.jsx';
import {useAppContext} from '../../app-context.js';
import {putInLocalStorage} from '../../common/actions.js';

const Header = () => {
  const {pathname} = useLocation();

  const {user, lang} = useAppContext();

  const handleLangChange = value => {
    putInLocalStorage('lang', value);
    window.location.reload();
  };

  const langOptions = [
    {
      value: 'en',
      label: 'English'
    },
    {
      value: 'de',
      label: 'Deutsche'
    },
    {
      value: 'es',
      label: 'Español'
    },
    {
      value: 'pt',
      label: 'Português'
    },
    {
      value: 'ru',
      label: 'Русский'
    },
    {
      value: 'tr',
      label: 'Türkçe'
    },
    {
      value: 'uk',
      label: 'Українська'
    }
  ];

  const isLogoLink = pathname === '/' || pathname === '/login';
  const isMenuShown = user.domain && user.crm != 'CHECKER';

  return (
    <header className="header">
      {isLogoLink ? (
        <div className="header__logo" />
      ) : (
        <Link to="/">
          <div className="header__logo" />
        </Link>
      )}

      <div className="header__actions">
        {user.crm === 'LK' && (
          <SingleSelect
            options={langOptions}
            value={lang}
            isMin
            isFullWidth
            onChange={handleLangChange}
          />
        )}

        {isMenuShown && <Menu />}
      </div>
    </header>
  );
};

export default Header;
