import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Page = ({children, isCenter, isTransparent}) => (
  <div
    className={cn('page', {
      page_center: isCenter,
      page_transparent: isTransparent
    })}
  >
    {children}
  </div>
);

Page.propTypes = {
  children: PropTypes.node,
  isCenter: PropTypes.bool,
  isTransparent: PropTypes.bool
};

Page.defaultProps = {
  children: <></>,
  isCenter: false,
  isTransparent: false
};

export default Page;
