import React, {useRef} from 'react';
import {t} from 'ttag';
import {useField} from 'formik';
import * as Title from '../../../../../../components/title/title.jsx';
import {Input} from '../../../../../../components/form/form.jsx';
import {normalizeUrl} from '../../../../../../common/actions.js';

const WebhookAddress = () => {
  const ref = useRef();

  const [field, , helpers] = useField('webhook');

  const {setValue} = helpers;

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Webhook address`}</Title.H3>

      <Input
        {...field}
        ref={ref}
        placeholder="https://"
        style={{marginBottom: 16}}
        onChange={value => setValue(normalizeUrl(value))}
      />
    </>
  );
};

export default WebhookAddress;
