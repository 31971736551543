import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import QRCode from 'qrcode';
import * as Title from '../../../../title/title.jsx';
import Article from '../../../../article/article.jsx';
import Text from '../../../../text/text.jsx';
import Wrapper from '../../../../wrapper/wrapper.jsx';
import QR from '../../../../qr/qr.jsx';
import Link from '../../../../link/link.jsx';
import {useAppContext} from '../../../../../app-context.js';

const Notifications = () => {
  const {user} = useAppContext();

  const [qr, setQr] = useState();

  const url = `https://t.me/whatcrm_bot?start=${user.ukey}`;

  useEffect(() => {
    QRCode.toDataURL(url).then(res => setQr(res));
  }, []);

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>
        {t`Notifications in Telegram`}
      </Title.H3>

      <Article style={{marginBottom: 16}}>
        <Text>{t`Set up the bot to receive important notifications about your connections.`}</Text>
      </Article>

      <Wrapper
        alignItems="center"
        gap={8}
        width="max-content"
        isColumn
      >
        <QR qr={qr} />

        <Link
          href={url}
          target="_blank"
        >
          t.me/whatcrm_bot
        </Link>
      </Wrapper>
    </>
  );
};

export default Notifications;
