import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icons from '../icons/icons.jsx';

const Closer = ({color, style, onClick}) => (
  <button
    className={cn('closer', {
      [`closer_${color}`]: color
    })}
    type="button"
    style={style}
    onClick={onClick}
  >
    <Icons.Times />
  </button>
);

Closer.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

Closer.defaultProps = {
  color: 'light-blue',
  style: {},
  onClick: () => {}
};

export default Closer;
