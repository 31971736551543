import React from 'react';
import PropTypes from 'prop-types';
import NavContext from './nav-context.js';

const Nav = props => {
  const {children} = props;
  const params = Object.assign({}, props);

  delete params.children;

  return <NavContext.Provider value={params}>{children}</NavContext.Provider>;
};

Nav.propTypes = {
  children: PropTypes.node,
  options: PropTypes.array,
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func
};

Nav.defaultProps = {
  children: <></>,
  options: [],
  value: '',
  onClick: () => {},
  onChange: () => {}
};

export {default as NavBar} from './nav-bar/nav-bar.jsx';
export {default as NavTab} from './nav-tab/nav-tab.jsx';

export default Nav;
