import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import * as Title from '../title/title.jsx';
import Image from '../image/image.jsx';

const EmptyBox = ({children, title = t`Nothing to show`}) => (
  <div className="placeholder">
    <Title.H2 style={{marginBottom: children && 12}}>{title}</Title.H2>

    {children}

    <Image
      src="/static/img/illustrations/empty-box.svg"
      alt={t`Nothing to show`}
    />
  </div>
);

EmptyBox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

EmptyBox.defaultProps = {};

export default EmptyBox;
