import React from 'react';
import {useField} from 'formik';
import {c} from 'ttag';

import {Input} from '../../../../../form/form';

const Label = () => {
  const [field, , helpers] = useField('label');

  return (
    <Input
      {...field}
      placeholder={c('connection').t`Name`}
      maxLength={56}
      onChange={helpers.setValue}
    />
  );
};

export default Label;
