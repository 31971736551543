import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import Wrapper from 'components/wrapper/wrapper.jsx';
import * as Title from 'components/title/title.jsx';
import {SingleSelect} from 'components/form/form.jsx';
import Tooltip from 'components/tooltip/tooltip.jsx';
import Text from 'components/text/text.jsx';
import {useConnectionContext} from 'components/connection-list/connection/connection-context.js';
import {useSettingsContext} from '../../../settings-context.js';

const Strategy = () => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField('strategy');
  const [pipelineIdField] = useField('pipeline_id');

  const options = [
    {
      value: 'uniform',
      label: t`Uniform`
    },
    {
      value: 'random',
      label: t`Random`
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 6}}>{t`Strategy`}</Title.H3>

      <Wrapper alignItems="center" noWrap>
        <SingleSelect
          {...field}
          options={options}
          value={
            connection.is_limited || pipelineIdField.value == 0
              ? undefined
              : field.value
          }
          placeholder={t`Strategy`}
          isDisabled={connection.is_limited || pipelineIdField.value == 0}
          onChange={helpers.setValue}
          onClick={() => connection.is_limited && setIsAlertOpen(true)}
        />

        <Tooltip>
          <Text style={{marginBottom: 8}}>
            {t`Uniform — a new request is created for the manager who has the fewest deals.`}
          </Text>

          <Text>
            {t`Random — a new request is created for a random manager, regardless of the number of deals.`}
          </Text>
        </Tooltip>
      </Wrapper>
    </div>
  );
};

export default Strategy;
