import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Text from '../../text/text.jsx';
import Wrapper from '../../wrapper/wrapper.jsx';

const Checkbox = ({
  comment,
  isChecked,
  isDisabled,
  isMaxWidth,
  label,
  onChange,
  style
}) => {
  const id = _.uniqueId('checkbox-');

  return (
    <label
      className={cn('checkbox__label', {
        'checkbox__label_max-width': isMaxWidth,
        checkbox__label_disabled: isDisabled
      })}
      htmlFor={id}
      style={style}
    >
      <Wrapper noWrap>
        <input
          id={id}
          type="checkbox"
          checked={isChecked}
          disabled={isDisabled}
          onChange={e => onChange(e.target.checked)}
        />

        <div className="checkbox" />

        <Wrapper gap={2} style={{marginTop: 2}} isColumn>
          {label && (
            <Text color="dark" isSpan>
              {label}
            </Text>
          )}

          {comment && (
            <Text isSmall isSpan>
              {comment}
            </Text>
          )}
        </Wrapper>
      </Wrapper>
    </label>
  );
};

Checkbox.propTypes = {
  comment: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMaxWidth: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  style: PropTypes.object
};

Checkbox.defaultProps = {
  comment: '',
  isChecked: false,
  isDisabled: false,
  isMaxWidth: false,
  label: <></>,
  onChange: () => {},
  style: {}
};

export default Checkbox;
