import React from 'react';
import PropTypes from 'prop-types';

export const TableHead = ({children}) => (
  <thead>
    <tr className="table__head">{children}</tr>
  </thead>
);

TableHead.propTypes = {
  children: PropTypes.node
};
