import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {SingleSelect} from 'components/form/form';
import * as Title from 'components/title/title';
import Text from 'components/text/text';
import Tooltip from 'components/tooltip/tooltip';
import Wrapper from 'components/wrapper/wrapper';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useSettingsContext} from '../../../settings-context';

const Strategy = () => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField<'random' | 'uniform'>('strategy');

  const options = [
    {label: t`Uniform`, value: 'uniform'},
    {label: t`Random`, value: 'random'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 6}}>{t`Strategy`}</Title.H3>

      <Wrapper alignItems="center" noWrap>
        <SingleSelect
          {...field}
          options={options}
          placeholder={t`Strategy`}
          value={connection.is_limited ? undefined : field.value}
          isDisabled={connection.is_limited}
          onChange={helpers.setValue}
          onClick={() => connection.is_limited && setIsAlertOpen(true)}
        />

        <Tooltip>
          <Text style={{marginBottom: 8}}>
            {t`Uniform — a new request is created for the manager who has the fewest deals.`}{' '}
            {t`The counter starts after the setting is turned on and resets after it is turned off.`}
          </Text>

          <Text>
            {t`Random — a new request is created for a random manager, regardless of the number of deals.`}
          </Text>
        </Tooltip>
      </Wrapper>
    </div>
  );
};

export default Strategy;
