import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {Input} from 'components/form/form';
import * as Title from 'components/title/title';
import {normalizePhoneNumber} from 'common/actions';

const Phone = () => {
  const [field, meta, helpers] = useField<string>('phone');
  const isValid = !(meta.error && meta.touched);

  return (
    <div className="checker">
      <Title.H3 style={{marginBottom: 8}}>{t`Phone number`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Phone number`}
        type="tel"
        isValid={isValid}
        onChange={value => helpers.setValue(normalizePhoneNumber(value))}
      />
    </div>
  );
};

export default Phone;
