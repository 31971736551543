import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../../../../../../components/image/image.jsx';

const Label = ({crm}) => (
  <>
    <Image
      src={crm.icon}
      style={{height: 12, margin: '0 8px -1px 0'}}
    />

    {crm.name}
  </>
);

Label.propTypes = {
  crm: PropTypes.shape({
    icon: PropTypes.string,
    name: PropTypes.string
  })
};

Label.defaultProps = {
  crm: {}
};

export default Label;
