import React, {useState, useEffect} from 'react';
import moment from 'moment';

import ErrorPage from 'components/error-page/error-page';
import Preloader from 'components/preloader/preloader';
import ProductsSection from 'components/products-section/products-section';
import {useAppContext} from 'app-context';
import {usePaymentHistory, useRequest, useSubscriptionList} from 'common/hooks';
import {getDashboardReq} from 'common/api/api';
import * as AppEnv from 'app-env';

import Billing from './billing/billing';
import Main from './main/main';
import Settings from './settings/settings';

const getLastPayment = (paymentHistory: AppEnv.Payment[]) => {
  let res: AppEnv.Payment | undefined = undefined;

  for (const item of paymentHistory) {
    if (!item.is_deleted && (!res || item.date_yslug > res.date_yslug))
      res = item;
  }

  return res;
};

const getLastSubscription = (subscriptionList: AppEnv.Subscription[]) => {
  let res: AppEnv.Subscription | undefined = undefined;

  for (const item of subscriptionList) {
    if (
      !res ||
      moment(item.date_add).format('X') > moment(res.date_add).format('X')
    )
      res = item;
  }

  return res;
};

const Home = () => {
  const {lang, user} = useAppContext();

  const [dashboard, setDashboard] =
    useState<Partial<AppEnv.Dashboard> | null>();

  const [payment, setPayment] = useState<
    AppEnv.Payment | AppEnv.Subscription
  >();

  const {request} = useRequest();
  const paymentHistory = usePaymentHistory(dashboard?.integration?.id);
  const subscriptionList = useSubscriptionList(dashboard?.integration?.id);

  useEffect(() => {
    if (!paymentHistory || !subscriptionList) return;

    const lastPayment = getLastPayment(paymentHistory);
    const lastSubscription = getLastSubscription(subscriptionList);

    const lastPaymentDate = lastPayment?.date_yslug || 0;

    const lastSubscriptionDate = parseInt(
      moment(lastSubscription?.date_add || 0).format('X')
    );

    const pay =
      lastPaymentDate > lastSubscriptionDate ? lastPayment : lastSubscription;

    setPayment(pay);
  }, [paymentHistory, subscriptionList]);

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = async () => {
    // if (
    //   !user.domain.includes('amocrm.ru') &&
    //   !user.domain.includes('kommo.com')
    // )
    //   return setDashboard({});

    const res: Partial<AppEnv.Dashboard> = await request(
      getDashboardReq('CHECKER', user.domain, lang)
    );

    setDashboard(Object.keys(res).length ? res : null);
  };

  const isBitrix24 = user.domain.includes('bitrix24');

  return (
    <>
      {typeof dashboard != 'undefined' ? (
        dashboard ? (
          <div className="home">
            <Main dashboard={dashboard} payment={payment} />
            <Settings />

            {!isBitrix24 && (
              <Billing
                paymentHistory={paymentHistory}
                subscriptionList={subscriptionList}
              />
            )}

            <ProductsSection />
          </div>
        ) : (
          <ErrorPage title="403 Forbidden" />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Home;
