import React, {useEffect, useState} from 'react';
import {t} from 'ttag';

import {Toggle} from 'components/form/form';
import {useAppContext} from 'app-context';
import {useCheckerContext} from 'pages/checker/checker-context';
import {useCheckerRequest} from 'common/hooks';

const Call = () => {
  const {user} = useAppContext();
  const {data} = useCheckerContext();

  const [isChecked, setIsChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {postSettings} = useCheckerRequest();

  useEffect(() => {
    if (typeof data?.call_card == 'boolean') setIsChecked(data.call_card);
  }, [data?.call_card]);

  const handleChange = async (value: boolean) => {
    setIsFetching(true);
    const res = await postSettings(user.domain, 'call', value);

    setIsFetching(false);
    if (!res) return;

    setIsChecked(value);
  };

  return (
    <Toggle
      label={t`Display a tab during a call`}
      isChecked={isChecked}
      isDisabled={isFetching}
      onChange={value => handleChange(value)}
    />
  );
};

export default Call;
