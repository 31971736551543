import React from 'react';
import {Formik, Form} from 'formik';
import {t} from 'ttag';
import update from 'immutability-helper';

import Button, {SaveButton} from 'components/button/button';
import Line from 'components/line/line';
import Wrapper from 'components/wrapper/wrapper';
import {useAppContext} from 'app-context';
import {useConnectionListContext} from 'components/connection-list/connection-list-context';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useRequest} from 'common/hooks';
import {updateConnectionReq} from 'common/api/api';

import Webhook from './webhook/webhook';

interface Values {
  webhook: string;
}

const Advanced = () => {
  const {setNotificationList} = useAppContext();
  const {setSettingsId} = useConnectionListContext();
  const {connection} = useConnectionContext();

  const {request} = useRequest();

  const handleSubmit = async (values: Values) => {
    const res = await request(
      updateConnectionReq({
        chat_key: connection.chat_key,
        data: {webhook: values.webhook}
      })
    );

    if (!Object.keys(res)) return true;

    // eslint-disable-next-line
    setNotificationList((prevValue: any) =>
      update(prevValue, {
        $push: [
          {
            text: t`The settings have been successfully saved.`,
            title: connection.label || `${t`Connection`} ${connection.id}`
          }
        ]
      })
    );

    setSettingsId(-1);
    return true;
  };

  const initialValues: Values = {webhook: connection.webhook || ''};

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <Webhook />
        <Line />

        <Wrapper>
          <SaveButton>{t`Save`}</SaveButton>

          <Button color="white" onClick={() => setSettingsId(-1)}>
            {t`Cancel`}
          </Button>
        </Wrapper>
      </Form>
    </Formik>
  );
};

export default Advanced;
