import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Wrapper from '../../wrapper/wrapper.jsx';
import * as Title from '../../title/title.jsx';
import {NavBar} from '../../nav/nav.jsx';
import Button from '../../button/button';
import Icons from '../../icons/icons.jsx';

const Trigger = ({title, isNav, isOpen, setIsOpen, handleOpen}) => {
  return (
    <div
      className={cn('section__head', {
        section__head_nav: isNav
      })}
      onClick={() => (isOpen ? setIsOpen(false) : handleOpen())}
    >
      <Wrapper isColumn gap={8} style={{overflow: 'hidden'}}>
        <Title.H2>{title}</Title.H2>

        {isNav && <NavBar linkLike={true} isUnderscoreHidden={!isOpen} />}
      </Wrapper>

      <Button color="transparent">
        <Icons.ArrowDown isRotate={isOpen} />
      </Button>
    </div>
  );
};

Trigger.propTypes = {
  title: PropTypes.string,
  isNav: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  handleOpen: PropTypes.func
};

Trigger.defaultProps = {
  title: '',
  navParams: false,
  isOpen: false,
  setIsOpen: () => {},
  handleOpen: () => {}
};

export default Trigger;
