import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';

const Bank = () => {
  const [field, meta, helpers] = useField('bank');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      placeholder="Банк"
      isMin
      isValid={isValid}
      onChange={setValue}
    />
  );
};

export default Bank;
