import React, {useEffect} from 'react';

import CheckRes from '../components/check-res/check-res';
import Placeholder from '../components/placeholder/placeholder';
import {useCheckerContext} from '../checker-context';

const Userfields = () => {
  const {data} = useCheckerContext();

  useEffect(() => {
    const app = document.querySelector('.app') as HTMLDivElement | null;
    if (!app) return;

    app.style.padding = '16px 0';
    app.style.background = '#fff';
  }, []);

  return (
    <div className="checker__userfields">
      {data?.code ? <CheckRes codeList={data.code} /> : <Placeholder />}
    </div>
  );
};

export default Userfields;
