import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../components/title/title.jsx';
import {Input} from '../../../../../../components/form/form.jsx';

const PhoneNumber = () => {
  const [field, , helpers] = useField('phone');

  const {setValue} = helpers;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Phone number`}</Title.H3>

      <Input
        {...field}
        type="tel"
        placeholder={t`Phone number`}
        onChange={setValue}
      />
    </div>
  );
};

export default PhoneNumber;
