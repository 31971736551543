import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

interface TableRow {
  children: React.ReactNode;
  isCollapsible?: boolean;
  isOpen?: boolean;
}

export const TableRow = forwardRef<HTMLTableRowElement, TableRow>(
  ({children, isOpen, isCollapsible}, ref) => (
    <tr
      className={cn('table__row', {
        table__row_collapsible: isCollapsible,
        table__row_open: isOpen
      })}
      ref={ref}
    >
      {children}
    </tr>
  )
);

TableRow.displayName = 'TableRow';

TableRow.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isCollapsible: PropTypes.bool
};

TableRow.defaultProps = {
  children: <></>,
  isOpen: false,
  isCollapsible: false
};
