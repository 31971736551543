import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import Wrapper from '../../../../../../../wrapper/wrapper.jsx';
import * as Title from '../../../../../../../title/title.jsx';
import {SingleSelect} from '../../../../../../../form/form.jsx';
import Tooltip from '../../../../../../../tooltip/tooltip.jsx';
import Text from '../../../../../../../text/text.jsx';
import {useConnectionContext} from '../../../../../connection-context.js';
import {useSettingsContext} from '../../../../settings-context.js';
import {usePipelineSettingsContext} from '../pipeline-settings-context.js';

const Strategy = () => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();
  const {status_id, isUnassembledStage} = usePipelineSettingsContext();

  const [field, , helpers] = useField('strategy');
  const {value} = field;
  const {setValue} = helpers;

  const options = [
    {
      value: 'uniform',
      label: t`Uniform`
    },
    {
      value: 'random',
      label: t`Random`
    }
  ];

  const isDisabled = !status_id || isUnassembledStage || connection.is_limited;

  return (
    <div>
      <Title.H3 style={{marginBottom: 6}}>{t`Strategy`}</Title.H3>

      <Wrapper
        alignItems="center"
        noWrap
      >
        <SingleSelect
          options={options}
          value={!isDisabled ? value : null}
          placeholder={t`Strategy`}
          isDisabled={isDisabled}
          onChange={setValue}
          onClick={() => connection.is_limited && setIsAlertOpen(true)}
        />

        <Tooltip>
          <Text style={{marginBottom: 8}}>
            {t`Uniform — a new request is created for the manager who has the fewest deals.`}
          </Text>

          <Text>
            {t`Random — a new request is created for a random manager, regardless of the number of deals.`}
          </Text>
        </Tooltip>
      </Wrapper>
    </div>
  );
};

export default Strategy;
