import React from 'react';
import {t} from 'ttag';

import Entry from '../entry/entry';
import {useConnectionListContext} from 'components/connection-list/connection-list-context';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useSettingsContext} from '../../settings-context';

const Avito = () => {
  const {managerList, pipelineList} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig} = useSettingsContext();

  const pipeline = pipelineList?.find(
    (item: any) => item.id == connectionConfig?.pipeline_settings?.[0]?.pipe_id // eslint-disable-line
  );

  const status = pipeline?.statuses?.find(
    (
      item: any // eslint-disable-line
    ) => item.id == connectionConfig?.pipeline_settings?.[0]?.status_id
  );

  const {manager_id} = connectionConfig.pipeline_settings?.[0] || {};

  // eslint-disable-next-line
  const manager = managerList.find((item: any) => item[0] === manager_id);

  const managers = manager?.[1] ? (
    <span style={{display: 'flex'}}>
      <span className="one-line">{manager[1]}</span>

      {connectionConfig.pipeline_settings?.length > 1 && (
        <span>{` +${connectionConfig.pipeline_settings.length - 1}`}</span>
      )}
    </span>
  ) : undefined;

  return (
    <>
      <Entry
        label={t`Pipeline`}
        value={pipeline?.name || t`A selection is required`}
        color={!pipeline ? 'yellow' : undefined}
      />

      <Entry label={t`Deal stage`} value={status?.name} />

      {!connection.is_limited && (
        <Entry
          label={t`Strategy`}
          value={
            connectionConfig.strategy === 'uniform'
              ? t`Uniform`
              : connectionConfig.strategy === 'random'
                ? t`Random`
                : undefined
          }
        />
      )}

      {!connection.is_limited && <Entry label={t`Managers`} value={managers} />}
    </>
  );
};

export default Avito;
