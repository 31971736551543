import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Toggle} from '../../../../../../form/form.jsx';

const Closed = () => {
  const [field, , helpers] = useField('closed');
  const {value} = field;
  const {setValue} = helpers;

  return (
    <Toggle
      label={t`Create deals if there are closed ones`}
      isChecked={value}
      onChange={setValue}
    />
  );
};

export default Closed;
