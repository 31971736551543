import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {t} from 'ttag';

import ErrorPage from '../../components/error-page/error-page';

const InstallationFailed = () => {
  const [URLSearchParams] = useSearchParams();
  const {error_code, error_text} = Object.fromEntries(URLSearchParams);

  const title = `${error_code || ''}${error_code ? ' ' : ''}${
    error_text || t`Installation failed`
  }`;

  return <ErrorPage title={title} />;
};

export default InstallationFailed;
