import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from '../../../../../text/text.jsx';

const Entry = ({label, value, color}) => (
  <>
    {value && (
      <div
        className={cn('entry', {
          [`entry_${color}`]: color
        })}
      >
        <Text
          isSmall
          style={{marginBottom: 4}}
        >
          {label}
        </Text>

        <Text color="dark">{value}</Text>
      </div>
    )}
  </>
);

Entry.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  color: PropTypes.oneOf(['', 'yellow'])
};

Entry.defaultProps = {
  label: '',
  value: '',
  color: ''
};

export default Entry;
