import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../title/title.jsx';
import {SingleSelect} from '../../../../../../form/form.jsx';
import {useSettingsContext} from '../../../settings-context.js';

const LineId = () => {
  const {connectionConfig} = useSettingsContext();

  const [field, , helpers] = useField('line_id');
  const {setValue} = helpers;

  const options = connectionConfig.lines
    ? connectionConfig.lines.map(item => ({
        value: item.id,
        label: item.name === 'Add new line' ? t`Add a new line` : item.name
      }))
    : [];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Open line`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Open line`}
        isSearchAvailable
        onChange={setValue}
      />
    </div>
  );
};

export default LineId;
