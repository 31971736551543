import React from 'react';
import Info from './info/info.jsx';
import Login from './login/login.jsx';
import {useAppContext} from '../../../../../app-context.js';

const TelphinCustomer = () => {
  const {user} = useAppContext();

  return user.telphin_code ? <Info /> : <Login />;
};

export default TelphinCustomer;
