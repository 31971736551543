import React from 'react';
import PropTypes from 'prop-types';

const List = ({items, style}) => (
  <ul className="list" style={style}>
    {items?.map((item, i) => (
      <li key={i} className="list__item">
        {item}
      </li>
    ))}
  </ul>
);

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  style: PropTypes.object
};

export default List;
