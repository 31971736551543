import React from 'react';
import {Link} from 'react-router-dom';
import Page from '../../components/page/page.jsx';
import Image from '../../components/image/image.jsx';
import {useAppContext} from '../../app-context.js';

const Error404 = () => {
  const {theme} = useAppContext();

  const fileName = '404' + (theme === 'dark' ? '_dark' : '');

  return (
    <Page isCenter>
      <Link to="/">
        <Image
          src={`/static/img/illustrations/${fileName}.svg`}
          alt="404"
          style={{maxWidth: 664}}
        />
      </Link>
    </Page>
  );
};

export default Error404;
