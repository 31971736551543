import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import Button from '../button/button';
import Icons from '../icons/icons.jsx';
import Preloader from '../preloader/preloader.jsx';

const QR = ({qr, isPending, style, onClick}) => {
  const [backgroundImage, setBackgroundImage] = useState();

  useEffect(() => {
    if (!qr) return;

    if (qr.includes('data:image/png;base64,')) {
      setBackgroundImage(qr);
      return;
    }

    QRCode.toDataURL(qr).then(res => setBackgroundImage(res));
  }, [qr]);

  return (
    <div
      className="qr"
      style={{
        ...style,
        backgroundImage:
          backgroundImage && !isPending && `url("${backgroundImage}")`
      }}
    >
      {isPending ? (
        <Button color="transparent" onClick={onClick}>
          <Icons.Refresh />
        </Button>
      ) : (
        !backgroundImage && <Preloader isMin />
      )}
    </div>
  );
};

QR.propTypes = {
  qr: PropTypes.string,
  isPending: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func
};

QR.defaultProps = {
  qr: '',
  isPending: false,
  style: {},
  onClick: () => {}
};

export default QR;
