import React from 'react';
import moment from 'moment';
import {t} from 'ttag';
import Text from '../../../../text/text.jsx';
import {useConnectionContext} from '../../connection-context.js';

const PaymentDate = () => {
  const {connection} = useConnectionContext();

  const paymentDate = moment.unix(connection.date_pay).utcOffset('+0300');

  return (
    <>
      {connection.is_paid && (
        <Text>
          {t`Payment date`}: {paymentDate.format('L')}
          {', '}
          {paymentDate.format('LT')}
          {' UTC+3'}
        </Text>
      )}
    </>
  );
};

export default PaymentDate;
