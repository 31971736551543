import React from 'react';
import cn from 'classnames';
import TariffHead from './tariff-head/tariff-head';
import Body from './tariff-body/tariff-body';
import TariffPlanContext from './tariff-plan-context';
import {usePaymentContext} from '../payment-context';
import * as AppEnv from 'app-env';

interface TariffPlan {
  tariffOptions: AppEnv.TariffOption[];
}

const TariffPlan = ({tariffOptions}: TariffPlan) => {
  const {selectedTariffId} = usePaymentContext();

  const {packet_name} = tariffOptions[0];
  const isSelected = !!tariffOptions.find(item => item.id === selectedTariffId);

  return (
    <TariffPlanContext.Provider
      value={{isSelected, packet_name, tariffOptions}}
    >
      <div
        className={cn(`tariff-plan tariff-plan_${packet_name.toLowerCase()}`, {
          'tariff-plan_selected': isSelected
        })}
      >
        <TariffHead />
        <Body />
      </div>
    </TariffPlanContext.Provider>
  );
};

export default TariffPlan;
