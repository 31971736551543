import React from 'react';
import PropTypes from 'prop-types';
import {ngettext, msgid} from 'ttag';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import Text from '../../../../../components/text/text.jsx';
import Icons from '../../../../../components/icons/icons.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {inflectNumberal} from '../../../../../common/actions.js';

const Attachments = ({count}) => {
  const {lang} = useAppContext();

  const numCount = parseInt(count);

  return (
    <>
      {numCount > 0 && (
        <Wrapper
          alignItems="center"
          gap={0}
        >
          <Icons.Clip />

          <Text>
            {count}

            {' '}

            {lang === 'ru'
              ? inflectNumberal({
                  value: count,
                  labels: ['вложение', 'вложения', 'вложений']
                })
              : lang === 'uk'
              ? inflectNumberal({
                  value: count,
                  labels: ['додаток', 'додатки', 'додатків']
                })
              : ngettext(msgid`attachment`, `attachments`, numCount)}
          </Text>
        </Wrapper>
      )}
    </>
  );
};

Attachments.propTypes = {
  count: PropTypes.number
};

Attachments.defaultProps = {
  count: 0
};

export default Attachments;
