import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {TableRow, TableCell} from '../../table/table.jsx';
import Tooltip from '../../tooltip/tooltip.jsx';
import UnsubscribeGuide from '../../unsubscribe-guide/unsubscribe-guide.jsx';
import {useAppContext} from '../../../app-context.js';
import {useGetTariffName} from '../../../common/hooks/index.js';
import * as AppEnv from 'app-env';

interface Subscription {
  subscription: AppEnv.Subscription;
}

const Subscription = ({subscription}: Subscription) => {
  const {user} = useAppContext();
  const getTariffName = useGetTariffName();
  const date = moment(subscription.date_add).utcOffset('+0300');

  return (
    <TableRow>
      <TableCell>
        {date.format('L')}
        {', '}
        {date.format('LT')}
      </TableCell>

      <TableCell>
        {getTariffName({
          quantity: subscription.tariff_quantity,
          period: subscription.tariff_period
        })}
      </TableCell>

      <TableCell>{subscription.tariff_plane || 'Start'}</TableCell>
      {!!user.is_partner && <TableCell>{subscription.domain}</TableCell>}

      <TableCell>
        {subscription.title === 'cloudpayments'
          ? 'CloudPayments'
          : subscription.title === 'stripe' && 'Stripe'}
      </TableCell>

      <TableCell isIcon>
        <Tooltip>
          <UnsubscribeGuide system={subscription.title} />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

Subscription.propTypes = {
  subscription: PropTypes.shape({
    date_add: PropTypes.string,
    domain: PropTypes.string,
    quantity: PropTypes.number,
    tariff_packet: PropTypes.oneOf([0, 1, 2, 3]),
    tariff_period: PropTypes.oneOf(['day', 'month']),
    tariff_quantity: PropTypes.oneOf([30, 3, 6, 12]),
    title: PropTypes.oneOf(['cloudpayments', 'stripe', 'telphin']),
    tariff_plane: PropTypes.oneOf(['Enterprise', 'Pro', 'Start'])
  })
};

Subscription.defaultProps = {
  subscription: {}
};

export default Subscription;
