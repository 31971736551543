import React, {useState} from 'react';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Button from 'components/button/button';
import Icons from 'components/icons/icons.jsx';
import Link from '../../../../../../../link/link.jsx';
import List from '../../../../../../../list/list.jsx';
import Name from '../../../../../name/name.jsx';
import QR from '../../../../../../../qr/qr.jsx';
import Text from '../../../../../../../text/text.jsx';
import Wrapper from '../../../../../../../wrapper/wrapper.jsx';

import {useAppContext} from '../../../../../../../../app-context.js';
import {useConnectionListContext} from 'components/connection-list/connection-list-context.js';
import {useConnectionContext} from '../../../../../connection-context.js';
import {
  useCopyToClipboard,
  useRequest
} from '../../../../../../../../common/hooks';
import {useLinkingContext} from '../linking-context.js';
import {fetchChatResuscitate, getConnectionStatusReq} from 'common/api/api.js';

const Scanning = ({startContainer}) => {
  const {lang, setNotificationList, whatsAppSupport} = useAppContext();
  const {connectionList, setConnectionList} = useConnectionListContext();

  const {connection} = useConnectionContext();

  const {setLinkingType} = useLinkingContext();
  const [isFething, setIsFetching] = useState(false);

  const {request} = useRequest();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async () => {
    setIsFetching(true);

    const res = await request(fetchChatResuscitate(connection.chat_key));

    if (!Object.keys(res).length) return setIsFetching(false);

    const status = await request(
      getConnectionStatusReq({chat_key: connection.chat_key, full: true})
    );

    if (!Object.keys(status).length) return setIsFetching(false);

    const index = connectionList.findIndex(item => item.id == connection.id);

    if (index != undefined && index > -1) {
      setConnectionList(prevValue =>
        update(prevValue, {[index]: {st: {$set: status}}})
      );
    }

    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [
          {
            title: connection.label || `${t`Connection`} ${connection.id}`,
            text: t`The connection has been restarted.`
          }
        ]
      })
    );

    setIsFetching(false);
  };

  const qr =
    connection.version == 'avito'
      ? `https://avito.whatcrm.net/v1/auth?state=${connection.chat_key}`
      : connection.version == 'telegram' || connection.version == 'whatcrm'
        ? connection.st?.qrCode
        : undefined;

  const manuals = {
    avito: [
      <>
        {t`Follow the`}{' '}
        <Link href={qr} target="_blank">
          {t`link`}
        </Link>{' '}
        {t`or scan the QR code`}
      </>,
      t`Sign in to your Avito account`,
      t`Confirm the processing of personal data`
    ],
    telegram: [
      t`Wait for the QR code`,
      t`Open Telegram on your phone`,
      t`Go to Settings → Devices → Link Desktop Device`,
      t`Use your phone to scan the QR code and wait 1 minute`
    ],
    whatcrm: [
      t`Wait for the QR code`,
      t`Open WhatsApp on your phone`,
      t`Go to Settings → Linked Devices → Link a Device`,
      t`Use your phone to scan the QR code and wait 1 minute`,
      t`Keep your phone connected while using Whatcrm Web`
    ]
  };

  if (lang == 'ru') {
    manuals.whatcrm.push(
      <Link
        href="https://whatcrm.gitbook.io/whatcrm-faq/poleznye-stati/vypolnite-eti-rekomendacii-dlya-stabilnoi-raboty-whatsapp"
        target="_blank"
      >
        {'Прочтите рекомендации для стабильной работы WhatsApp'}
      </Link>
    );
  }

  const isNumberSignInShown =
    connection.version == 'telegram' || connection.version == 'whatcrm';

  const isPending =
    connection.st.state == 'REBOOT' ||
    connection.st.state === 'CONNECTED' ||
    connection.st.state === 'NAVIGATION' ||
    connection.st.state === 'PENDING' ||
    connection.st.state === 'READY';

  const style = {
    display: 'flex',
    flex: '1 0',
    paddingTop: 12,
    overflow: 'hidden'
  };

  return (
    <Wrapper alignItems="stretch" gap={24} noWrap>
      <div style={{flex: '0 1'}}>
        <QR
          qr={qr}
          isPending={isPending}
          style={{marginBottom: 16}}
          onClick={startContainer}
        />

        <Button
          color="white"
          style={{marginBottom: 8}}
          onClick={() =>
            copyToClipboard(
              `${window.location.origin}/scanner/${connection.chat_key}`
            )
          }
        >
          <Icons.Copy />
          {t`Copy the link to the QR code`}
        </Button>

        {connection.version == 'whatcrm' && (
          <Button
            color="white"
            style={{marginBottom: 8}}
            isDisabled={isFething}
            onClick={handleClick}
          >
            <Icons.Refresh />
            {t`Restart the connection`}
          </Button>
        )}

        {connection.version == 'telegram' ? (
          <Text>
            {t`If you can not scan the QR code, contact`}{' '}
            <Link href={whatsAppSupport} target="_blank">
              {t`support`}
            </Link>
            .
          </Text>
        ) : (
          connection.version == 'whatcrm' && (
            <Text>
              {t`If the QR code does not load or cannot be scanned try to restart the connection.`}
            </Text>
          )
        )}
      </div>

      <div style={style}>
        <Wrapper justifyContent="space-between" gap={0} isColumn>
          <div style={{maxWidth: '100%'}}>
            <Name style={{marginBottom: 16}} />
            <List items={manuals[connection.version]} />
          </div>

          {isNumberSignInShown && (
            <Link
              isFake
              isDisabled={!connection.st.qrCode}
              style={{alignSelf: 'flex-end'}}
              onClick={() => setLinkingType('login')}
            >
              {t`Log in by phone number`}
            </Link>
          )}
        </Wrapper>
      </div>
    </Wrapper>
  );
};

Scanning.propTypes = {
  startContainer: PropTypes.func
};

Scanning.defaultProps = {
  startContainer: () => {}
};

export default Scanning;
