import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Title from './title/title.jsx';
import Message from './message/message.jsx';
import AttachMenu from './attach-menu/attach-menu.jsx';
import AttachmentList from './attachment-list/attachment-list.jsx';
import Popup from '../../../../components/popup/popup.jsx';
import * as Headline from '../../../../components/title/title.jsx';
import Line from '../../../../components/line/line.jsx';
import Wrapper from '../../../../components/wrapper/wrapper.jsx';
import Button, {SaveButton} from '../../../../components/button/button';
import EditorContext from './editor-context.js';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks';
import {getAttachmentListReq} from '../../../../common/api/api.js';
import {trimValues} from '../../../../common/actions.js';

const Editor = ({headline, messageTemplate, onClose, onSubmit}) => {
  const {user} = useAppContext();

  const [attachmentList, setAttachmentList] = useState();

  const {request} = useRequest();

  const getAttachmentList = async () => {
    const res = await request(
      getAttachmentListReq(user.id, messageTemplate.id)
    );

    setAttachmentList(res);
  };

  useEffect(() => {
    if (messageTemplate.id) {
      getAttachmentList();
    }
  }, []);

  const handleSubmit = values => {
    const trimmedValues = trimValues(values);

    onSubmit({values: trimmedValues, attachmentList});
    onClose();
    return true;
  };

  const initialValues = {
    title: messageTemplate.title || '',
    message: messageTemplate.message || ''
  };

  const validationSchema = yup.object().shape({
    title: yup.string().trim().required(),
    message: yup.string()
  });

  return (
    <EditorContext.Provider
      value={{
        messageTemplate,
        attachmentList,
        setAttachmentList
      }}
    >
      <Popup onClick={onClose}>
        <Headline.H2 style={{marginBottom: 8}}>{headline}</Headline.H2>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Title />
            <Message />
            <AttachMenu />
            <AttachmentList />

            <Line />

            <Wrapper alignItems="center">
              <SaveButton>{t`Save`}</SaveButton>

              <Button color="white" onClick={onClose}>
                {t`Cancel`}
              </Button>
            </Wrapper>
          </Form>
        </Formik>
      </Popup>
    </EditorContext.Provider>
  );
};

Editor.propTypes = {
  headline: PropTypes.string,
  messageTemplate: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

Editor.defaultProps = {
  headline: '',
  messageTemplate: {},
  onClose: () => {},
  onSubmit: () => {}
};

export default Editor;
