import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Input} from '../../../../../../components/form/form.jsx';

const Domain = () => {
  const [field, meta, helpers] = useField('domain');
  const [{value: crm}] = useField('crm');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      inputMode={crm === 'LK' && 'email'}
      placeholder={t`Domain`}
      autoComplete={false}
      isValid={isValid}
      style={{marginBottom: 16}}
      onChange={value => setValue(value.toLowerCase())}
    />
  );
};

export default Domain;
