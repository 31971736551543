import React from 'react';
import {useField} from 'formik';
import {Toggle} from '../../../../../../../form/form.jsx';

const EqualAddresses = () => {
  const [field, , helpers] = useField('equal_addresses');

  return (
    <Toggle
      label="Фактический адрес совпадает с юридическим"
      isChecked={field.value}
      onChange={helpers.setValue}
    />
  );
};

export default EqualAddresses;
