import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import update from 'immutability-helper';

import ProgressBar from '../../../../../../progress-bar/progress-bar.jsx';
import TelegramAuthentication from '../../../../../../telegram-authentication/telegram-authentication.jsx';
import Name from '../../../../name/name.jsx';
import {useConnectionListContext} from 'components/connection-list/connection-list-context.js';
import {useConnectionContext} from '../../../../connection-context.js';
import {useRequest} from '../../../../../../../common/hooks';
import {getConnectionStatusReq} from '../../../../../../../common/api/api.js';

import TelegramConfirmation from './telegram-confirmation/telegram-confirmation.jsx';
import Scanning from './scanning/scanning.jsx';
import Login from './login/login.jsx';
import LinkingContext from './linking-context.js';

const Linking = () => {
  const {connectionList, setConnectionList} = useConnectionListContext();

  const {connection} = useConnectionContext();

  const [linkingType, setLinkingType] = useState('scanning');
  const {request} = useRequest();

  const startContainer = async () => {
    if (
      (connection.version == 'telegram' || connection.version == 'whatcrm') &&
      !connection.st.qrCode
    ) {
      const res = await request(
        getConnectionStatusReq({
          chat_key: connection.chat_key,
          full: 1
        })
      );

      const index = connectionList.findIndex(item => item.id == connection.id);
      if (index == undefined || index < 0) return;

      setConnectionList(prevValue =>
        update(prevValue, {
          [index]: {st: {$set: _.pick(res, ['state', 'qrCode'])}}
        })
      );
    }
  };

  useEffect(() => {
    startContainer();

    const timeout = setTimeout(() => {
      startContainer();
    }, 8000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <LinkingContext.Provider value={{setLinkingType}}>
      {connection.st.state === 'LOADING_SCREEN' ? (
        <ProgressBar percent={connection.st.percent} />
      ) : connection.st.state === 'authorizationStateWaitPassword' ? (
        <TelegramAuthentication
          chat_key={connection.chat_key}
          connectionName={<Name style={{marginBottom: 16}} />}
          passwordHint={connection.st.passwordHint}
        />
      ) : connection.st.state === 'authorizationStateWaitCode' ? (
        <TelegramConfirmation />
      ) : linkingType === 'scanning' ? (
        <Scanning startContainer={startContainer} />
      ) : (
        <Login />
      )}
    </LinkingContext.Provider>
  );
};

export default Linking;
