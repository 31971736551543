import React from 'react';
import {t} from 'ttag';
import {useField} from 'formik';
import * as Title from '../../../../../../components/title/title.jsx';
import {Textarea} from '../../../../../../components/form/form.jsx';

const Description = () => {
  const [field, , helpers] = useField('description');

  const {setValue} = helpers;

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Description`}</Title.H3>

      <Textarea
        {...field}
        placeholder={t`Description`}
        style={{marginBottom: 16}}
        onChange={setValue}
      />
    </>
  );
};

export default Description;
