import React from 'react';
import Button from '../../../button/button';
import Icons from '../../../icons/icons.jsx';

import * as AppEnv from 'app-env';

interface Search {
  inputRef:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>;
  isCollapsed: boolean;
  setIsCollapsed: AppEnv.SetState<boolean>;
}

const Search = ({inputRef, isCollapsed, setIsCollapsed}: Search) => {
  const handleClick = () => {
    setIsCollapsed(false);

    if (!('current' in inputRef) || !inputRef.current) return;

    inputRef.current.focus();
    const {selectionEnd} = inputRef.current;

    setTimeout(() => {
      if (inputRef.current) inputRef.current.selectionStart = selectionEnd;
    });
  };

  return isCollapsed ? (
    <Button color="transparent" onClick={handleClick}>
      <Icons.Search />
    </Button>
  ) : (
    <Icons.Search />
  );
};

export default Search;
