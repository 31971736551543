import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {SingleSelect} from '../../../../../../form/form';
import * as Title from '../../../../../../title/title.jsx';
import {useConnectionListContext} from '../../../../../connection-list-context';

const BoardId = () => {
  const {pipelineList} = useConnectionListContext();

  const [field, , helpers] = useField('board_id');
  const [, , {setValue: setGroupId}] = useField('group_id');

  const handleChange = value => {
    if (value != field.value) setGroupId('');
    helpers.setValue(value);
  };

  const options = pipelineList.map(item => ({
    group: item.workspace_name || t`Other`,
    label: item.board_name,
    value: item.board_id
  }));

  const isSearchAvailable = options.length > 7;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Board`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Board`}
        isSearchAvailable={isSearchAvailable}
        onChange={handleChange}
      />
    </div>
  );
};

export default BoardId;
