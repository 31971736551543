import React from 'react';
import {t} from 'ttag';
import {Input} from '../../../../../../components/form/form.jsx';
import {useStaffContext} from '../../staff-context.js';

const Searching = () => {
  const {search, setSearch} = useStaffContext();

  return (
    <Input
      value={search}
      placeholder={t`Search`}
      isSearch
      isCollapsible
      onChange={setSearch}
    />
  );
};

export default Searching;
