import React, {useState, useEffect} from 'react';
import {t, ngettext, msgid} from 'ttag';

import EmptyBox from '../empty-box/empty-box.jsx';
import Filter from '../filter/filter.jsx';
import Preloader from '../preloader/preloader.jsx';
import Table, {TableBody, TableCell, TableHead} from '../table/table.jsx';
import Text from '../text/text.jsx';
import {useAppContext} from '../../app-context.js';
import {inflectNumberal} from '../../common/actions.js';
import * as AppEnv from 'app-env';

import Subscription from './subscription/subscription';

interface Values {
  domains: string[];
}

interface SubscriptionList {
  subscriptionList: AppEnv.Subscription[] | undefined;
}

const SubscriptionList = ({subscriptionList}: SubscriptionList) => {
  const {user, lang} = useAppContext();

  const [filteredSubscriptionList, setFilteredSubscriptionList] = useState<
    AppEnv.Subscription[]
  >([]);

  useEffect(() => {
    if (subscriptionList) setFilteredSubscriptionList(subscriptionList);
  }, [subscriptionList]);

  const handleFilterChange = (values: Values) => {
    if (values.domains.length) {
      setFilteredSubscriptionList(
        subscriptionList?.filter(item =>
          values.domains.includes(item.domain)
        ) || []
      );

      return;
    }

    setFilteredSubscriptionList(subscriptionList || []);
  };

  const domains = subscriptionList
    ? subscriptionList.reduce((acc: string[], item) => {
        acc.push(item.domain);
        return acc;
      }, [])
    : [];

  return (
    <div>
      {subscriptionList ? (
        subscriptionList.length ? (
          <>
            <Filter
              domains={domains}
              style={{marginBottom: 16}}
              onChange={handleFilterChange}
            />

            <Text isSmall style={{marginBottom: 8}}>
              {filteredSubscriptionList.length}
              {' '}
              {lang === 'ru'
                ? inflectNumberal({
                    value: filteredSubscriptionList.length,
                    labels: [
                      'активная подписка',
                      'активные подписки',
                      'активных подписок'
                    ]
                  })
                : lang === 'uk'
                  ? inflectNumberal({
                      value: filteredSubscriptionList.length,
                      labels: [
                        'активна підписка',
                        'активні підписки',
                        'активних підписок'
                      ]
                    })
                  : ngettext(
                      msgid`active subscription`,
                      `active subscriptions`,
                      filteredSubscriptionList.length
                    )}
            </Text>

            <Table>
              <TableHead>
                <TableCell>{t`Start date`} (UTC+3)</TableCell>
                <TableCell>{t`Cycle`}</TableCell>
                <TableCell>{t`Tariff plan`}</TableCell>
                {!!user.is_partner && <TableCell>{t`Domain`}</TableCell>}
                <TableCell>{t`Platform`}</TableCell>
                <TableCell />
              </TableHead>

              <TableBody>
                {filteredSubscriptionList.map((item, i) => (
                  <Subscription key={i} subscription={item} />
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default SubscriptionList;
