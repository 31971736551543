import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../../title/title.jsx';
import {SingleSelect} from '../../../../../../../form/form.jsx';
import {useConnectionListContext} from '../../../../../../connection-list-context.js';

const PipeId = () => {
  const {pipelineList} = useConnectionListContext();

  const [field, , helpers] = useField('pipeline_settings');
  const {value: pipeline_settings} = field;
  const {setValue: setPipelineSettings} = helpers;

  const handleChange = tempPipeId => {
    const {statuses} = pipelineList.find(item => item.id === tempPipeId) || {};

    setPipelineSettings([
      {
        pipe_id: tempPipeId,
        status_id: statuses[0].id,
        manager_id: null
      }
    ]);
  };

  const options = pipelineList.map(item => ({
    value: item.id,
    label: item.name
  }));

  const {pipe_id} = pipeline_settings[0] || {};

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Pipeline`}</Title.H3>

      <SingleSelect
        options={options}
        value={pipe_id}
        placeholder={t`Pipeline`}
        onChange={handleChange}
      />
    </div>
  );
};

export default PipeId;
