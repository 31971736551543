import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {MultiSelect} from 'components/form/form';
import * as Title from 'components/title/title';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useConnectionListContext} from 'components/connection-list/connection-list-context';
import {useSettingsContext} from '../../../settings-context';

interface Value {
  isChecked: boolean;
  label: string;
  value: number;
}

const ResponsibleId = () => {
  const {managerList} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField<number[]>('responsible_id');

  const handleChange = (values: Value[]) => {
    const value = values.reduce((acc, item) => {
      if (item.isChecked) acc.push(item.value);
      return acc;
    }, [] as number[]);

    helpers.setValue(value);
  };

  const isDisabled = !!connection.is_limited || !managerList.length;

  // eslint-disable-next-line
  const options = managerList.map((item: any) => ({
    isChecked: connection.is_limited ? false : field.value.includes(item.id),
    label: item.fullname,
    value: item.id
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Managers`}</Title.H3>

      <MultiSelect
        options={options}
        placeholder={t`Managers`}
        isDisabled={isDisabled}
        isSearchAvailable
        onChange={handleChange}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
      />
    </div>
  );
};

export default ResponsibleId;
