import React from 'react';
import {t} from 'ttag';

import CheckRes from '../components/check-res/check-res';
import Header from '../components/header/header';
import Link from 'components/link/link';
import Placeholder from '../components/placeholder/placeholder';
import Text from 'components/text/text';
import Wrapper from 'components/wrapper/wrapper';
import {useCheckerContext} from '../checker-context';

const List = () => {
  const {data} = useCheckerContext();

  return (
    <div className="page">
      <Header style={{marginBottom: 32}} />

      <Wrapper gap={16} style={{marginBottom: 16}}>
        {data?.id && (
          <div>
            <Text style={{marginBottom: 4}} isSmall>
              {'ID'}
            </Text>

            <Text color="dark">{data?.id}</Text>
          </div>
        )}

        {data?.title && data.link && (
          <div>
            <Text style={{marginBottom: 4}} isSmall>{t`Entity`}</Text>

            <Text>
              <Link href={data?.link} target="_blank">
                {data?.title}
              </Link>
            </Text>
          </div>
        )}
      </Wrapper>

      {data?.code ? <CheckRes codeList={data?.code} /> : <Placeholder />}
    </div>
  );
};

export default List;
