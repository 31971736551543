import React from 'react';
import PropTypes from 'prop-types';
import {useField} from 'formik';
import {t} from 'ttag';

import {Input} from '../../../../../components/form/form.jsx';
import {
  isolateNumbers,
  normalizePhoneNumber
} from '../../../../../common/actions.js';

const Phone = ({type, setType}) => {
  const [field, meta, helpers] = useField('phone');
  const isValid = !(meta.touched && meta.error);

  const handleChange = value => {
    if (parseInt(value?.[0]) >= 0 || value?.[0] === '+') {
      helpers.setValue(
        isolateNumbers(value).length > 15
          ? isolateNumbers(value)
          : normalizePhoneNumber(value)
      );

      setType('tel');
      return;
    }

    helpers.setValue(value);
    setType('text');
  };

  return (
    <Input
      {...field}
      type={type}
      placeholder={t`Contact`}
      autoFocus
      isValid={isValid}
      onChange={handleChange}
    />
  );
};

Phone.propTypes = {
  type: PropTypes.oneOf(['tel', 'text']),
  setType: PropTypes.func
};

Phone.defaultProps = {
  type: 'tel',
  setType: () => {}
};

export default Phone;
