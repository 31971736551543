import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface TariffPlanContext {
  isSelected: boolean;
  tariffOptions: AppEnv.TariffOption[];
  packet_name: 'Enterprise' | 'Pro' | 'Start';
}

const TariffPlanContext = React.createContext<TariffPlanContext>(
  {} as TariffPlanContext
);

export const useTariffPlanContext = () => useContext(TariffPlanContext);
export default TariffPlanContext;
