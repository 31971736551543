import React from 'react';
import {t} from 'ttag';
import countries from 'i18n-iso-countries';

import {SingleSelect} from 'components/form/form';
import * as Title from 'components/title/title';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface Server {
  location: string | undefined;
  setLocation: AppEnv.SetState<string | undefined>;
}

const ServerLocation = ({location, setLocation}: Server) => {
  const {lang} = useAppContext();

  // eslint-disable-next-line
  countries.registerLocale(require(`i18n-iso-countries/langs/${lang}.json`));

  const options = [
    {
      comment: countries.getName('FI', lang),
      label: t`Helsinki`,
      value: 'Helsinki'
    },
    {
      comment: countries.getName('DE', lang),
      label: t`Falkenstein`,
      value: 'Falkenstein'
    },
    {
      comment: countries.getName('DE', lang),
      label: t`Nuremberg`,
      value: 'Nuremberg'
    },
    {label: countries.getName('KZ', lang), value: 'Kazakhstan'},
    {label: countries.getName('NL', lang), value: 'Netherlands'},
    {label: countries.getName('PL', lang), value: 'Poland'},
    {comment: countries.getName('RU', lang), label: t`Moscow`, value: 'Moscow'},
    {
      comment: countries.getName('RU', lang),
      label: t`Saint Petersburg`,
      value: 'Saint Petersburg'
    },
    {
      comment: `${t`Oregon`}, ${countries.getName('US', lang)}`,
      label: t`Hillsboro`,
      value: 'Hillsboro'
    },
    {
      comment: `${t`Virginia`}, ${countries.getName('US', lang)}`,
      label: t`Ashburn`,
      value: 'Ashburn'
    }
  ];

  return (
    <div>
      <Title.H1 style={{marginBottom: 12}}>{t`Server location`}</Title.H1>

      <SingleSelect
        value={location}
        options={options}
        placeholder={t`Server location`}
        isClearable
        onChange={setLocation}
      />
    </div>
  );
};

export default ServerLocation;
