import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';
import {useAddressContext} from '../address-context.js';

const House = () => {
  const {prefix} = useAddressContext();

  const [field, meta, helpers] = useField(`${prefix}_house`);

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      placeholder="Дом"
      isMin
      isValid={isValid}
      onChange={helpers.setValue}
    />
  );
};

export default House;
