import React, {useEffect, useState} from 'react';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import * as yup from 'yup';

import * as Title from '../../../components/title/title.jsx';
import Button, {SaveButton} from '../../../components/button/button';
import Code from './code/code.jsx';
import Line from '../../../components/line/line.jsx';
import Text from '../../../components/text/text.jsx';
import Wrapper from '../../../components/wrapper/wrapper.jsx';
import {useAppContext} from '../../../app-context.js';
import {useLoginContext} from '../login-context.js';
import {useRequest} from '../../../common/hooks';
import {
  checkVerificationCodeReq,
  sendVerificationCodeReq
} from '../../../common/api/api.js';
import {putInLocalStorage} from '../../../common/actions.js';

const Verification = ({onCancel}) => {
  const {setUser} = useAppContext();
  const {email, setEmail} = useLoginContext();

  const [counter, setCounter] = useState(0);
  const {request} = useRequest();

  const sendVerificationCode = () => request(sendVerificationCodeReq(email));

  useEffect(() => {
    sendVerificationCode();
  }, []);

  const handleCancel = () => {
    onCancel();
    return true;
  };

  const updateCounter = () => {
    if (counter > 1) return handleCancel();

    setCounter(counter + 1);
    return true;
  };

  const submitForm = async values => {
    const {code} = values;

    const res = await request(checkVerificationCodeReq({email, code}));
    if (res.success != 200) return updateCounter();

    putInLocalStorage('domain', email);
    setUser({crm: 'LK', domain: email});
    return true;
  };

  const initialValues = {code: ''};

  const validationSchema = yup.object().shape({
    code: yup.string().trim().required().length(8)
  });

  return (
    <>
      <Title.H2 style={{marginBottom: 8}}>{t`Verification code`}</Title.H2>

      <Text style={{marginBottom: 16}}>
        {t`Enter the verification code sent to your email.`}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        <Form>
          <Code submitForm={submitForm} />
          <Line />

          <Wrapper>
            <SaveButton>{t`Continue`}</SaveButton>

            <Button color="white" onClick={() => setEmail('')}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </>
  );
};

Verification.propTypes = {
  onCancel: PropTypes.func
};

Verification.defaultProps = {
  onCancel: () => {}
};

export default Verification;
