import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {t, ngettext, msgid} from 'ttag';
import Icons from '../../icons/icons.jsx';
import Text from '../../text/text.jsx';

const Dropzone = ({accept, multiple, isMax, style, onChange}) => {
  const onDrop = useCallback(onChange, []);

  const {getRootProps, getInputProps, isDragAccept} = useDropzone({
    accept,
    multiple,
    onDrop
  });

  const n = multiple ? 2 : 1;

  const file_s = ngettext(msgid`a file`, `files`, n);

  return (
    <div
      {...getRootProps()}
      className={cn('dropzone', {
        dropzone_active: isDragAccept,
        dropzone_max: isMax
      })}
      style={style}
    >
      <input {...getInputProps()} />
      <Icons.Clip style={{marginBottom: 8}} />

      <Text color="dark">
        {t`Drag and drop ${file_s} here or click to select`}
      </Text>
    </div>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.object,
  multiple: PropTypes.bool,
  isMax: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func
};

Dropzone.defaultProps = {
  accept: {},
  multiple: false,
  isMax: false,
  style: {},
  onChange: () => {}
};

export default Dropzone;
