import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Wrapper from '../../wrapper/wrapper.jsx';

const Switch = ({options, value, onChange}) => (
  <Wrapper
    alignItems="center"
    gap={12}
  >
    {options.map(item => (
      <button
        key={item.value}
        className={cn('switch', {switch_active: item.value === value})}
        type="button"
        onClick={() => onChange(item.value)}
      >
        {item.label}
      </button>
    ))}
  </Wrapper>
);

Switch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({value: PropTypes.any, label: PropTypes.node})
  ),
  value: PropTypes.any,
  onChange: PropTypes.func
};

Switch.defaultProps = {
  options: [],
  value: null,
  onChange: () => {}
};

export default Switch;
