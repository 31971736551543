import React from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';
import * as yup from 'yup';
import {Formik, Form} from 'formik';
import Domain from './domain/domain.jsx';
import Line from '../../../../../line/line.jsx';
import {SaveButton} from '../../../../../button/button';
import {useAppContext} from '../../../../../../app-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {createPartnerReq} from '../../../../../../common/api/api.js';
import {trimValues} from '../../../../../../common/actions.js';

const Creating = () => {
  const {user, setUser} = useAppContext();

  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);
    const res = await request(createPartnerReq(user.id, trimmedValues.domain));

    if (Object.keys(res).length) {
      setUser(prevValue =>
        update(prevValue, {
          partner: {
            $set: {
              date_add: Date.now() / 1000,
              domain: res.domain,
              email: res.email,
              phone: res.phone,
              title: res.title
            }
          }
        })
      );
    }

    return true;
  };

  const initialValues = {
    domain: ''
  };

  const validationSchema = yup.object().shape({
    domain: yup.string().required()
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Domain />
          <Line />
          <SaveButton>{t`Add a partner`}</SaveButton>
        </Form>
      </Formik>
    </>
  );
};

export default Creating;
