import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Grid = ({children, elements, gap, width, style}) => (
  <div
    className={cn('grid', {
      [`grid_${elements}`]: elements,
      [`grid_gap-${gap}`]: gap,
      [`grid_width-${width}`]: width
    })}
    style={style}
  >
    {children}
  </div>
);

Grid.propTypes = {
  children: PropTypes.node,
  elements: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gap: PropTypes.oneOf([8, 12, 16, '24-56']),
  width: PropTypes.oneOf(['', 'max-content']),
  style: PropTypes.object
};

Grid.defaultProps = {
  children: <></>,
  elements: null,
  gap: 16,
  width: '',
  style: {}
};

export default Grid;
