import React, {useState} from 'react';
import LegalEntity from './legal-enity/legal-entity.jsx';
import Individual from './individual/individual.jsx';
import Recovery from './recovery/recovery.jsx';
import Nav, {NavBar, NavTab} from '../../../../../nav/nav.jsx';

const Login = () => {
  const [tab, setTab] = useState('legalEntity');

  const options = [
    {
      value: 'legalEntity',
      label: 'Юридическое лицо'
    },
    {
      value: 'individual',
      label: 'Физическое лицо'
    },
    {
      value: 'recovery',
      label: 'Уже есть код клиента'
    }
  ];

  return (
    <>
      <Nav
        options={options}
        value={tab}
        onChange={setTab}
      >
        <NavBar style={{marginBottom: 16}} />

        <NavTab tab="legalEntity">
          <LegalEntity />
        </NavTab>

        <NavTab tab="individual">
          <Individual />
        </NavTab>

        <NavTab tab="recovery">
          <Recovery />
        </NavTab>
      </Nav>
    </>
  );
};

export default Login;
