export const getConnectionConfigReq = ({domain, chat_id}) => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'chat', chat_id}
});

export const updateConnectionConfigReq = ({domain, chat_id, data}) => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'set_prefs', chat_id},
  method: 'post',
  data
});

export const getDialingCodeReq = domain => ({
  path: `v1/phonecode/${domain}`,
  params: {t: 'get'}
});

export const updateDialingCodeReq = ({domain, code}) => ({
  path: `v1/phonecode/${domain}`,
  params: {t: 'set', code}
});

export const deleteDialingCodeReq = domain => ({
  path: `v1/phonecode/${domain}`,
  params: {t: 'delete'}
});

export const updateEmployeeListReq = domain => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'update'}
});
