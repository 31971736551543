import React from 'react';
import Address from '../../address/address.jsx';

const JAddress = () => (
  <Address
    prefix="j"
    placeholder="Юридический адрес"
    isCollapsible
  />
);

export default JAddress;
