import React from 'react';

import {usePaymentContext} from '../payment-context';

import Warning from './warning/warning';

const WarningList = () => {
  const {connection, connectionIdList, connectionList} = usePaymentContext();

  return (
    <>
      {connectionIdList?.length ? (
        // eslint-disable-next-line
        connectionIdList.map(id => {
          const instance = connectionList?.find(item => item.id == id);

          return <Warning key={id} connection={instance} />;
        })
      ) : (
        <Warning connection={connection} />
      )}
    </>
  );
};

export default WarningList;
