import React from 'react';
import {t} from 'ttag';

import Wrapper from 'components/wrapper/wrapper';
import Section from 'components/section/section';
import {useAppContext} from 'app-context';
import {useCheckerContext} from 'pages/checker/checker-context';

import Bizproc from './bizproc/bizproc';
import Call from './call/call';
import Phone from './phone/phone';

const Settings = () => {
  const {user} = useAppContext();
  const {data} = useCheckerContext();

  const isBitrix24 = user.domain.includes('bitrix24');
  const isBizprocShown = !data?.bizproc && isBitrix24;

  return (
    <Section title={t`Settings`}>
      <Wrapper gap={16} isColumn>
        {isBitrix24 && <Call />}
        <Phone />
        {isBizprocShown && <Bizproc />}
      </Wrapper>
    </Section>
  );
};

export default Settings;
