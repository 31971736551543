import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Line = ({clearance, style}) => (
  <div
    className={cn('line', {
      [`line_full-width-${clearance}`]: clearance
    })}
    style={style}
  />
);

Line.propTypes = {
  clearance: PropTypes.number,
  style: PropTypes.object
};

Line.defaultProps = {
  clearance: 0,
  style: {}
};

export default Line;
