import React from 'react';
import PropTypes from 'prop-types';
import RadioItem from './radio-item/radio-item.jsx';
import Wrapper from '../../wrapper/wrapper.jsx';

const Radio = ({options, name, onChange}) => {
  return (
    <Wrapper
      gap={12}
      isColumn
    >
      {options.map(item => (
        <RadioItem
          key={item.value}
          radioItem={item}
          name={name}
          onChange={onChange}
        />
      ))}
    </Wrapper>
  );
};

Radio.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func
};

Radio.defaultProps = {
  options: [],
  name: '',
  onChange: () => {}
};

export default Radio;
