import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';
import {useAddressContext} from '../address-context.js';

const Flat = () => {
  const {prefix} = useAddressContext();

  const [field, meta, helpers] = useField(`${prefix}_flat`);
  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      placeholder="Офис или кв."
      isMin
      isValid={isValid}
      onChange={setValue}
    />
  );
};

export default Flat;
