import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';

const Mail = () => {
  const [field, meta, helpers] = useField('mail');

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      type="email"
      placeholder="Электронная почта"
      isValid={isValid}
      onChange={value => helpers.setValue(value.toLowerCase())}
    />
  );
};

export default Mail;
