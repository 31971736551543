import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../components/title/title.jsx';
import {Input} from '../../../../../../components/form/form.jsx';
import {normalizePhoneNumber} from '../../../../../../common/actions.js';

const Phone = () => {
  const [field, , helpers] = useField('phone');

  const {setValue} = helpers;

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Personal phone number`}</Title.H3>

      <Input
        {...field}
        type="tel"
        placeholder={t`Personal phone number`}
        style={{marginBottom: 16}}
        onChange={value => setValue(normalizePhoneNumber(value))}
      />
    </>
  );
};

export default Phone;
