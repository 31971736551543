import React from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';
import {Formik, Form} from 'formik';

import Wrapper from '../../../../../wrapper/wrapper.jsx';
import Line from '../../../../../line/line.jsx';
import Button, {SaveButton} from '../../../../../button/button';
import Preloader from '../../../../../preloader/preloader.jsx';
import EmptyBox from '../../../../../empty-box/empty-box.jsx';

import {useAppContext} from '../../../../../../app-context.js';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {updateConnectionConfigReq} from '../../../../../../common/api/monday.js';
import {trimValues} from '../../../../../../common/actions.js';

import BoardId from './board-id/board-id.jsx';
import DisableItem from './disable-item/disable-item';
import DisableUpdate from './disable-update/disable-update';
import Group from './group/group.jsx';
import GroupId from './group-id/group-id.jsx';
import ResponsibleId from './responsible-id/responsible-id';
import Strategy from './strategy/strategy';

const Monday = () => {
  const {setNotificationList} = useAppContext();
  const {client, setSettingsId} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig, updateConnectionConfig} = useSettingsContext();

  const {crmRequest} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const responseSave = await crmRequest({
      crm: client.crm,
      ...updateConnectionConfigReq({
        domain: client.domain,
        chat_id: connection.id,
        phone: connection.phone,
        data: trimmedValues
      })
    });
    if (responseSave.success && responseSave.success === true) {
      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: connection.label || `${t`Connection`} ${connection.id}`,
              text: t`The settings have been successfully saved.`
            }
          ]
        })
      );

      updateConnectionConfig({...connectionConfig, ...responseSave.result});
      setSettingsId(-1);
    }

    return true;
  };

  const initialValues = connectionConfig
    ? {
        board_id: connectionConfig.board_id || '',
        disable_item: connectionConfig.disable_item,
        disable_update: connectionConfig.disable_update,
        group_id: connectionConfig.group_id || '',
        is_group: connectionConfig.is_group || false,
        responsible_id: connectionConfig.responsible_id || [],
        strategy: connectionConfig.strategy
      }
    : {};

  return (
    <>
      {connectionConfig ? (
        Object.keys(connectionConfig).length ? (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <Wrapper gap={16} isColumn>
                <BoardId />
                <GroupId />
                <Strategy />
                <ResponsibleId />
                <Group />
                <DisableUpdate />
                <DisableItem />
              </Wrapper>

              <Line />

              <Wrapper>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="white" onClick={() => setSettingsId(-1)}>
                  {t`Cancel`}
                </Button>
              </Wrapper>
            </Form>
          </Formik>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader isMin />
      )}
    </>
  );
};

export default Monday;
