import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import Wrapper from '../../wrapper/wrapper.jsx';
import Text from '../../text/text.jsx';

const Toggle = ({label, isChecked, isDisabled, style, onClick, onChange}) => {
  const id = _.uniqueId('checkbox-');

  return (
    <label
      className={cn('toggle', {
        toggle_disabled: isDisabled
      })}
      htmlFor={id}
      style={style}
      onClick={() => onClick()}
    >
      <Wrapper
        alignItems="center"
        noWrap
      >
        <input
          type="checkbox"
          id={id}
          checked={isChecked}
          disabled={isDisabled}
          onChange={e => onChange(e.target.checked)}
        />

        <div className="toggle__slider" />

        <Text
          color="dark"
          isSpan
        >
          {label}
        </Text>
      </Wrapper>
    </label>
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

Toggle.defaultProps = {
  onClick: () => {},
  onChange: () => {}
};

export default Toggle;
