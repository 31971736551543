import React from 'react';
import {useField} from 'formik';
import {SearchSelect} from '../../../../../../../form/form.jsx';
import {useAddressContext} from '../address-context.js';

const Street = () => {
  const {prefix} = useAddressContext();

  const [, , indexHelpers] = useField(`${prefix}_index`);
  const [countryField, , countryHelpers] = useField(`${prefix}_country`);
  const [, , regionHelpers] = useField(`${prefix}_region`);
  const [, , cityHelpers] = useField(`${prefix}_city`);
  const [field, meta, helpers] = useField(`${prefix}_street`);

  const {value} = field;
  const {error, touched} = meta;
  const {setValue} = helpers;

  const handleChange = street => {
    const {data} = street;

    indexHelpers.setValue(data.postal_code);
    indexHelpers.setTouched(false);

    countryHelpers.setValue(data.country);
    countryHelpers.setTouched(false);

    regionHelpers.setValue(data.region_with_type);
    regionHelpers.setTouched(false);

    cityHelpers.setValue(data.city_with_type || data.settlement_with_type);
    cityHelpers.setTouched(false);

    setValue(data.street_with_type);
  };

  const isValid = !(error && touched);

  return (
    <SearchSelect
      input={{
        ...field,
        placeholder: 'Улица',
        isValid,
        onChange: setValue
      }}
      path="address"
      query={value}
      locations={[{country: countryField.value}]}
      fromBound="street"
      toBound="street"
      onChange={handleChange}
    />
  );
};

export default Street;
