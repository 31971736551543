import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import Text from '../text/text';
import Link from '../link/link';

const UnsubscribeGuide = ({system, style}) => (
  <Text style={style}>
    {system === 'stripe' ? (
      <>
        {t`You can cancel your subscription on the`}{' '}
        <Link
          href="https://billing.stripe.com/p/login/fZe14d9V03L29Xi144"
          target="_blank"
        >
          {t`payment system site`}
        </Link>
        {'. '}
        {t`Enter your email and a confirmation code. Then find our product and click the Cancel button.`}
      </>
    ) : (
      <>
        {t`You can cancel your subscription on the`}{' '}
        <Link
          href="https://my.cloudpayments.ru"
          target="_blank"
        >
          {t`payment system site`}
        </Link>
        {'. '}
        {t`Enter the details of your card and the cost of your subscription. Then find our product and click the Cancel button.`}
      </>
    )}
  </Text>
);

UnsubscribeGuide.propTypes = {
  system: PropTypes.oneOf(['cloud', 'cloudpayments', 'stripe']),
  style: PropTypes.object
};

export default UnsubscribeGuide;
