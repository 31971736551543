import React, {useState, useEffect} from 'react';

import EmptyBox from 'components/empty-box/empty-box.jsx';
import Grid from 'components/grid/grid.jsx';
import Preloader from 'components/preloader/preloader.jsx';
import {useRequest} from 'common/hooks';
import {getIntegrationListReq} from 'common/api/api.js';

import Integration from './integration/integration.jsx';

const IntegrationList = () => {
  const [integrationList, setIntegrationList] = useState();
  const {request} = useRequest();

  const getIntegrationList = async () => {
    const res = await request(getIntegrationListReq());

    setIntegrationList(Object.values(res));
  };

  useEffect(() => {
    getIntegrationList();
  }, []);

  return (
    <div>
      {integrationList ? (
        integrationList.length > 0 ? (
          <Grid>
            {integrationList.map(item => (
              <Integration key={item.id} integration={item} />
            ))}
          </Grid>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default IntegrationList;
