import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import {useField, useFormikContext} from 'formik';

const Code = ({submitForm}) => {
  const [isAttempted, setIsAttempted] = useState(false);

  const {setSubmitting} = useFormikContext();
  const [field, meta, helpers] = useField('code');

  const handleChange = async value => {
    helpers.setValue(value);
    if (value.length < 8 || isAttempted) return;

    setIsAttempted(true);
    setSubmitting(true);
    await submitForm({code: value});
    setSubmitting(false);
  };

  const isValid = !(meta.error && meta.touched);

  return (
    <ReactCodeInput
      {...field}
      className=" "
      fields={8}
      inputStyleInvalid={{borderColor: '#ff8242'}}
      isValid={isValid}
      onChange={handleChange}
    />
  );
};

Code.propTypes = {
  submitForm: PropTypes.func
};

Code.defaultProps = {
  submitForm: () => {}
};

export default Code;
