import React, {useState} from 'react';
import {t} from 'ttag';

import Section from 'components/section/section.jsx';
import {NavTab} from 'components/nav/nav.jsx';
import {useAppContext} from 'app-context.js';

import IntegrationList from './integration-list/integration-list.jsx';
import TelphinProductList from './telphin-product-list/telphin-product-list.jsx';
import WhatcrmProductList from './whatcrm-product-list/whatcrm-product-list.jsx';

const ProductsSection = () => {
  const {user} = useAppContext();
  const [tab, setTab] = useState('whatcrm');

  const options = [
    {value: 'whatcrm', label: 'Whatcrm'}
    // {value: 'telphin', label: t`Telphin`}
  ];

  if (user.crm === 'LK')
    options.push({label: t`Integrations`, value: 'integrationList'});

  const navParams = {onChange: setTab, options, value: tab};

  return (
    <Section title={t`Products`} navParams={navParams}>
      <NavTab tab="whatcrm">
        <WhatcrmProductList />
      </NavTab>

      <NavTab tab="telphin">
        <TelphinProductList />
      </NavTab>

      {user.crm == 'LK' && (
        <NavTab tab="integrationList">
          <IntegrationList />
        </NavTab>
      )}
    </Section>
  );
};

export default ProductsSection;
