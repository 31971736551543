import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import Wrapper from '../../../wrapper/wrapper.jsx';
import Icons from '../../../icons/icons.jsx';
import * as Title from '../../../title/title.jsx';
import Button from '../../../button/button';
import {useConnectionContext} from '../connection-context.js';
import {useAppContext} from 'app-context.js';

import Editor from './editor/editor.jsx';

const Name = ({style, isEditable}) => {
  const {user} = useAppContext();
  const {connection} = useConnectionContext();
  const [isEditorActive, setIsEditorActive] = useState(false);

  const isAdmin =
    (user.crm != 'MONDAY' && user.crm != 'TGMONDAY') || user.is_admin > 0;

  return (
    <>
      <Wrapper
        alignItems="center"
        gap={8}
        noWrap
        style={{overflow: 'auto', ...style}}
      >
        {connection.version == 'avito' ? (
          <Icons.Avito />
        ) : connection.version === 'telegram' ? (
          <Icons.Telegram />
        ) : (
          connection.version === 'whatcrm' && <Icons.WhatsApp />
        )}

        <Wrapper alignItems="center" gap={0} noWrap style={{overflow: 'auto'}}>
          <Title.H2 className="one-line">
            {connection.label || `${t`Connection`} ${connection.id}`}
          </Title.H2>

          {isEditable && isAdmin && (
            <Button color="transparent" onClick={() => setIsEditorActive(true)}>
              <Icons.Pencil />
            </Button>
          )}
        </Wrapper>
      </Wrapper>

      {isEditorActive && <Editor setIsEditorActive={setIsEditorActive} />}
    </>
  );
};

Name.propTypes = {
  style: PropTypes.object,
  isEditable: PropTypes.bool
};

Name.defaultProps = {
  style: {},
  isEditable: false
};

export default Name;
