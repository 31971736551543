import React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import * as yup from 'yup';
import {Formik, Form} from 'formik';

import Address from '../address/address.jsx';
import BankAuthority from './bank-authority/bank-authority.jsx';
import BankDate from './bank-date/bank-date.jsx';
import BankNumber from './bank-number/bank-number.jsx';
import BankSeries from './bank-series/bank-series.jsx';
import Mail from './mail/mail.jsx';
import Name from './name/name.jsx';
import Phone from './phone/phone.jsx';

import Wrapper from '../../../../../../wrapper/wrapper.jsx';
import * as Title from '../../../../../../title/title.jsx';
import Line from '../../../../../../line/line.jsx';
import {SaveButton} from '../../../../../../button/button';

import {useAppContext} from '../../../../../../../app-context.js';
import {useRequest} from '../../../../../../../common/hooks';
import {createTelphinClientReq} from '../../../../../../../common/api/api.js';
import {trimValues} from '../../../../../../../common/actions.js';

const Individual = () => {
  const {user, setUser} = useAppContext();
  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const getAddress = prefix =>
      [
        trimmedValues[`${prefix}_index`],
        trimmedValues[`${prefix}_country`],
        trimmedValues[`${prefix}_region`],
        trimmedValues[`${prefix}_city`],
        trimmedValues[`${prefix}_street`],
        trimmedValues[`${prefix}_house`],
        trimmedValues[`${prefix}_flat`]
      ].join(', ');

    const data = {
      ..._.pick(trimmedValues, ['mail', 'name', 'phone']),
      ambassadors: trimmedValues.name,
      bank: [
        `${trimmedValues.bank_series} ${trimmedValues.bank_number}`,
        trimmedValues.bank_authority,
        trimmedValues.bank_date
      ].join(', '),
      contract_owner: trimmedValues.name,
      customer_type: 'F',
      docs: '1',
      f_address: getAddress('j'),
      j_address: getAddress('j')
    };

    const res = await request(createTelphinClientReq(user.id, data));
    const code = res.client_code || res.client;

    if (code) {
      setUser(
        update(user, {
          telphin_code: {$set: code},
          telphin_email: {$set: data.mail}
        })
      );
    }

    return true;
  };

  const initialValues = {
    bank_authority: '',
    bank_date: '',
    bank_number: '',
    bank_series: '',
    j_city: '',
    j_country: '',
    j_flat: '',
    j_house: '',
    j_index: '',
    j_region: '',
    j_street: '',
    mail: '',
    name: '',
    phone: ''
  };

  const validationSchema = yup.object().shape({
    bank_authority: yup.string().trim().required(),
    bank_date: yup.date().required(),
    bank_number: yup.string().trim().length(6).required(),
    bank_series: yup.string().trim().length(4).required(),
    j_city: yup.string().trim().required(),
    j_country: yup.string().trim().required(),
    j_flat: yup.string(),
    j_house: yup.string().trim().required(),
    j_index: yup.string().trim().required().length(6),
    j_region: yup.string().trim().required(),
    j_street: yup.string().trim().required(),
    mail: yup.string().email().required(),
    name: yup
      .string()
      .test(value => value && value.trim().split(' ').length === 3)
      .required(),
    phone: yup
      .string()
      .test(
        value =>
          value &&
          ((value[0] === '+' && value.length > 11) ||
            (value[0] !== '+' && value.length > 10))
      )
      .required()
  });

  const date = new Date();
  const y = date.getFullYear();
  date.setFullYear(y - 26);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Title.H3 style={{marginBottom: 8}}>Личные данные</Title.H3>

        <Wrapper gap={16} isColumn style={{marginBottom: 16}}>
          <Name />
          <Mail />
          <Phone />
        </Wrapper>

        <div style={{marginBottom: 16}}>
          <Title.H3 style={{marginBottom: 8}}>Паспортные данные</Title.H3>

          <Wrapper noWrap style={{marginBottom: 16}}>
            <BankSeries />
            <BankNumber />
            <BankDate />
          </Wrapper>

          <BankAuthority />
        </div>

        <Address prefix="j" placeholder="Адрес" isCollapsible={false} />

        <Line />
        <SaveButton>Отправить</SaveButton>
      </Form>
    </Formik>
  );
};

export default Individual;
