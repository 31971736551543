import React from 'react';
import PropTypes from 'prop-types';

const Image = ({src, alt, style}) => (
  <img
    className="img"
    src={src}
    alt={alt}
    style={style}
  />
);

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object
};

export default Image;
