import React from 'react';
import {t} from 'ttag';

import * as Title from 'components/title/title';
import Text from 'components/text/text';
import * as AppEnv from 'app-env';

import Actions from './actions/actions';
import EntityList from './entity-list/entity-list';

interface Check {
  check: AppEnv.Check;
}

const Check = ({check}: Check) => (
  <div>
    <Title.H3 style={{marginBottom: 4}}>{check.code.phone}</Title.H3>

    <Text style={{marginBottom: 8}} isSmall>
      {t`The phone number is registered on WhatsApp.`}
    </Text>

    {Object.values(check.entities[0]).length ? (
      <EntityList check={check} />
    ) : (
      <Actions check={check} />
    )}
  </div>
);

export default Check;
