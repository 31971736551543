import React from 'react';
import PropTypes from 'prop-types';

import {useConnectionListContext} from '../connection-list-context.js';

import PaymentTemplate from 'components/payment/payment';

const Payment = ({connection, connectionIdList, isTariffChanging, onClose}) => {
  const {tariffList, client, connectionList} = useConnectionListContext();

  return (
    <PaymentTemplate
      client={client}
      connection={connection}
      connectionIdList={connectionIdList}
      isTariffChanging={isTariffChanging}
      onClose={onClose}
      tariffList={tariffList}
      connectionList={connectionList}
    />
  );
};

Payment.propTypes = {
  connection: PropTypes.object,
  connectionIdList: PropTypes.array,
  isTariffChanging: PropTypes.bool,
  onClose: PropTypes.func
};

Payment.defaultProps = {
  connection: {},
  connectionIdList: [],
  isTariffChanging: false,
  onClose: () => {}
};

export default Payment;
