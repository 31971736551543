import React from 'react';
import {useFormikContext, useField} from 'formik';
import {SearchSelect} from '../../../../../../../form/form.jsx';

const BIK = () => {
  const {setFieldValue} = useFormikContext();

  const [field, meta, helpers] = useField('bik');

  const {value} = field;
  const {error, touched} = meta;
  const {setValue} = helpers;

  const handleChange = bank => {
    const values = {
      bik: bank.data.bic,
      bank: bank.label
    };

    Object.entries(values).map(item => setFieldValue(item[0], item[1]));
  };

  const isValid = !(error && touched);

  return (
    <SearchSelect
      input={{
        ...field,
        inputMode: 'tel',
        placeholder: 'БИК',
        isMin: true,
        isValid,
        onChange: setValue
      }}
      query={value}
      path="bank"
      onChange={handleChange}
    />
  );
};

export default BIK;
