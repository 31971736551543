import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import moment from 'moment';

import * as Title from 'components/title/title';
import Advice from 'components/advice/advice';
import Link from 'components/link/link';
import Payment from 'components/payment/payment';
import Text from 'components/text/text';
import Wrapper from 'components/wrapper/wrapper';
import {useAppContext} from 'app-context';
import {useGetTariffPlan, useRequest} from 'common/hooks';
import {getTariffListReq} from 'common/api/api';
import * as AppEnv from 'app-env';

interface PaymentStatus {
  dashboard: Partial<AppEnv.Dashboard>;
  payment: AppEnv.Payment | AppEnv.Subscription | undefined;
}

const PaymentStatus = ({dashboard, payment}: PaymentStatus) => {
  const {lang, user} = useAppContext();

  const [isPaymentActive, setIsPaymentActive] = useState(false);
  const [paymentDate, setPaymentDate] = useState<number>();
  const [expirationDate, setExpirationDate] = useState<number>();

  const [paymentStatus, setPaymentStatus] = useState<
    'paid' | 'premium' | 'trial' | 'unpaid'
  >('unpaid');

  const [tariffList, setTariffList] = useState();

  const {request} = useRequest();
  const getTariffPlan = useGetTariffPlan();

  useEffect(() => {
    getPaymentStatus();
  }, [payment]);

  useEffect(() => {
    getTariffList();
  }, []);

  const getPaymentStatus = () => {
    if (dashboard.integration?.is_premium) return setPaymentStatus('premium');
    else if ((dashboard.integration?.date_trial || 0) * 1000 > Date.now())
      return setPaymentStatus('trial');

    if (!payment) return;

    const lastDate =
      'date_yslug' in payment
        ? payment.date_yslug
        : parseInt(moment(payment.date_add).format('X'));

    setPaymentDate(lastDate);

    const expDate = new Date(lastDate * 1000);

    if (payment.tariff_quantity == 30)
      expDate.setUTCDate(expDate.getUTCDate() + 30);
    else expDate.setUTCMonth(expDate.getUTCMonth() + payment.tariff_quantity);

    setExpirationDate(expDate.getTime() / 1000);

    if (expDate > new Date()) setPaymentStatus('paid');
  };

  const getTariffList = async () => {
    const res = await request(
      getTariffListReq({
        country: undefined,
        crm: 'CHECKER',
        domain: undefined,
        isPartner: 0,
        lang
      })
    );

    setTariffList(res);
  };

  const advices = {
    paid: {
      label: `${t`Paid until`} ${moment.unix(expirationDate || 0).format('L')}`,
      type: 'success'
    },
    premium: {label: t`Premium`, type: 'star'},
    trial: {
      label: `${t`Trial until`} ${moment.unix(dashboard.integration?.date_trial || 0).format('L')}`,
      type: 'info'
    },
    unpaid: {label: t`Requires payment`, type: 'warning'}
  };

  const advice = paymentStatus ? advices[paymentStatus] : undefined;

  const tariffPlan = getTariffPlan({
    id: payment?.tariff_packet,
    quantity: payment?.quantity
  });

  return (
    <>
      {advice && (
        <Wrapper alignItems="flex-start" gap={16} isColumn>
          <Advice
            type={advice.type as any} // eslint-disable-line
            isSmall
            onClick={
              paymentStatus == 'unpaid'
                ? () => setIsPaymentActive(true)
                : undefined
            }
          >
            <Title.H3>{advice.label}</Title.H3>
          </Advice>

          {paymentStatus != 'premium' && (
            <Wrapper isColumn>
              {/* <Text>
                {t`Rate plan`}
                {':'} {'Start'}{' '}
                <Link
                  isFake
                  isUnderlined
                  onClick={() => setIsPaymentActive(true)}
                >{t`change`}</Link>
              </Text> */}

              <Text>
                {t`Rate plan`}
                {':'} {typeof payment?.tariff_packet == 'number' && tariffPlan}
                {typeof payment?.tariff_packet == 'number' && ' '}{' '}
                <Link
                  isFake
                  isUnderlined
                  onClick={() => setIsPaymentActive(true)}
                >
                  {' '}
                  {typeof payment?.tariff_packet == 'number'
                    ? t`change`
                    : t`select`}{' '}
                </Link>
              </Text>

              {!!paymentDate && (
                <Text>
                  {t`Payment date`}
                  {':'}{' '}
                  {moment.unix(paymentDate).utcOffset('+0300').format('L, LT')}
                  {' UTC+3'}
                </Text>
              )}
            </Wrapper>
          )}
        </Wrapper>
      )}

      {isPaymentActive && (
        <Payment
          client={user}
          isTariffChanging={paymentStatus == 'paid'}
          onClose={() => setIsPaymentActive(false)}
          tariffList={tariffList}
        />
      )}
    </>
  );
};

export default PaymentStatus;

{
  /* <Advice
            type={paymentStatus.type}
            isSmall
            onClick={
              paymentStatus.type == 'warning'
                ? () => setIsPaymentOpen(true)
                : undefined
            }
          >
            <Title.H3>{paymentStatus.label}</Title.H3>
          </Advice> */
}

// t`Change the rate plan`
// : t`Select a rate plan`
