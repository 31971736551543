import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Text from '../../text/text.jsx';
import {useNavContext} from '../nav-context.js';

const NavBar = ({linkLike, isUnderscoreHidden, style}) => {
  const {options, value, onClick, onChange} = useNavContext();

  const handleClick = target => {
    if (target !== value) {
      onChange(target);
    }
  };

  return (
    <div
      className="nav__bar"
      style={style}
      onClick={onClick}
    >
      {options.map((item, i) => (
        <div
          key={i}
          className={cn('nav__item', {
            'nav__item_link-like': linkLike,
            'nav__item_without-underscore': isUnderscoreHidden,
            nav__item_active: item.value === value
          })}
          onClick={() => handleClick(item.value)}
        >
          <Text
            color="dark"
            isSpan
            isBold
          >
            {item.label}
          </Text>
        </div>
      ))}
    </div>
  );
};

NavBar.propTypes = {
  linkLike: PropTypes.bool,
  isUnderscoreHidden: PropTypes.bool,
  style: PropTypes.object
};

NavBar.defaultProps = {
  linkLike: false,
  isUnderscoreHidden: false,
  style: {}
};

export default NavBar;
