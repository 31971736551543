import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';

const Name = () => {
  const [field, meta, helpers] = useField('name');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      placeholder="Название организации"
      isValid={isValid}
      onChange={setValue}
    />
  );
};

export default Name;
