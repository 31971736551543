import React, {useState} from 'react';
import {t} from 'ttag';

import Wrapper from '../../../wrapper/wrapper.jsx';
import Confirmation from '../../../confirmation/confirmation.jsx';
import Tooltip from '../../../tooltip/tooltip.jsx';
import Button from '../../../button/button';
import Icons from '../../../icons/icons.jsx';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';
import useDeleteConnection from '../delete-connection.js';

const Deleting = () => {
  const {isSelection} = useConnectionListContext();
  const {connection} = useConnectionContext();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const deleteConnection = useDeleteConnection();

  const isUnavailable = !!(connection.st && !connection.st.state);

  return (
    <Wrapper gap={0} noWrap style={{margin: '-8px -8px 0 0'}}>
      {isUnavailable && (
        <Tooltip type="warning">
          {t`Connection is unavailable. Please contact support.`}
        </Tooltip>
      )}

      <Button
        color="transparent"
        isDisabled={isSelection}
        onClick={() => setIsConfirmationOpen(true)}
      >
        <Icons.Trash />
      </Button>

      {isConfirmationOpen && (
        <Confirmation
          title={t`Are you sure you want to delete this connection?`}
          onClick={res =>
            res ? deleteConnection() : setIsConfirmationOpen(false)
          }
        />
      )}
    </Wrapper>
  );
};

export default Deleting;
