import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {SingleSelect} from '../../../../../../form/form';
import * as Title from '../../../../../../title/title.jsx';
import {useConnectionListContext} from '../../../../../connection-list-context';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useSettingsContext} from '../../../settings-context';

const PipelineId = () => {
  const {pipelineList} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField('pipeline_id');
  const [, , stageIdHelpers] = useField('stage_id');

  const handleChange = (value = 0) => {
    if (value != field.value) stageIdHelpers.setValue(0);
    helpers.setValue(typeof value == 'number' ? value : 0);
  };

  const options = [{label: t`Lead`, value: -1, group: ''}];

  options.push(
    ...pipelineList.map(item => ({
      label: item.name,
      value: item.id,
      group: t`Pipelines`
    }))
  );

  const isSearchAvailable = options.length > 7;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>
        {t`Lead` + ' / ' + t`Pipeline`}
      </Title.H3>

      <SingleSelect
        {...field}
        value={connection.is_limited ? undefined : field.value}
        options={options}
        placeholder={t`Pipeline`}
        isSearchAvailable={isSearchAvailable}
        isClearable
        isDisabled={connection.is_limited}
        onChange={handleChange}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
      />
    </div>
  );
};

export default PipelineId;
