import React from 'react';
import PropTypes from 'prop-types';
import {useField} from 'formik';
import {t} from 'ttag';

import {Input} from '../../form/form.jsx';

const Password = ({passwordHint}) => {
  const [field, meta, helpers] = useField('password');

  const placeholder = passwordHint || t`Password`;
  const isValid = !(meta.touched && meta.error);

  return (
    <Input
      {...field}
      type="password"
      placeholder={placeholder}
      isValid={isValid}
      autoFocus
      onChange={helpers.setValue}
    />
  );
};

Password.propTypes = {
  passwordHint: PropTypes.string
};

Password.defaultProps = {
  passwordHint: ''
};

export default Password;
