import React from 'react';
import {useField} from 'formik';
import {SearchSelect} from '../../../../../../../form/form.jsx';
import {useAddressContext} from '../address-context.js';

const Region = () => {
  const {prefix} = useAddressContext();

  const [countryField, , countryHelpers] = useField(`${prefix}_country`);
  const [field, meta, helpers] = useField(`${prefix}_region`);

  const handleChange = region => {
    const {data} = region;

    countryHelpers.setValue(data.country);
    countryHelpers.setTouched(false);

    helpers.setValue(data.region_with_type);
  };

  const isValid = !(meta.error && meta.touched);

  return (
    <SearchSelect
      input={{
        ...field,
        placeholder: 'Регион',
        isValid,
        onChange: helpers.setValue
      }}
      path="address"
      query={field.value}
      fromBound="region"
      toBound="region"
      locations={[{country: countryField.value}]}
      onChange={handleChange}
    />
  );
};

export default Region;
