import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {Input} from '../../../../../../../../form/form.jsx';
import {normalizePhoneNumber} from '../../../../../../../../../common/actions.js';

const Phone = () => {
  const [field, meta, helpers] = useField('phone');
  const isValid = !(meta.touched && meta.error);

  return (
    <Input
      {...field}
      type="tel"
      placeholder={t`Phone number`}
      isValid={isValid}
      style={{marginBottom: 16}}
      onChange={value => helpers.setValue(normalizePhoneNumber(value))}
    />
  );
};

export default Phone;
