import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Input} from '../../../../../../components/form/form.jsx';
import * as Title from '../../../../../../components/title/title.jsx';

const Email = () => {
  const [field, meta, helpers] = useField('email');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Email`}</Title.H3>

      <Input
        {...field}
        type="email"
        placeholder={t`Email`}
        isValid={isValid}
        style={{marginBottom: 16}}
        onChange={value => setValue(value.toLowerCase())}
      />
    </>
  );
};

export default Email;
