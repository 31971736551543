import React from 'react';
import {useField} from 'formik';
import PipeId from './pipe-id/pipe-id.jsx';
import StatusId from './status-id/status-id.jsx';
import Strategy from './strategy/strategy.jsx';
import Managers from './manager-id/manager-id.jsx';
import PipelineSettingsContext from './pipeline-settings-context.js';
import Wrapper from '../../../../../../wrapper/wrapper.jsx';
import {useConnectionListContext} from '../../../../../connection-list-context.js';

const PipelineSettings = () => {
  const {pipelineList} = useConnectionListContext();

  const [field] = useField('pipeline_settings');
  const {value: pipeline_settings} = field;

  const {pipe_id, status_id} = pipeline_settings[0] || {};
  const {statuses} = pipelineList.find(item => item.id === pipe_id) || {};

  const isUnassembledStage = statuses
    ? !!statuses.find(item => item.id === status_id)?.unsorted
    : false;

  return (
    <PipelineSettingsContext.Provider value={{status_id, isUnassembledStage}}>
      <Wrapper
        gap={16}
        isColumn
      >
        <PipeId />
        <StatusId statuses={statuses} />
        <Strategy />
        <Managers />
      </Wrapper>
    </PipelineSettingsContext.Provider>
  );
};

export default PipelineSettings;
