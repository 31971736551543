import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';
import {useAddressContext} from '../address-context.js';

const Country = () => {
  const {prefix} = useAddressContext();

  const [field, meta, helpers] = useField(`${prefix}_country`);
  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      placeholder="Страна"
      isMin
      isValid={isValid}
      onChange={setValue}
    />
  );
};

export default Country;
