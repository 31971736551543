import React from 'react';
import {useField} from 'formik';
import * as Title from '../../../../../../../title/title.jsx';
import {Input} from '../../../../../../../form/form.jsx';

const TelphinCode = () => {
  const [field, meta, helpers] = useField('telphin_code');

  const isValid = !(meta.error && meta.touched);

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>Код клиента «Телфин»</Title.H3>

      <Input
        {...field}
        placeholder="Код клиента «Телфин»"
        maxLength={8}
        isValid={isValid}
        onChange={value => helpers.setValue(value.toUpperCase())}
      />
    </>
  );
};

export default TelphinCode;
