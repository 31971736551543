import React, {useState} from 'react';
import update from 'immutability-helper';
import * as Title from '../../../../../../components/title/title.jsx';
import Text from '../../../../../../components/text/text.jsx';
import Link from '../../../../../../components/link/link.jsx';
import Button from '../../../../../../components/button/button';
import Confirmation from '../../../../../../components/confirmation/confirmation.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {updateUserReq} from '../../../../../../common/api/api.js';

const Info = () => {
  const {user, setUser} = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();

  const resetTelphinCode = async () => {
    const res = await request(updateUserReq(user.id, {telphin_code: ''}));

    if ('telphin_code' in res) {
      setUser(
        update(user, {
          telphin_code: {$set: res.telphin_code}
        })
      );
    }

    return true;
  };

  return (
    <>
      {user.telphin_email ? (
        <>
          <Title.H3 style={{marginBottom: 8}}>
            {'Вы успешно зарегистрировались'}
          </Title.H3>

          <Text style={{marginBottom: 16}}>
            {`Ваш код клиента `}
            <Text color="dark" isSpan isBold>
              {user.telphin_code}
            </Text>
            {`. На электронную почту `}
            <Text color="dark" isSpan isBold>
              {user.telphin_email}
            </Text>
            {` отправлены логин и пароль для доступа в `}
            <Link href="https://cabinet.telphin.ru" target="_blank">
              личный кабинет
            </Link>
            .
          </Text>
        </>
      ) : (
        <>
          <Title.H3 style={{marginBottom: 8}}>
            {'Вы уже зарегистрированы'}
          </Title.H3>

          <Text style={{marginBottom: 16}}>
            {`Ваш код клиента `}
            <Text color="dark" isSpan isBold>
              {user.telphin_code}
            </Text>
            .
          </Text>
        </>
      )}

      <Button color="white" onClick={() => setIsOpen(true)}>
        Сбросить
      </Button>

      {isOpen && (
        <Confirmation
          title="Вы уверены, что хотите сбросить код клиента?"
          onClick={res => (res ? resetTelphinCode() : setIsOpen(false))}
        />
      )}
    </>
  );
};

export default Info;
