import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';
import {isolateNumbers} from '../../../../../../../../common/actions.js';

const BankNumber = () => {
  const [field, meta, helpers] = useField('bank_number');

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      inputMode="tel"
      placeholder="Номер"
      maxLength={6}
      isValid={isValid}
      onChange={value => helpers.setValue(isolateNumbers(value))}
    />
  );
};

export default BankNumber;
