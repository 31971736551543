import {t} from 'ttag';

const getAvitoFeatureList = () => [
  {
    name: t`Unlimited dialogues`,
    Start: true,
    Pro: true,
    Enterprise: true
  },
  {
    name: t`Sending and receiving files`,
    Start: true,
    Pro: true,
    Enterprise: true
  },
  {
    name: t`Link to bulletin`,
    Start: true,
    Pro: true,
    Enterprise: true
  },
  {
    name: t`Avito Career`,
    Start: true,
    Pro: true,
    Enterprise: true
  },
  {
    name: t`Working with Salesbot`,
    Start: true,
    Pro: true,
    Enterprise: true
  },
  {
    name: 'Whatcrm Web',
    Start: false,
    Pro: true,
    Enterprise: true
  },
  {
    name: t`Missed calls`,
    Start: false,
    Pro: true,
    Enterprise: true
  },
  {
    name: t`Distribution of incoming orders`,
    Start: false,
    Pro: true,
    Enterprise: true
  }
];

export default getAvitoFeatureList;
