const getNextPaymentDate = (quantity: 30 | 3 | 6 | 12) => {
  const value = new Date();

  if (quantity !== 30) {
    return value.setMonth(value.getMonth() + quantity) / 1000;
  }

  return value.setDate(value.getDate() + 30) / 1000;
};

export default getNextPaymentDate;
