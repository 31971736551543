import React from 'react';
import cn from 'classnames';

import Closer from 'components/closer/closer';

interface Advice {
  children: React.ReactNode;
  onClose?: () => void;
  isSmall?: boolean;
  isWhite?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  type?: 'info' | 'star' | 'success' | 'warning';
}

const Advice = ({
  children,
  onClose,
  isSmall,
  isWhite,
  onClick,
  style,
  type
}: Advice) => (
  <div
    className={cn('advice', {
      [`advice_type-${type}`]: type,
      advice_clickable: onClick,
      advice_small: isSmall,
      advice_white: isWhite
    })}
    style={style}
    onClick={() => (onClick ? onClick() : undefined)}
  >
    {onClose && <Closer color="true-transparent" onClick={onClose} />}
    {type && <div className="advice__icon" />}
    {children}
  </div>
);

export default Advice;
