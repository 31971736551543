import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import * as Title from '../../../../../../title/title.jsx';
import {SingleSelect} from '../../../../../../form/form.jsx';
import {useConnectionContext} from 'components/connection-list/connection/connection-context.js';
import {useSettingsContext} from '../../../settings-context.js';

const StageId = () => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen, groupList} = useSettingsContext();

  const [field, , helpers] = useField('stage_id');
  const [pipelineIdField] = useField('pipeline_id');

  const options = groupList
    .filter(item => item.pipeline_id == pipelineIdField.value)
    .map(item => ({label: item.name, value: item.id}));

  const isSearchAvailable = options?.length > 7;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Deal stage`}</Title.H3>

      <SingleSelect
        {...field}
        value={connection.is_limited ? undefined : field.value}
        options={options || []}
        placeholder={
          !pipelineIdField.value || connection.is_limited || options
            ? t`Deal stage`
            : t`Loading...`
        }
        isDisabled={
          !options?.length ||
          connection.is_limited ||
          pipelineIdField?.value < 1
        }
        isSearchAvailable={isSearchAvailable}
        onChange={helpers.setValue}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
      />
    </div>
  );
};

export default StageId;
