import React from 'react';
import {Formik, Form} from 'formik';
import {t} from 'ttag';
import update from 'immutability-helper';

import Button, {SaveButton} from 'components/button/button';
import EmptyBox from 'components/empty-box/empty-box';
import Line from 'components/line/line';
import Preloader from 'components/preloader/preloader';
import Wrapper from 'components/wrapper/wrapper';
import {useAppContext} from 'app-context';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useConnectionListContext} from 'components/connection-list/connection-list-context';
import {useRequest} from 'common/hooks';
import {useSettingsContext} from '../../settings-context';
import {updateConnectionConfigReq} from 'common/api/avito';

import PipeId from './pipe-id/pipe-id';
import StatusId from './status-id/status-id';
import ManagerId from './manager-id/manager-id';
import Strategy from './strategy/strategy';

const Avito = () => {
  const {setNotificationList} = useAppContext();
  const {client, setSettingsId} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig, updateConnectionConfig} = useSettingsContext();

  const {crmRequest} = useRequest();

  // eslint-disable-next-line
  const handleSubmit = async (values: any) => {
    const res = await crmRequest({
      crm: client.crm,
      ...updateConnectionConfigReq({
        domain: client.domain,
        id: connection.id,
        data: values
      })
    } as any); // eslint-disable-line

    if (Object.keys(res).length > 3) {
      // eslint-disable-next-line
      setNotificationList((prevValue: any) =>
        update(prevValue, {
          $push: [
            {
              title: connection.label || `${t`Connection`} ${connection.id}`,
              text: t`The settings have been successfully saved.`
            }
          ]
        })
      );

      updateConnectionConfig(res);
      setSettingsId(-1);
    }

    return true;
  };

  const initialValues = {
    pipeline_settings: connectionConfig.pipeline_settings || [],
    strategy: connectionConfig.strategy
  };

  return (
    <>
      {connectionConfig ? (
        Object.keys(connectionConfig).length ? (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <Wrapper gap={16} isColumn>
                <PipeId />
                <StatusId />
                <Strategy />
                <ManagerId />
              </Wrapper>

              <Line />

              <Wrapper>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="white" onClick={() => setSettingsId(-1)}>
                  {t`Cancel`}
                </Button>
              </Wrapper>
            </Form>
          </Formik>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Avito;
