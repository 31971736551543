import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import ReactCollapsible from 'react-collapsible';
import Trigger from './trigger/trigger.jsx';
import Nav from '../nav/nav.jsx';
import {scrollToPosition} from '../../common/actions.js';

const Section = ({children, title, navParams, isDefaultOpen}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const ref = useRef();

  const handleOpen = () => {
    setIsOpen(true);

    const {top} = ref.current.getBoundingClientRect();
    scrollToPosition(top);
  };

  const handleClick = e => {
    e.stopPropagation();

    if (!isOpen) {
      handleOpen(true);
    }
  };

  const trigger = (
    <Trigger
      title={title}
      isNav={navParams?.options.length > 1}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOpen={handleOpen}
    />
  );

  return (
    <div
      className="section section_collapsible"
      ref={ref}
    >
      <Nav
        {...navParams}
        onClick={handleClick}
      >
        <ReactCollapsible
          trigger={trigger}
          triggerDisabled={true}
          transitionTime={200}
          open={isOpen}
          overflowWhenOpen="visible"
          onTriggerOpening={() => handleOpen()}
          onTriggerClosing={() => setIsOpen(false)}
        >
          <div className="section__body">{children}</div>
        </ReactCollapsible>
      </Nav>
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  navParams: PropTypes.object,
  isDefaultOpen: PropTypes.bool
};

Section.defaultProps = {
  children: <></>,
  title: '',
  navParams: null,
  isDefaultOpen: false
};

export default Section;
