import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useNavContext} from '../nav-context.js';

const NavTab = ({children, tab}) => {
  const {value} = useNavContext();

  return (
    <div
      className={cn('nav__tab', {
        nav__tab_shown: value === tab
      })}
    >
      {children}
    </div>
  );
};

NavTab.propTypes = {
  children: PropTypes.node,
  tab: PropTypes.string
};

NavTab.defaultProps = {
  children: <></>,
  tab: ''
};

export default NavTab;
