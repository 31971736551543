import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../components/title/title.jsx';
import {Dropzone} from '../../../../../../components/form/form.jsx';
import Image from '../../../../../../components/image/image.jsx';
import {useRequest} from '../../../../../../common/hooks';
import {loadDataReq} from '../../../../../../common/api/api.js';

const Logo = () => {
  const [field, , helpers] = useField('image');

  const {value} = field;
  const {setValue} = helpers;

  const {request} = useRequest();

  const handleChange = async fileList => {
    const file = fileList[0];

    if (file.type.includes('image') && file.size <= 5242880) {
      const res = await request(
        loadDataReq({
          category: 'integrators',
          file
        })
      );

      setValue(res);
    }
  };

  const style = {
    width: 56,
    height: 56,
    marginBottom: 8,
    borderRadius: 2
  };

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Logotype (112×112)`}</Title.H3>

      {value && <Image src={value} style={style} />}

      <Dropzone
        accept={{'image/*': []}}
        style={{marginBottom: 16}}
        onChange={handleChange}
      />
    </>
  );
};

export default Logo;
