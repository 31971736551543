import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../text/text.jsx';

const OptionList = ({options, onChange}) => (
  <div className="select__options">
    {options.map(item => (
      <div
        key={item.value}
        className="select__options-item"
        onClick={() => onChange(item)}
      >
        <Text
          color="dark"
          isSpan
        >
          {item.label}
        </Text>
      </div>
    ))}
  </div>
);

OptionList.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func
};

OptionList.defaultProps = {
  options: [],
  onChange: () => {}
};

export default OptionList;
