import React from 'react';
import ShortUniqueId from 'short-unique-id';

import {useAppContext} from '../../app-context.js';

import Item from './item/item.jsx';

const NotificationList = () => {
  const {notificationList} = useAppContext();
  const uid = new ShortUniqueId({length: 10});

  return (
    <div className="notification-list">
      {notificationList.map((item, i) => (
        <Item key={uid.rnd()} index={i} data={item} />
      ))}
    </div>
  );
};

export default NotificationList;
