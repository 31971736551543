import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App.js';
// import 'whatcrm-core/dist/main.css';
import './styles/main.sass';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
