import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {Toggle} from 'components/form/form';

const DisableUpdate = () => {
  const [field, , helpers] = useField('disable_update');

  return (
    <Toggle
      label={t`Disable messages in updates`}
      isChecked={field.value}
      onChange={helpers.setValue}
    />
  );
};

export default DisableUpdate;
