import React from 'react';
import {useFormikContext, useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../title/title.jsx';
import {MultiSelect} from '../../form/form.jsx';

const Domains = () => {
  const {handleSubmit} = useFormikContext();
  const [field, , helpers] = useField('domains');

  const handleChange = value => {
    helpers.setValue(value);
    handleSubmit();
  };

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Domains`}</Title.H3>

      <MultiSelect
        options={field.value}
        placeholder={t`Choose`}
        onChange={handleChange}
      />
    </>
  );
};

export default Domains;
