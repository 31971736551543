import React from 'react';
import {useField} from 'formik';
import {SearchSelect} from '../../../../../../../form/form.jsx';
import {useAddressContext} from '../address-context.js';

const City = () => {
  const {prefix} = useAddressContext();

  const [countryField, , countryHelpers] = useField(`${prefix}_country`);
  const [, , regionHelpers] = useField(`${prefix}_region`);
  const [field, meta, helpers] = useField(`${prefix}_city`);

  const handleChange = city => {
    const {data} = city;

    countryHelpers.setValue(data.country);
    countryHelpers.setTouched(false);

    regionHelpers.setValue(data.region_with_type);
    regionHelpers.setTouched(false);

    helpers.setValue(data.city_with_type || data.settlement_with_type);
  };

  const isValid = !(meta.error && meta.touched);

  return (
    <SearchSelect
      input={{
        ...field,
        placeholder: 'Город или населенный пункт',
        isValid,
        onChange: helpers.setValue
      }}
      path="address"
      query={field.value}
      locations={[{country: countryField.value}]}
      fromBound="city"
      toBound="settlement"
      onChange={handleChange}
    />
  );
};

export default City;
