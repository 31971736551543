import React, {useState} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Prewiew from './preview/preview.jsx';
import Wrapper from '../../../../../../components/wrapper/wrapper.jsx';
import Link from '../../../../../../components/link/link.jsx';
import Text from '../../../../../../components/text/text.jsx';
import Button from '../../../../../../components/button/button';
import Icons from '../../../../../../components/icons/icons.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useEditorContext} from '../../editor-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {getMessageTemplateAttachmentReq} from '../../../../../../common/api/api.js';

const Attachment = ({attachment, index}) => {
  const {user} = useAppContext();
  const {messageTemplate, setAttachmentList} = useEditorContext();

  const [preview, setPreview] = useState();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const {request} = useRequest();

  const processFile = file =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => resolve(fileReader.result);
      fileReader.onerror = reject;
    });

  const handleClick = async () => {
    setIsPreviewOpen(true);

    if (attachment.id) {
      const res = await request(
        getMessageTemplateAttachmentReq(
          user.id,
          messageTemplate.id,
          attachment.id
        )
      );

      setPreview(res);
      return;
    }

    const blob = (await processFile(attachment.file)) || '';
    setPreview({...attachment, link: blob});
  };

  const handleRemove = () =>
    setAttachmentList(prevValue =>
      update(prevValue || [], {[index]: {isRemovable: {$set: true}}})
    );

  const isPreviewAvailable =
    attachment.type.includes('image') || attachment.type.includes('video');

  return (
    <>
      {!attachment.isRemovable && (
        <Wrapper justifyContent="space-between" alignItems="center" noWrap>
          {isPreviewAvailable ? (
            <Link isFake onClick={handleClick}>
              {attachment.title}
            </Link>
          ) : (
            <Text color="dark" isSpan isOneLine>
              {attachment.title}
            </Text>
          )}

          <Wrapper alignItems="center" gap={0} noWrap>
            {attachment.link && (
              <Button
                color="transparent"
                href={attachment.link}
                target="_blank"
                isLink
              >
                <Icons.Download />
              </Button>
            )}

            <Button color="transparent" onClick={handleRemove}>
              <Icons.Trash />
            </Button>
          </Wrapper>
        </Wrapper>
      )}

      {isPreviewOpen && (
        <Prewiew preview={preview} onClose={() => setIsPreviewOpen(false)} />
      )}
    </>
  );
};

Attachment.propTypes = {
  attachment: PropTypes.shape({
    file: PropTypes.object,
    id: PropTypes.number,
    link: PropTypes.string,
    template_id: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    isRemovable: PropTypes.bool
  }),
  index: PropTypes.number
};

Attachment.defaultProps = {
  attachment: {},
  index: -1
};

export default Attachment;
