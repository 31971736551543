import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from '../../../../wrapper/wrapper.jsx';
import Text from '../../../../text/text.jsx';

const Label = ({text, number}) => (
  <Wrapper
    gap={3}
    noWrap
  >
    <Text
      color="dark"
      isOneLine
    >
      {text}
    </Text>

    <Text color="dark">({number})</Text>
  </Wrapper>
);

Label.propTypes = {
  text: PropTypes.node,
  number: PropTypes.number
};

export default Label;
