import React from 'react';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import * as Title from 'components/title/title.jsx';
import Button from 'components/button/button';
import Image from 'components/image/image.jsx';
import Text from 'components/text/text.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';
import {useAppContext} from 'app-context.js';
import {useRequest} from 'common/hooks';
import {updateUserReq} from 'common/api/api.js';

const Integration = ({integration}) => {
  const {user, setUser} = useAppContext();
  const {request} = useRequest();

  const handleClick = async widget_code => {
    const res = await request(updateUserReq(user.id, {widget_code}));

    if ('widget_code' in res)
      setUser(update(user, {widget_code: {$set: res.widget_code}}));
  };

  const image = integration.image || '/static/img/products/integration.svg';
  const isConnected = user.widget_code === integration.title;
  const status = integration.status || (isConnected ? t`connected` : null);

  return (
    <div className="product">
      <div>
        <Image src={image} style={{marginBottom: 16}} />

        <Title.H3 style={{marginBottom: 8}}>
          {integration.description_short}
        </Title.H3>

        <Text style={{marginBottom: !user.is_integration ? 16 : undefined}}>
          {integration.description}
        </Text>
      </div>

      <Wrapper alignItems="center">
        {!user.is_integration && (
          <Button
            color={isConnected ? 'orange' : 'blue'}
            onClick={() => handleClick(isConnected ? '' : integration.title)}
          >
            {isConnected ? t`Disconnect` : t`Connect`}
          </Button>
        )}

        {integration.link && (
          <Button color="white" href={integration.link} target="_blank" isLink>
            {t`Description`}
          </Button>
        )}
      </Wrapper>

      {status && (
        <div className="product__status">
          <Text color="white" isSmall>
            {status}
          </Text>
        </div>
      )}
    </div>
  );
};

Integration.propTypes = {
  integration: PropTypes.shape({
    description: PropTypes.string,
    description_short: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    is_registration: PropTypes.number,
    link: PropTypes.string,
    title: PropTypes.string,
    token_id: PropTypes.number,
    webhook: PropTypes.string,
    webhook_chat: PropTypes.string,
    webhook_pay: PropTypes.string,
    status: PropTypes.string
  })
};

export default Integration;
