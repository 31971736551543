import React, {useContext} from 'react';

interface TransitionContext {
  method: 'cloud' | 'stripe' | 'telphin' | undefined;
}

const TransitionContext = React.createContext<TransitionContext>(
  {} as TransitionContext
);

export const useTransitionContext = () => useContext(TransitionContext);
export default TransitionContext;
