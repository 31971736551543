import React, {useState} from 'react';
import {t} from 'ttag';

import Text from '../../../../../text/text.jsx';
import Wrapper from '../../../../../wrapper/wrapper.jsx';
import Button from '../../../../../button/button';
import Icons from '../../../../../icons/icons.jsx';
import Link from '../../../../../link/link.jsx';
import Confirmation from '../../../../../confirmation/confirmation.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import useDeleteConnection from '../../../delete-connection.js';

const Output = () => {
  const {client, setOpenConnectionId} = useConnectionListContext();
  const {connection, isConnected} = useConnectionContext();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const deleteConnection = useDeleteConnection();

  const logout = async () => {
    await deleteConnection({
      version: connection.version,
      label: connection.label
    });

    return true;
  };

  return (
    <>
      {client.isCustomer ? (
        isConnected && (
          <Text>
            {t`Phone number:`} +{connection.phone}
          </Text>
        )
      ) : (
        <>
          <Wrapper alignItems="center" gap={0}>
            <Text isSpan>
              {t`Phone number:`}
              {' '}
            </Text>

            {typeof isConnected == 'boolean' ? (
              isConnected ? (
                <Wrapper alignItems="center" gap={0}>
                  <Text isSpan>+{connection.phone}</Text>

                  <Button
                    color="transparent"
                    style={{height: 16}}
                    onClick={() => setIsConfirmationOpen(true)}
                  >
                    <Icons.Pencil />
                  </Button>
                </Wrapper>
              ) : (
                <Link
                  isFake
                  isUnderlined
                  onClick={() => setOpenConnectionId(connection.id)}
                >
                  {connection.version === 'whatcrm'
                    ? t`connect WhatsApp`
                    : connection.version === 'telegram'
                      ? t`connect Telegram`
                      : t`connect`}
                </Link>
              )
            ) : (
              <Text isSpan>{t`Loading...`}</Text>
            )}
          </Wrapper>
        </>
      )}

      {isConfirmationOpen && (
        <Confirmation
          title={t`Are you sure you want to change your phone number?`}
          onClick={res => (res ? logout() : setIsConfirmationOpen(false))}
        />
      )}
    </>
  );
};

export default Output;
