import React from 'react';
import {t} from 'ttag';

import Wrapper from '../../../../wrapper/wrapper.jsx';
import Text from '../../../../text/text.jsx';
import Link from '../../../../link/link.jsx';
import {useConnectionListContext} from '../../../connection-list-context.js';
import {useConnectionContext} from '../../connection-context.js';
import Button from '../../../../button/button';

const Tariff = () => {
  const {isConnector} = useConnectionListContext();
  const {connection, setIsPaymentOpen} = useConnectionContext();

  const isShown =
    connection.condition !== 'premium' && (connection.is_paid || !isConnector);

  return (
    <>
      {isShown && (
        <Wrapper
          alignItems="center"
          gap={4}
        >
          <Text>
            {t`Tariff plan:`}
            {connection.is_paid && <> {connection.tariff_plane}</>}
          </Text>

          {!isConnector && (
            <Text>
              {
                connection.is_paid ?
                  <Link
                    isFake
                    isUnderlined
                    onClick={() => setIsPaymentOpen(true)}
                  >
                    {t`change`}
                  </Link>
                  : <Button style={{height: 24}} onClick={() => setIsPaymentOpen(true)}>
                    {t`Pay`}
                  </Button>
              }
            </Text>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Tariff;
