import React, {useEffect} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';

import Entry from '../entry/entry.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';

const Monday = () => {
  const {connectionList, pipelineList, setConnectionList, managerList} =
    useConnectionListContext();

  const {connection} = useConnectionContext();
  const {connectionConfig, groupList} = useSettingsContext();

  const board_id = connectionConfig.board_id || undefined;

  const board = pipelineList.find(item => item.board_id === board_id);

  let group = groupList?.find(item => item.id == connectionConfig.group_id);
  group = group?.is_deleted ? undefined : group;

  const connectionIndex = connectionList.findIndex(
    item => item.id === connection.id
  );

  useEffect(() => {
    setConnectionList(prevValue =>
      update(prevValue, {
        [connectionIndex]: {
          is_configured: {$set: !!board}
        }
      })
    );
  }, [board]);

  const getStrategy = () => {
    if (connectionConfig.strategy == 'random') return t`Random`;
    else if (connectionConfig.strategy == 'uniform') return t`Uniform`;
  };

  const manager_id = connectionConfig?.responsible_id?.[0];

  const manager = managerList.find(item => item.id == manager_id);

  const managers = manager?.fullname ? (
    <span style={{display: 'flex'}}>
      <span className="one-line">{manager.fullname}</span>

      {connectionConfig &&
        connectionConfig.responsible_id &&
        connectionConfig.responsible_id.length > 1 && (
          <span>{` +${connectionConfig.responsible_id.length - 1}`}</span>
        )}
    </span>
  ) : undefined;

  return (
    <>
      <Entry
        label={t`Board`}
        value={
          pipelineList?.length
            ? board?.board_name || t`A selection is required`
            : t`Loading...`
        }
        color={pipelineList?.length && !board ? 'yellow' : undefined}
      />

      <Entry
        label={t`Group of leads`}
        value={
          groupList ? group?.title || t`A selection is required` : t`Loading...`
        }
        color={groupList && !group ? 'yellow' : undefined}
      />

      <Entry label={t`Strategy`} value={getStrategy()} />

      {!connection.is_limited && <Entry label={t`Managers`} value={managers} />}

      <Entry
        label={t`Working with groups`}
        value={
          connectionConfig.is_group && !connection.is_limited ? t`Yes` : t`No`
        }
      />
    </>
  );
};

export default Monday;
