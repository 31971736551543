import React from 'react';
import {t} from 'ttag';
import {useField} from 'formik';
import Text from '../../../../../components/text/text.jsx';
import {Input} from '../../../../../components/form/form.jsx';

const Title = () => {
  const [field, meta, helpers] = useField('title');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <>
      <Text style={{marginBottom: 8}}>{t`Template name`}</Text>

      <Input
        {...field}
        placeholder={t`Template name`}
        isValid={isValid}
        isMax
        style={{marginBottom: 16}}
        onChange={setValue}
      />
    </>
  );
};

export default Title;
