import React, {useRef, forwardRef} from 'react';
import cn from 'classnames';
import {useWithIcon} from '../../common/hooks';

interface Button {
  children: React.ReactNode;
  color?: 'blue' | 'orange' | 'transparent' | 'white';
  href?: string;
  isDisabled?: boolean;
  isLink?: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
  target?: '_blank' | '_self';
  type?: 'button' | 'submit';
}

const Button = forwardRef<any, Button>( // eslint-disable-line
  (
    {
      children,
      color = 'blue',
      href,
      isDisabled,
      isLink,
      onClick,
      style,
      target,
      type = 'button'
    },
    forwardedRef
  ) => {
    const innerRef = useRef<any>(null); // eslint-disable-line
    const ref = forwardedRef || innerRef;

    const withIcon = useWithIcon(ref);

    const params = {
      className: cn('btn', {
        'btn_with-icon': withIcon,
        'one-line': color != 'transparent',
        [`btn_${color}`]: color
      }),
      disabled: isDisabled,
      onClick,
      ref,
      style,
      type
    };

    const rel = target == '_blank' ? 'noreferrer' : undefined;

    return (
      <>
        {isLink ? (
          <a {...params} href={href} target={target} rel={rel}>
            {children}
          </a>
        ) : (
          <button {...params}>{children}</button>
        )}
      </>
    );
  }
);

Button.displayName = 'Button';

export {default as SaveButton} from './save-button/save-button.jsx';
export default Button;
