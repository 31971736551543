import React, {useState} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {t} from 'ttag';

import {
  TableCell,
  TableRow
} from '../../../../../../components/table/table.jsx';
import Button from '../../../../../../components/button/button';
import Confirmation from '../../../../../../components/confirmation/confirmation.jsx';
import Icons from '../../../../../../components/icons/icons.jsx';
import Image from 'components/image/image.jsx';
import Text from 'components/text/text.jsx';
import Tooltip from 'components/tooltip/tooltip.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';

import {useAppContext} from '../../../../../../app-context.js';
import {useCustomerContext} from '../customer-context.js';
import {useCustomerListContext} from '../../customer-list-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {customerPropTypes} from '../../prop-types.js';
import {deleteCustomerReq} from '../../../../../../common/api/api.js';

const BasicInfo = ({error, isOpen, setIsOpen}) => {
  const {user} = useAppContext();
  const {customerList, crmList, setCustomerList} = useCustomerListContext();
  const {customer} = useCustomerContext();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const {request} = useRequest();

  const deleteCustomer = async () => {
    const res = await request(
      deleteCustomerReq(user.id, {
        crm: customer.type_crm,
        domain: customer.domain
      })
    );

    if (res.success) {
      const index = customerList.findIndex(item => item.id === customer.id);

      setCustomerList(
        update(customerList, {
          $splice: [[index, 1]]
        })
      );
    }

    return true;
  };

  const crm = crmList[customer.type_crm];

  return (
    <TableRow>
      <TableCell>{customer.domain}</TableCell>

      <TableCell>
        <Wrapper alignItems="center">
          <Image src={crm?.icon} style={{height: 16}} />
          <Text color="dark">{crm?.name}</Text>
        </Wrapper>
      </TableCell>

      <TableCell isIcon>
        {error && <Tooltip type="warning">{error}</Tooltip>}
      </TableCell>

      <TableCell isIcon>
        <Button color="transparent" onClick={() => setIsConfirmationOpen(true)}>
          <Icons.Trash />
        </Button>

        {isConfirmationOpen && (
          <Confirmation
            title={t`Are you sure you want to remove your customer ${customer.domain}?`}
            onClick={res =>
              res ? deleteCustomer() : setIsConfirmationOpen(false)
            }
          />
        )}
      </TableCell>

      <TableCell isIcon>
        <Button color="transparent" onClick={() => setIsOpen(!isOpen)}>
          <Icons.ArrowDown isRotate={isOpen} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

BasicInfo.propTypes = {
  customer: customerPropTypes,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired
};

export default BasicInfo;
