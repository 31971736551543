import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import * as Title from '../../../../../../title/title.jsx';
import {SingleSelect} from '../../../../../../form/form.jsx';
import {useSettingsContext} from '../../../settings-context.js';

const GroupId = () => {
  const {requestGroupList} = useSettingsContext();

  const [options, setOptions] = useState([]);

  const [{value: boardId}] = useField('board_id');
  const [field, , helpers] = useField('group_id');

  const getOptions = async () => {
    setOptions([]);

    const res = await requestGroupList(boardId);
    const groups = res.map(item => ({label: item.title, value: item.id}));

    setOptions(groups);
  };

  useEffect(() => {
    getOptions();
  }, [boardId]);

  const isSearchAvailable = options?.length > 7;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Group of leads`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={options.length ? t`Group of leads` : t`Loading...`}
        isDisabled={!options.length}
        isSearchAvailable={isSearchAvailable}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default GroupId;
