import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {Toggle} from 'components/form/form';

const DisableItem = () => {
  const [field, , helpers] = useField<boolean>('disable_item');

  return (
    <Toggle
      label={t`Receive messages from contacts only`}
      isChecked={field.value}
      onChange={helpers.setValue}
    />
  );
};

export default DisableItem;
