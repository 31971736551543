import React from 'react';
import PropTypes from 'prop-types';

import {Checkbox} from '../../../../form.jsx';

const Option = ({matchingOptionIds, onChange, option}) => {
  const handleChange = value =>
    onChange({value: option.value, isChecked: value});

  const isShown =
    matchingOptionIds.length == 0 || matchingOptionIds.includes(option.value);

  return (
    <>
      {isShown && (
        <div className="select__options-inner">
          <Checkbox
            comment={option.comment}
            label={option.label}
            isChecked={option.isChecked}
            onChange={value => handleChange(value)}
          />
        </div>
      )}
    </>
  );
};

Option.propTypes = {
  matchingOptionIds: PropTypes.array,
  onChange: PropTypes.func,
  option: PropTypes.shape({
    comment: PropTypes.string,
    isChecked: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.any
  })
};

Option.defaultProps = {
  matchingOptionIds: [],
  onChange: () => {},
  option: {}
};

export default Option;
