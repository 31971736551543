import React from 'react';
import {t} from 'ttag';

import Link from 'components/link/link';

import Table, {
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from 'components/table/table';
import * as AppEnv from 'app-env';

interface EntityList {
  check: AppEnv.Check;
}

const EntityList = ({check}: EntityList) => (
  <Table>
    <TableHead>
      <TableCell>{'ID'}</TableCell>
      <TableCell>{t`Entity`}</TableCell>
    </TableHead>

    <TableBody>
      {check.entities.map(item => (
        <TableRow key={item.id}>
          <TableCell>{item.id}</TableCell>

          <TableCell>
            <Link target="_blank" href={item.link}>
              {item.title || t`No name`}
            </Link>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default EntityList;
