import React from 'react';
import PropTypes from 'prop-types';
import {useField} from 'formik';
import {t} from 'ttag';

import * as Title from '../../../../../../../title/title.jsx';
import {SingleSelect} from '../../../../../../../form/form.jsx';
import {useConnectionContext} from '../../../../../connection-context.js';
import {useSettingsContext} from '../../../../settings-context.js';
import {usePipelineSettingsContext} from '../pipeline-settings-context.js';

const StatusId = ({statuses}) => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();
  const {status_id} = usePipelineSettingsContext();

  const [field, , helpers] = useField('pipeline_settings');

  const handleChange = tempStatusId =>
    helpers.setValue(
      field.value.map(item => ({
        ...item,
        status_id: tempStatusId
      }))
    );

  const options = statuses
    ? statuses.map(item => ({
        value: item.id,
        label: item.name
      }))
    : [];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Deal stage`}</Title.H3>

      <SingleSelect
        options={options}
        value={status_id}
        placeholder={t`Deal stage`}
        isDisabled={connection.is_limited || !options.length}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
        onChange={handleChange}
      />
    </div>
  );
};

StatusId.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  )
};

StatusId.defaultProps = {
  statuses: []
};

export default StatusId;
