import React, {useState} from 'react';
import {t} from 'ttag';

import Wrapper from '../../../wrapper/wrapper.jsx';
import Button from '../../../button/button';
import Icons from '../../../icons/icons.jsx';
import Payment from '../../payment/payment.jsx';
import {useConnectionListContext} from '../../connection-list-context.js';

const Selection = () => {
  const {
    visibleConnectionList,
    selectedConnectionIdList,
    isSelection,
    setSelectedConnectionIdList,
    setIsSelection
  } = useConnectionListContext();

  const [isPaymentOpen, setIsPaymentOpen] = useState();

  const handleClick = () => {
    if (isSelection) {
      setIsSelection(false);
      setSelectedConnectionIdList([]);
      return;
    }

    setIsSelection(true);
  };

  const handleSelect = () =>
    setSelectedConnectionIdList(prevValue =>
      prevValue?.length == visibleConnectionList?.length
        ? []
        : visibleConnectionList?.map(item => item.id)
    );

  const versions = new Set(visibleConnectionList.map(item => item.version));
  const isDisabled = versions.length > 1 && versions.includes('avito');

  return (
    <>
      <Wrapper>
        <Button color="white" onClick={handleClick}>
          <Icons.Cursor />
          {isSelection ? t`Cancel` : t`Select a few and pay`}
        </Button>
      </Wrapper>

      {isSelection && (
        <Wrapper alignItems="center">
          <Button color="white" isDisabled={isDisabled} onClick={handleSelect}>
            {t`Select all`}
          </Button>

          <Button
            color="white"
            isDisabled={!selectedConnectionIdList.length}
            onClick={() => setIsPaymentOpen(true)}
          >
            {t`Pay`}
          </Button>
        </Wrapper>
      )}

      {isPaymentOpen && (
        <Payment
          connectionIdList={selectedConnectionIdList}
          onClose={() => setIsPaymentOpen(false)}
        />
      )}
    </>
  );
};

export default Selection;
