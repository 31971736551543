import React, {useEffect, useState} from 'react';
import {t} from 'ttag';

import * as Title from 'components/title/title';
import Advice from 'components/advice/advice';
import Link from 'components/link/link';
import Text from 'components/text/text';
import {useAppContext} from 'app-context';
import {getFromLocalStorage, putInLocalStorage} from 'common/actions';

const Trial = () => {
  const {whatsAppSupport} = useAppContext();
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const isTrialAdviceHidden = getFromLocalStorage('isTrialAdviceHidden');
    setIsHidden(isTrialAdviceHidden == 'true');
  }, []);

  const handleClose = () => {
    setIsHidden(true);
    putInLocalStorage('isTrialAdviceHidden', 'true');
  };

  return (
    <>
      {!isHidden && (
        <Advice isWhite onClose={handleClose} style={{width: '100%'}}>
          <Title.H3 style={{marginBottom: 8}}>
            {t`Welcome to our service!`}
          </Title.H3>

          <Text>
            {t`If you need help or have any questions, please feel free to contact us. We are always ready to help you solve problems or expand functionality.`}{' '}
            {t`Text us on `}
            <Link href={whatsAppSupport} target="_blank">
              {'WhatsApp'}
            </Link>{' '}
            {t`or`}
            {' '}
            <Link href="https://t.me/whatcrmbot" target="_blank">
              {'Telegram'}
            </Link>
            {t` and we will definitely respond.`}
          </Text>
        </Advice>
      )}
    </>
  );
};

export default Trial;
