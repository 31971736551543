import React from 'react';
import {t} from 'ttag';
import moment from 'moment';
import PropTypes from 'prop-types';

import {TableCell, TableRow} from 'components/table/table.jsx';
import LocaleString from 'components/locale-string/locale-string.jsx';
import Tooltip from 'components/tooltip/tooltip.jsx';
import {useAppContext} from 'app-context.js';
import {useGetTariffName} from 'common/hooks';
import * as AppEnv from 'app-env';

interface PaymentRecord {
  isRefund: boolean;
  paymentRecord: AppEnv.Payment;
}

const PaymentRecord = ({isRefund, paymentRecord}: PaymentRecord) => {
  const {user} = useAppContext();

  const getTariffName = useGetTariffName();

  const date = moment.unix(paymentRecord.date_yslug).utcOffset('+0300');
  const currency = paymentRecord.currency || 'RUB';

  return (
    <TableRow>
      <TableCell>
        {date.format('L')}, {date.format('LT')}
      </TableCell>

      <TableCell>
        <LocaleString
          type="currency"
          amount={paymentRecord.summa}
          currency={currency}
        />
      </TableCell>

      <TableCell>
        {getTariffName({
          quantity: paymentRecord.tariff_quantity,
          period: paymentRecord.tariff_period
        })}
      </TableCell>

      <TableCell>{paymentRecord.tariff_plane || 'Start'}</TableCell>
      {!!user.is_partner && <TableCell>{paymentRecord.domain}</TableCell>}

      {isRefund && (
        <TableCell isIcon>
          {paymentRecord.is_deleted == 1 && (
            <Tooltip>{t`Refund is issued`}</Tooltip>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

PaymentRecord.propTypes = {
  paymentRecord: PropTypes.shape({
    currency: PropTypes.oneOf(['RUB', 'rub', 'USD', 'usd']),
    date_yslug: PropTypes.number,
    domain: PropTypes.string,
    is_deleted: PropTypes.oneOf([0, 1]),
    partner_id: PropTypes.number,
    quantity: PropTypes.number,
    summa: PropTypes.string,
    tariff_packet: PropTypes.oneOf([0, 1, 2, 3]),
    tariff_period: PropTypes.oneOf(['day', 'month']),
    tariff_quantity: PropTypes.oneOf([30, 3, 6, 12]),
    tariff_plane: PropTypes.oneOf(['Enterprise', 'Pro', 'Start'])
  }),
  isRefund: PropTypes.bool
};

PaymentRecord.defaultProps = {
  paymentRecord: {},
  isRefund: false
};

export default PaymentRecord;
