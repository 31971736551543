import {useState, useEffect} from 'react';

import {useRequest} from 'common/hooks';
import {getSubscriptionListReq} from 'common/api/api';
import * as AppEnv from 'app-env';

const useSubscriptionList = (id: number | undefined) => {
  const [subscriptionList, setSubscriptionList] =
    useState<AppEnv.Subscription[]>();

  const {request} = useRequest();

  useEffect(() => {
    if (id) getSubscriptionList();
  }, [id]);

  const getSubscriptionList = async () => {
    const res: AppEnv.Subscription[] | object = await request(
      getSubscriptionListReq(id)
    );

    setSubscriptionList(Object.values(res));
  };

  return subscriptionList;
};

export default useSubscriptionList;
