import React, {useId} from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../../../wrapper/wrapper.jsx';
import Text from '../../../text/text.jsx';

const RadioItem = ({radioItem, name, onChange}) => {
  const id = useId('radio-');

  return (
    <label
      className="radio"
      htmlFor={id}
    >
      <Wrapper alignItems="center">
        <input
          id={id}
          type="radio"
          name={name}
          value={radioItem.value}
          checked={radioItem.isChecked}
          onChange={e => onChange(e.target.value)}
        />

        <div className="radio__button" />

        {radioItem.label && (
          <Text
            color="dark"
            isSpan
          >
            {radioItem.label}
          </Text>
        )}
      </Wrapper>
    </label>
  );
};

RadioItem.propTypes = {
  radioItem: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    isChecked: PropTypes.bool
  }),
  name: PropTypes.string,
  onChange: PropTypes.func
};

RadioItem.defaultProps = {
  radioItem: {},
  name: '',
  onChange: () => {}
};

export default RadioItem;
