import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {t} from 'ttag';
import {Input} from '../../../form.jsx';

const Dropdown = ({
  children,
  fieldRef,
  isMulti,
  isFullWidth,
  isSearchAvailable,
  onSearch
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = value => {
    setSearchQuery(value);
    onSearch(value);
  };

  let {width} = fieldRef.current.getBoundingClientRect();
  width += 16;

  return (
    <div
      className="select__container"
      style={{width}}
    >
      <div
        className={cn('select__dropdown', {
          'select__dropdown_full-width': isFullWidth,
          select__dropdown_multi: isMulti,
          'select__dropdown_with-search': isSearchAvailable
        })}
      >
        {isSearchAvailable && (
          <div className="select__dropdown-search">
            <Input
              value={searchQuery}
              placeholder={t`Search`}
              isSearch
              onChange={handleChange}
            />
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  fieldRef: PropTypes.exact({
    current: PropTypes.object
  }),
  isMulti: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isSearchAvailable: PropTypes.bool,
  onSearch: PropTypes.func
};

Dropdown.defaultProps = {
  children: <></>,
  fieldRef: {},
  isMulti: false,
  isFullWidth: false,
  isSearchAvailable: false,
  onSearch: () => {}
};

export default Dropdown;
