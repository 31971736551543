import React from 'react';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import update from 'immutability-helper';
import {c, t} from 'ttag';

import Popup from '../../../../popup/popup';
import * as Title from '../../../../title/title';
import Line from '../../../../line/line';
import Wrapper from '../../../../wrapper/wrapper';
import Button, {SaveButton} from '../../../../button/button';
import {useAppContext} from '../../../../../app-context';
import {useConnectionListContext} from '../../../connection-list-context';
import {useConnectionContext} from '../../connection-context';
import {useRequest} from '../../../../../common/hooks';
import {updateConnectionReq} from '../../../../../common/api/api';

import Label from './label/label';

const Editor = ({setIsEditorActive}) => {
  const {setNotificationList} = useAppContext();
  const {connectionList, setConnectionList} = useConnectionListContext();
  const {connection} = useConnectionContext();

  const {request} = useRequest();

  const handleSubmit = async values => {
    const label = values.label.trim();

    const res = await request(
      updateConnectionReq({chat_key: connection.chat_key, data: {label}})
    );

    if ('label' in res) {
      const index = connectionList.findIndex(item => item.id === connection.id);

      setConnectionList(prevValue =>
        update(prevValue, {
          [index]: {
            label: {$set: res.label}
          }
        })
      );

      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: res.label || `${t`Connection`} ${connection.id}`,
              text: t`The connection has been renamed.`
            }
          ]
        })
      );

      setIsEditorActive(false);
    }

    return true;
  };

  return (
    <>
      <Popup onClick={() => setIsEditorActive(false)}>
        <Title.H2 style={{marginBottom: 16}}>{c('connection')
          .t`Name`}</Title.H2>

        <Formik
          initialValues={{label: connection.label}}
          onSubmit={handleSubmit}
        >
          <Form>
            <Label />
            <Line />

            <Wrapper>
              <SaveButton>{t`Save`}</SaveButton>

              <Button
                color="white"
                onClick={() => setIsEditorActive(false)}
              >
                {t`Cancel`}
              </Button>
            </Wrapper>
          </Form>
        </Formik>
      </Popup>
    </>
  );
};

Editor.propTypes = {
  setIsEditorActive: PropTypes.func
};

Editor.defaultProps = {
  setIsEditorActive: () => {}
};

export default Editor;
