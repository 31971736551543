import React from 'react';
import PropTypes from 'prop-types';
import Popup from '../../../../../../../components/popup/popup.jsx';
import Image from '../../../../../../../components/image/image.jsx';
import Video from '../../../../../../../components/video/video.jsx';
import Preloader from '../../../../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../../../../components/empty-box/empty-box.jsx';

const Preview = ({preview, onClose}) => {
  const style = {borderRadius: 2};

  return (
    <Popup
      width={790}
      onClick={onClose}
    >
      {preview ? (
        preview.type?.includes('image') ? (
          <Image
            src={preview.link}
            style={style}
          />
        ) : preview.type?.includes('video') ? (
          <Video
            src={preview.link}
            style={style}
          />
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </Popup>
  );
};

Preview.propTypes = {
  preview: PropTypes.shape({
    file: PropTypes.string,
    id: PropTypes.string,
    link: PropTypes.string,
    template_id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string
  }),
  onClose: PropTypes.func
};

Preview.defialtProps = {
  preview: {},
  onClose: () => {}
};

export default Preview;
