import React from 'react';
// import {Mark} from 'whatcrm-core';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  TableRow,
  TableCell
} from '../../../../../../components/table/table.jsx';
import Icons from '../../../../../../components/icons/icons.jsx';

const User = ({user}) => (
  <TableRow>
    <TableCell>{moment.unix(user.date_add).format('L')}</TableCell>
    <TableCell>{user.domain}</TableCell>

    <TableCell>
      {/* <Mark mark={user.is_deleted} /> */}
      {user.is_deleted ? <Icons.Check /> : <Icons.Times color="light" />}
    </TableCell>
  </TableRow>
);

User.propTypes = {
  user: PropTypes.shape({
    date_add: PropTypes.number,
    domain: PropTypes.string,
    id: PropTypes.number,
    instances_count: PropTypes.number,
    is_deleted: PropTypes.oneOf([0, 1]),
    managers_count: PropTypes.number
  })
};

export default User;
