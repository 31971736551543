import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';
import {isolateNumbers} from '../../../../../../../../common/actions.js';

const CPP = () => {
  const [field, meta, helpers] = useField('cpp');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      inputMode="tel"
      placeholder="КПП"
      isMin
      isValid={isValid}
      maxLength={9}
      onChange={value => setValue(isolateNumbers(value))}
    />
  );
};

export default CPP;
