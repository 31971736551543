import React from 'react';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import TelphinCode from './telphin-code/telphin-code.jsx';
import Line from '../../../../../../line/line.jsx';
import {SaveButton} from '../../../../../../button/button';
import {updateUserReq} from '../../../../../../../common/api/api.js';
import {useAppContext} from '../../../../../../../app-context.js';
import {useRequest} from '../../../../../../../common/hooks';
import {trimValues} from '../../../../../../../common/actions.js';

const Recovery = () => {
  const {user, setUser} = useAppContext();
  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);
    const res = await request(updateUserReq(user.id, trimmedValues));

    if (res.telphin_code) {
      setUser(prevValue =>
        update(prevValue, {
          telphin_code: {$set: res.telphin_code}
        })
      );
    }
  };

  const initialValues = {telphin_code: ''};

  const validationSchema = yup.object().shape({
    telphin_code: yup.string().trim().required().length(8)
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <TelphinCode />
          <Line />
          <SaveButton>{t`Save`}</SaveButton>
        </Form>
      </Formik>
    </>
  );
};

export default Recovery;
