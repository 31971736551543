import React, {useEffect} from 'react';
import {useField} from 'formik';
import {useLocation} from 'react-router-dom';
import {t} from 'ttag';

import {Input} from '../../../../components/form/form.jsx';
import Link from 'components/link/link.jsx';
import Text from 'components/text/text.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';
import {useAppContext} from 'app-context.js';

const Email = () => {
  const {lang} = useAppContext();

  const [field, meta, helpers] = useField('email');
  const location = useLocation();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const email = urlSearchParams.get('email');

    if (email) helpers.setValue(email);
  }, []);

  const isSpaceShown = ['en', 'es', 'pt', 'tr'].includes(lang);
  const isValid = !(meta.error && meta.touched);

  return (
    <Wrapper gap={4} isColumn>
      <Input
        {...field}
        type="email"
        placeholder={t`Email`}
        isValid={isValid}
        onChange={value => helpers.setValue(value.toLowerCase())}
      />

      {meta.error == 'ru' && (
        <div>
          <Text color="orange" isSmall>
            {t`Only Russian email can be registered on app.whatcrm.ru.`}
          </Text>

          <Text color="dark" isSmall>
            {t`Go to`}
            {' '}
            <Link href={`https://app.whatcrm.net?email=${field.value}`}>
              {'app.whatcrm.net'}
            </Link>
            {isSpaceShown && ' '}
            {t`to use this email`}
          </Text>
        </div>
      )}
    </Wrapper>
  );
};

export default Email;
