import React from 'react';
import {t} from 'ttag';
import PropTypes from 'prop-types';

import * as Title from '../title/title.jsx';
import Icons from '../icons/icons.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';

const Preloader = ({isMin, style}) => (
  <div className="placeholder" style={style}>
    <Wrapper alignItems="center" isColumn>
      <Icons.Spinner className="icon_spinner" />
      {!isMin && <Title.H3>{t`Loading...`}</Title.H3>}
    </Wrapper>
  </div>
);

Preloader.propTypes = {
  isMin: PropTypes.bool,
  style: PropTypes.object
};

export default Preloader;
