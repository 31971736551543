import {useState, useEffect} from 'react';

import {useRequest} from 'common/hooks';
import {getPaymentHistoryReq} from 'common/api/api';
import * as AppEnv from 'app-env';

const usePaymentHistory = (id: number | undefined) => {
  const [paymentHistory, setPaymentHistory] = useState<AppEnv.Payment[]>();

  const {request} = useRequest();

  useEffect(() => {
    if (id) getPaymentHistory();
  }, [id]);

  const getPaymentHistory = async () => {
    const res: AppEnv.Payment[] | object = await request(
      getPaymentHistoryReq(id)
    );

    setPaymentHistory(Object.values(res));
  };

  return paymentHistory;
};

export default usePaymentHistory;
