import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../../../text/text.jsx';

const Entry = ({label, value}) => (
  <>
    {value && (
      <div>
        <Text
          isSmall
          style={{marginBottom: 4}}
        >
          {label}
        </Text>

        <Text color="dark">{value}</Text>
      </div>
    )}
  </>
);

Entry.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

Entry.defaultProps = {
  label: '',
  value: ''
};

export default Entry;
