import React, {useState, useEffect, useRef} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';

import Button from '../../../components/button/button';
import Icons from '../../../components/icons/icons.jsx';
import Confirmation from '../../../components/confirmation/confirmation.jsx';
import {useAppContext} from '../../../app-context.js';
import {useOnClickOutside, useOnKeyDown} from '../../../common/hooks';

import Options from './options/options.jsx';
import Settings from './settings/settings.jsx';
import MenuContext from './menu-context.js';

const Menu = () => {
  const {user, isTelphinClientFormOpen, setUser} = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const ref = useRef();

  useOnClickOutside({ref, onClick: () => setIsOpen(false)});
  useOnKeyDown({onEscape: () => setIsOpen(false)});

  useEffect(() => {
    if (isTelphinClientFormOpen) {
      setIsSettingsOpen(true);
    }
  }, [isTelphinClientFormOpen]);

  const signOut = () => {
    try {
      localStorage.removeItem('domain');
    } catch {
      //
    }

    setUser(
      update(user, {
        domain: {$set: ''}
      })
    );

    return true;
  };

  return (
    <MenuContext.Provider
      value={{
        setIsOpen,
        setIsSettingsOpen,
        setIsConfirmationOpen
      }}
    >
      <div ref={ref} className="position-relative">
        <Button color="transparent" onClick={() => setIsOpen(!isOpen)}>
          <Icons.Profile />
        </Button>

        {isOpen && <Options />}
        {isSettingsOpen && <Settings />}
      </div>

      {isConfirmationOpen && (
        <Confirmation
          title={t`Are you sure you want to sign out?`}
          onClick={res => (res ? signOut() : setIsConfirmationOpen(false))}
        />
      )}
    </MenuContext.Provider>
  );
};

export default Menu;
