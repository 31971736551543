import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {t} from 'ttag';

import Advice from 'components/advice/advice';
import * as Title from 'components/title/title';
import Text from 'components/text/text';
import * as AppEnv from 'app-env';

interface Warning {
  connection: Partial<AppEnv.Connection> | undefined;
}

const Warning = ({connection}: Warning) => {
  const label = connection?.label || connection?.id;

  const subscriptionDate =
    (connection?.date_subscription || 0) * 1000 > Date.now()
      ? moment.unix(connection?.date_subscription || 0).format('L')
      : null;

  return (
    <>
      {subscriptionDate && (
        <Advice type="warning" style={{width: '100%'}}>
          <Title.H3 style={{marginBottom: 8}}>
            {t`Connection ${label} is paid until ${subscriptionDate}`}
          </Title.H3>

          <Text color="dark">
            {t`If you pay again, the subscription period will start now and will not be added to the previous one.`}
          </Text>
        </Advice>
      )}
    </>
  );
};

Warning.propTypes = {
  connection: PropTypes.object
};

Warning.defaultProps = {
  connection: {}
};

export default Warning;
