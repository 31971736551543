import React from 'react';
import update from 'immutability-helper';
import cn from 'classnames';
import {Checkbox} from '../../../form/form.jsx';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';

const Aside = () => {
  const {
    connectionList,
    isSelection,
    selectedConnectionIdList,
    setSelectedConnectionIdList
  } = useConnectionListContext();

  const {connection} = useConnectionContext();

  const getIsDisabled = () => {
    const firstSelectedConnection = connectionList.find(
      item => item.id == selectedConnectionIdList[0]
    );

    if (!firstSelectedConnection) return false;
    else if (
      (connection.version == 'avito' &&
        firstSelectedConnection.version != 'avito') ||
      (connection.version != 'avito' &&
        firstSelectedConnection.version == 'avito')
    )
      return true;

    return false;
  };

  const handleChange = value => {
    if (value) {
      setSelectedConnectionIdList(prevValue =>
        update(prevValue, {
          $push: [connection.id]
        })
      );

      return;
    }

    const index = selectedConnectionIdList.indexOf(connection.id);

    setSelectedConnectionIdList(prevValue =>
      update(prevValue, {
        $splice: [[index, 1]]
      })
    );
  };

  const isChecked = selectedConnectionIdList.includes(connection.id);
  const isDisabled = getIsDisabled();

  return (
    <div className="connection__aside">
      <div
        className={cn('connection__aside-slider', {
          'connection__aside-slider_shown': isSelection
        })}
      >
        <Checkbox
          isChecked={isChecked}
          isDisabled={isDisabled}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Aside;
