import {useEffect, useRef, useState} from 'react';
import QRCode from 'qrcode';
import {t} from 'ttag';
import {w3cwebsocket} from 'websocket';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';

const useWebSocket = (connectionList, setConnectionList, onConnect) => {
  const {setNotificationList} = useAppContext();

  const [channels, setChannels] = useState([]);
  const [counter, setCounter] = useState(0);
  const [messageQueue, setMessageQueue] = useState([]);

  const client = useRef();

  const updateConnectionStatus = (connection, status) => {
    const index = connectionList?.findIndex(
      item => item.chat_key == connection.chat_key
    );

    if (index == undefined || index < 0) return;

    setConnectionList(prevValue =>
      update(prevValue, {[index]: {st: {$set: status}}})
    );
  };

  const handleConnect = (connection, info) => {
    const index = connectionList?.findIndex(
      item => item.chat_key == connection.chat_key
    );

    if (index == undefined || index < 0) return;

    const name =
      connection.version == 'avito'
        ? info.name
        : connection.version == 'telegram' || connection.version == 'whatcrm'
          ? info.pushname
          : '';

    const phone =
      connection.version == 'avito'
        ? info.phone
        : connection.version == 'telegram'
          ? info.phoneNumber
          : connection.version == 'whatcrm'
            ? info.me.user
            : '';

    setConnectionList(prevValue =>
      update(prevValue, {
        [index]: {
          name: {$set: name},
          phone: {$set: phone},
          st: {
            state: {
              $set:
                connection.version === 'telegram'
                  ? 'authorizationStateReady'
                  : 'CONNECTED'
            }
          }
        }
      })
    );

    onConnect(connection);
  };

  const handleMessage = message => {
    try {
      const messageData = JSON.parse(message.data);

      const connection = connectionList?.find(
        item => item.chat_key == messageData.chat_key
      );

      if (connection && messageData.events) {
        const event = messageData.events[0];

        if (
          event.name == 'CHAT_FREE' ||
          event.name == 'NAVIGATION' ||
          event.name == 'PENDING' ||
          event.name == 'REBOOT'
        ) {
          updateConnectionStatus(connection, {state: 'PENDING'});
        } else if (event.name == 'CONNECTED' || event.name == 'READY') {
          handleConnect(connection, event.info);
        } else if (event.name == 'LOADING_SCREEN') {
          updateConnectionStatus(connection, {
            state: 'LOADING_SCREEN',
            percent: event.percent
          });
        } else if (event.name == 'QR_CODE') {
          QRCode.toDataURL(event.code).then(qrCode =>
            updateConnectionStatus(connection, {state: 'got qr code', qrCode})
          );
        } else if (event.name == 'WAIT_CODE') {
          updateConnectionStatus(connection, {
            state: 'authorizationStateWaitCode'
          });
        } else if (event.name == 'WAIT_PASSWORD') {
          updateConnectionStatus(connection, {
            passwordHint: event.authorizationStateWaitPassword.passwordHint,
            state: 'authorizationStateWaitPassword'
          });
        }
      }
    } catch {
      //
    }
  };

  useEffect(() => {
    if (client.current) client.current.onmessage = handleMessage;
  }, [connectionList]);

  const updateChannels = () => {
    const add = [];
    const remove = [];
    const newChannels = [...channels];

    connectionList?.forEach(connection => {
      if (newChannels.includes(connection.chat_key)) return;

      add.push(connection.chat_key);
      newChannels.push(connection.chat_key);
    });

    newChannels.forEach((channel, i) => {
      if (connectionList?.find(connection => connection.chat_key == channel))
        return;

      remove.push(channel);
      newChannels.splice(i, 1);
    });

    setChannels(newChannels);

    if (client.current?.readyState) {
      if (add.length)
        client.current?.send(JSON.stringify({chat_key: add, type: 'add'}));

      if (remove.length)
        client.current?.send(
          JSON.stringify({chat_key: remove, type: 'remove'})
        );
    } else {
      if (add.length)
        setMessageQueue(prevValue =>
          update(prevValue, {
            $push: [JSON.stringify({chat_key: add, type: 'add'})]
          })
        );

      if (remove.length)
        setMessageQueue(prevValue =>
          update(prevValue, {
            $push: [JSON.stringify({chat_key: remove, type: 'remove'})]
          })
        );
    }
  };

  useEffect(() => {
    updateChannels();
  }, [connectionList?.length]);

  const handleClose = () => {
    if (counter >= 5)
      return setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: t`Error`,
              text: t`For further work with connections please reload the page.`
            }
          ]
        })
      );

    setCounter(prevValue => prevValue + 1);
    setTimeout(() => connectWebSocket(), 2000);
  };

  const handleOpen = () => {
    if (messageQueue)
      messageQueue.map(message => client.current?.send(message));

    updateChannels();
  };

  useEffect(() => {
    if (client.current) client.current.onopen = handleOpen;
  }, [messageQueue]);

  const connectWebSocket = () => {
    try {
      client.current = new w3cwebsocket('wss://events.whatcrm.net');
      client.current.binaryType = 'arraybuffer';
      client.current.onopen = handleOpen;
      client.current.onclose = handleClose;
    } catch {
      //
    }
  };

  useEffect(() => {
    connectWebSocket();
  }, []);
};

export default useWebSocket;

// import {useEffect, useRef} from 'react';
// import {w3cwebsocket} from 'websocket';
// import QRCode from 'qrcode';
// import update from 'immutability-helper';

// const useWebSocket = (connectionList, setConnectionList, onConnect) => {
//   const client = useRef();

//   const updateConnectionStatus = (connection, status) => {
//     const index = connectionList?.findIndex(
//       item => item.chat_key == connection.chat_key
//     );

//     if (index == undefined || index < 0) return;

//     setConnectionList(prevValue =>
//       update(prevValue, {[index]: {st: {$set: status}}})
//     );
//   };

//   const handleConnect = (connection, info) => {
//     const index = connectionList?.findIndex(
//       item => item.chat_key == connection.chat_key
//     );

//     if (index == undefined || index < 0) return;

//     const name =
//       connection.version == 'avito'
//         ? info.name
//         : connection.version == 'telegram' || connection.version == 'whatcrm'
//           ? info.pushname
//           : '';

//     const phone =
//       connection.version == 'avito'
//         ? info.phone
//         : connection.version == 'telegram'
//           ? info.phoneNumber
//           : connection.version == 'whatcrm'
//             ? info.me.user
//             : '';

//     setConnectionList(prevValue =>
//       update(prevValue, {
//         [index]: {
//           name: {$set: name},
//           phone: {$set: phone},
//           st: {
//             state: {
//               $set:
//                 connection.version === 'telegram'
//                   ? 'authorizationStateReady'
//                   : 'CONNECTED'
//             }
//           }
//         }
//       })
//     );

//     onConnect(connection);
//   };

//   const handleMessage = message => {
//     try {
//       const messageData = JSON.parse(message.data);

//       const connection = connectionList?.find(
//         item => item.chat_key == messageData.chat_key
//       );

//       if (connection && messageData.events) {
//         const event = messageData.events[0];

//         if (
//           event.name == 'CHAT_FREE' ||
//           event.name == 'NAVIGATION' ||
//           event.name == 'PENDING' ||
//           event.name == 'REBOOT'
//         ) {
//           updateConnectionStatus(connection, {state: 'PENDING'});
//         } else if (event.name == 'CONNECTED' || event.name == 'READY') {
//           handleConnect(connection, event.info);
//         } else if (event.name == 'LOADING_SCREEN') {
//           updateConnectionStatus(connection, {
//             state: 'LOADING_SCREEN',
//             percent: event.percent
//           });
//         } else if (event.name == 'QR_CODE') {
//           QRCode.toDataURL(event.code).then(qrCode =>
//             updateConnectionStatus(connection, {state: 'got qr code', qrCode})
//           );
//         } else if (event.name == 'WAIT_CODE') {
//           updateConnectionStatus(connection, {
//             state: 'authorizationStateWaitCode'
//           });
//         } else if (event.name == 'WAIT_PASSWORD') {
//           updateConnectionStatus(connection, {
//             passwordHint: event.authorizationStateWaitPassword.passwordHint,
//             state: 'authorizationStateWaitPassword'
//           });
//         }
//       }
//     } catch {
//       //
//     }
//   };

//   const connectWebSocket = () => {
//     try {
//       client.current = new w3cwebsocket('wss://ws.whatcrm.net/socket');
//       client.current.binaryType = 'arraybuffer';
//       client.current.onopen = () => {};
//       client.current.onclose = () => setTimeout(() => connectWebSocket(), 1000);
//     } catch {
//       //
//     }
//   };

//   useEffect(() => {
//     if (client.current) client.current.onmessage = handleMessage;
//   }, [connectionList]);

//   useEffect(() => {
//     connectWebSocket();
//   }, []);
// };

// export default useWebSocket;
