const fetchConnection = (domain, chat_key) => ({
  params: {chat_key, domain},
  path: 'connection'
});

const fetchEmployees = domain => ({path: 'api/employees', params: {domain}});
const fetchPipelines = domain => ({path: 'pipelines', params: {domain}});
const fetchShortCode = domain => ({path: 'shortCode', params: {domain}});
const fetchStages = domain => ({path: 'stages', params: {domain}});

const putConnection = (domain, chat_key, data) => ({
  path: 'connection',
  data: {...data, domain, chat_key},
  method: 'put'
});

const putShortCode = (domain, short_code) => ({
  path: 'shortCode',
  data: {domain, short_code},
  method: 'put'
});

export {
  fetchConnection,
  fetchEmployees,
  fetchPipelines,
  fetchShortCode,
  fetchStages,
  putConnection,
  putShortCode
};
