import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Notice from './notice/notice.jsx';

const NoticeList = ({noticeList, isImportant, setNoticeList}) => {
  const visibleNoticeList = noticeList.filter(item =>
    isImportant
      ? item.alert_type === 1 || item.alert_type === 2
      : item.alert_type === 0 || item.alert_type === 3
  );

  return (
    <>
      <div
        className={cn('notice-list', {
          'notice-list_regular': !isImportant,
          'notice-list_important': isImportant
        })}
      >
        {visibleNoticeList.map(item => (
          <Notice
            key={item.id}
            notice={item}
            setNoticeList={setNoticeList}
          />
        ))}
      </div>
    </>
  );
};

NoticeList.propTypes = {
  noticeList: PropTypes.arrayOf(PropTypes.object),
  isImportant: PropTypes.bool,
  setNoticeList: PropTypes.func
};

NoticeList.defaultProps = {
  noticeList: [],
  isImportant: false,
  setNoticeList: () => {}
};

export default NoticeList;
