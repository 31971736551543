import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Text from '../../../../../text/text.jsx';
import {useSingleSelectContext} from '../../single-select-context.js';

const Option = ({option}) => {
  const {value, matchingOptionIds, handleChange} = useSingleSelectContext();

  const isShown =
    matchingOptionIds.length === 0 || matchingOptionIds.includes(option.value);

  return (
    <>
      {isShown && (
        <div
          className={cn('select__options-item one-line', {
            'select__options-item_selected': option.value === value
          })}
          onClick={() => handleChange(option.value)}
        >
          <Text color="dark" isOneLine>
            {option.label}
          </Text>

          {option.comment && (
            <Text isOneLine isSmall>
              {option.comment}
            </Text>
          )}
        </div>
      )}
    </>
  );
};

Option.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.node,
    comment: PropTypes.string
  })
};

export default Option;
