import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import * as Title from '../title/title.jsx';
import Text from '../text/text.jsx';

const ProgressBar = ({percent}) => (
  <div>
    <Title.H3 style={{marginBottom: 16}}>{t`Loading...`}</Title.H3>

    <div
      className="progress-bar"
      style={{marginBottom: 8}}
    >
      <div
        className="progress-bar__thumb"
        style={{width: `${percent}%`}}
      />
    </div>

    <Text>{percent}%</Text>
  </div>
);

ProgressBar.propTypes = {
  percent: PropTypes.number
};

ProgressBar.defaultProps = {
  percent: 0
};

export default ProgressBar;
