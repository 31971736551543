import React, {useState} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import {t} from 'ttag';
import * as yup from 'yup';
import update from 'immutability-helper';

import {SaveButton} from 'components/button/button';
import Section from 'components/section/section';
import Wrapper from 'components/wrapper/wrapper';
import {useAppContext} from 'app-context';
import {useCheckerRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import CheckList from './check-list/check-list';
import MainContext from './main-context';
import PaymentStatus from './payment-status/payment-status';
import Phone from './phone/phone';

interface Values {
  phone: string;
}

interface Main {
  dashboard: Partial<AppEnv.Dashboard>;
  payment: AppEnv.Payment | AppEnv.Subscription | undefined;
}

const Main = ({dashboard, payment}: Main) => {
  const {user} = useAppContext();
  const [checkList, setCheckList] = useState<AppEnv.Check[]>();
  const {postCheck} = useCheckerRequest();

  const handleSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {
    const res = await postCheck(user.domain, values.phone);
    if (!res) return;

    setCheckList(prevValue =>
      prevValue ? update(prevValue, {$unshift: [res]}) : [res]
    );

    helpers.resetForm();
  };

  const isBitrix24 = user.domain.includes('bitrix24');

  const validationSchema = yup.object().shape({
    phone: yup.string().trim().required().min(7)
  });

  return (
    <MainContext.Provider value={{checkList, setCheckList}}>
      <Section title={t`Check the phone number in WhatsApp`} isDefaultOpen>
        <Wrapper gap={32} isColumn>
          {!isBitrix24 && (
            <PaymentStatus dashboard={dashboard} payment={payment} />
          )}

          <Formik
            initialValues={{phone: ''}}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Wrapper alignItems="flex-end">
                <Phone />
                <SaveButton>{t`Check`}</SaveButton>
              </Wrapper>
            </Form>
          </Formik>

          {checkList && <CheckList />}
        </Wrapper>
      </Section>
    </MainContext.Provider>
  );
};

export default Main;
