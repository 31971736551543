import React from 'react';
import PropTypes from 'prop-types';
import {useFormikContext} from 'formik';
import Button from '../button';

const SaveButton = props => {
  const {isDisabled} = props;
  const {isSubmitting} = useFormikContext();

  const htmlIsDisabled = isDisabled || isSubmitting;

  return <Button {...props} type="submit" isDisabled={htmlIsDisabled} />;
};

SaveButton.propTypes = {
  isDisabled: PropTypes.bool
};

SaveButton.defaultProps = {
  isDisabled: false
};

export default SaveButton;
