import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useFormikContext, useField} from 'formik';
import ReactCollapsible from 'react-collapsible';

import City from './city/city.jsx';
import Country from './country/country.jsx';
import EqualAddresses from './equal-addresses/equal-addresses.jsx';
import Flat from './flat/flat.jsx';
import House from './house/house.jsx';
import Index from './index/index.jsx';
import Region from './region/region.jsx';
import Street from './street/street.jsx';

import Grid from '../../../../../../grid/grid.jsx';
import Wrapper from '../../../../../../wrapper/wrapper.jsx';
import * as Title from '../../../../../../title/title.jsx';
import Icons from '../../../../../../icons/icons.jsx';

import AddressContext from './address-context.js';

const Address = ({prefix, placeholder, isCollapsible}) => {
  const [isOpen, setIsOpen] = useState(isCollapsible ? false : true);

  const {errors, touched} = useFormikContext();
  const [equalAddressesField] = useField('equal_addresses');

  const findByPrefix = obj =>
    !!Object.keys(obj).find(item => item.includes(`${prefix}_`));

  const handleClick = () => {
    if (isCollapsible) {
      setIsOpen(prevValue => !prevValue);
    }
  };

  const isAddressErrors = findByPrefix(errors) && findByPrefix(touched);
  const isAddressOpen = isOpen || isAddressErrors;
  const isFieldsOpen = prefix === 'j' || !equalAddressesField.value;

  return (
    <AddressContext.Provider value={{prefix}}>
      <div>
        <Wrapper
          justifyContent="space-between"
          alignItems="center"
          isClickable={isCollapsible}
          onClick={handleClick}
        >
          <Title.H3>{placeholder}</Title.H3>
          {isCollapsible && <Icons.ArrowDown isRotate={isOpen} />}
        </Wrapper>

        <ReactCollapsible
          transitionTime={200}
          open={isAddressOpen}
        >
          <div style={{marginTop: 8}}>
            {prefix === 'f' && <EqualAddresses />}

            <ReactCollapsible
              transitionTime={200}
              open={isFieldsOpen}
            >
              <Grid
                elements={2}
                style={{marginTop: prefix === 'f' && 16}}
              >
                <Wrapper
                  gap={16}
                  isColumn
                >
                  <Wrapper noWrap>
                    <Index />
                    <Country />
                  </Wrapper>

                  <Region />
                  <City />
                </Wrapper>

                <Wrapper
                  gap={16}
                  isColumn
                >
                  <Street />

                  <Wrapper noWrap>
                    <House />
                    <Flat />
                  </Wrapper>
                </Wrapper>
              </Grid>
            </ReactCollapsible>
          </div>
        </ReactCollapsible>
      </div>
    </AddressContext.Provider>
  );
};

Address.propTypes = {
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  isCollapsible: PropTypes.bool
};

Address.defaultProps = {
  prefix: '',
  placeholder: 'Адрес',
  isCollapsible: false
};

export default Address;
