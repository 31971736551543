import React from 'react';
import {t} from 'ttag';
import * as Title from 'components/title/title.jsx';
import LocaleString from 'components/locale-string/locale-string.jsx';
import {useAppContext} from 'components/../app-context.js';
import {usePaymentContext} from '../../../../payment-context';
import {useTariffPlanContext} from '../../../tariff-plan-context';
import {inflectNumberal} from 'common/actions.js';
import * as AppEnv from 'app-env';

const Total = () => {
  const {user, lang} = useAppContext();
  const {selectedTariffId, connectionIdList, client} = usePaymentContext();
  const {tariffOptions, isSelected} = useTariffPlanContext();

  const isDiscount = user.is_review === 2 && !client.isCustomer;

  const selectedTariff = (tariffOptions.find(
    item => item.id === selectedTariffId
  ) || {}) as AppEnv.TariffOption | undefined;

  const summa = selectedTariff
    ? isDiscount
      ? parseFloat(selectedTariff.summa_review)
      : parseFloat(selectedTariff.summa)
    : 0;

  const amount = summa * (connectionIdList?.length || 1);

  return (
    <Title.H2
      className={isSelected ? '' : 'title_hidden'}
      style={{marginBottom: 16}}
    >
      {t`Total`}
      {': '}
      <LocaleString
        amount={amount}
        type="currency"
        currency={selectedTariff?.currency}
      />{' '}
      {t`for`}
      {' '}
      {connectionIdList?.length}
      {' '}
      {lang === 'ru'
        ? inflectNumberal({
            value: connectionIdList?.length,
            labels: ['подключение', 'подключения', 'подключений']
          })
        : lang === 'uk'
          ? inflectNumberal({
              value: connectionIdList?.length,
              labels: ['підключення', 'підключення', 'підключень']
            })
          : t`connections`}
    </Title.H2>
  );
};

export default Total;
