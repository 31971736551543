import React from 'react';
import {Formik, Form} from 'formik';
import {t} from 'ttag';

import {SaveButton} from 'components/button/button';
import * as Title from 'components/title/title';
import Article from 'components/article/article';
import Line from 'components/line/line';
import Preloader from 'components/preloader/preloader';
import Text from 'components/text/text';

import PhoneCode from './phone-code/phone-code';

export interface PhoneCodeEditorValues {
  phoneCode: string;
}

interface PhoneCodeEditor {
  onSubmit: (arg: PhoneCodeEditorValues) => Promise<boolean>;
  phoneCode: string | undefined;
}

const PhoneCodeEditor = ({onSubmit, phoneCode}: PhoneCodeEditor) => {
  const initialValues: PhoneCodeEditorValues = {phoneCode: phoneCode || ''};

  return (
    <div>
      <Article style={{marginBottom: 8}}>
        <Title.H3 style={{marginBottom: 4}}>{t`Phone code`}</Title.H3>

        <Text>
          {t`If it is not listed in the phone number, it will be substituted when you send a message.`}
        </Text>
      </Article>

      {typeof phoneCode == 'string' ? (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form>
            <PhoneCode />
            <Line />
            <SaveButton>{t`Save`}</SaveButton>
          </Form>
        </Formik>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default PhoneCodeEditor;
