import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {Input} from 'components/form/form';
import * as Title from 'components/title/title.jsx';
import {normalizeUrl} from 'common/actions';

const Webhook = () => {
  const [field, , helpers] = useField<string>('webhook');

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Webhook address`}</Title.H3>

      <Input
        {...field}
        placeholder="https://"
        style={{marginBottom: 16}}
        onChange={value => helpers.setValue(normalizeUrl(value))}
      />
    </>
  );
};

export default Webhook;
