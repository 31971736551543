import React from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import {Dropzone} from '../../../../../components/form/form.jsx';
import {useAppContext} from 'app-context.js';
import {useEditorContext} from '../editor-context.js';

const image = 16777216;
const other = 104857600;
const video = 67108864;

const AttachMenu = () => {
  const {setNotificationList} = useAppContext();
  const {setAttachmentList} = useEditorContext();

  const pushNotification = (title, size) =>
    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [
          {
            title,
            text: `${t`The file size is too large.`} ${t`The maximum size for this type is ${size} MB.`}`
          }
        ]
      })
    );

  const handleChange = async fileList => {
    fileList.forEach(file => {
      if (file.size > other) return pushNotification(file.name, 100);
      else if (file.type.includes('image') && file.size > image)
        return pushNotification(file.name, 16);
      else if (file.type.includes('video') && file.size > video)
        return pushNotification(file.name, 64);

      setAttachmentList(prevValue =>
        update(prevValue || [], {
          $push: [{file: file, title: file.name, type: file.type}]
        })
      );
    });
  };

  return (
    <>
      <Dropzone
        style={{marginBottom: 16}}
        isMax
        multiple
        onChange={handleChange}
      />
    </>
  );
};

export default AttachMenu;
