import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Closer from '../closer/closer.jsx';
import {useOnKeyDown} from '../../common/hooks';

const Popup = ({children, width, isClosable, withBackground, onClick}) => {
  const ref = useRef();

  useEffect(() => {
    if (withBackground) {
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = '');
    }
  }, []);

  useOnKeyDown({
    onEscape: () => {
      if (!ref.current.querySelector('.popup')) {
        onClick();
      }
    }
  });

  const style = width >= 790 ? {top: -24, right: -24} : {top: -16, right: -16};

  return (
    <div
      ref={ref}
      className={cn('popup', {
        popup_bg: withBackground
      })}
    >
      <div
        className={cn('popup__wrapper', {
          [`popup__wrapper_width-${width}`]: width
        })}
      >
        {isClosable && (
          <div className="position-relative">
            <Closer color="light-blue" style={style} onClick={onClick} />
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOf([600, 644, 790, 1080]),
  isClosable: PropTypes.bool,
  withBackground: PropTypes.bool,
  onClick: PropTypes.func
};

Popup.defaultProps = {
  children: <></>,
  width: undefined,
  isClosable: true,
  withBackground: true,
  onClick: () => {}
};

export default Popup;
