import React, {useState} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {t} from 'ttag';
import Attachments from './attachments/attachments.jsx';
import Editor from '../editor/editor.jsx';
import Confirmation from '../../../../components/confirmation/confirmation.jsx';
import Wrapper from '../../../../components/wrapper/wrapper.jsx';
import Line from '../../../../components/line/line.jsx';
import * as Title from '../../../../components/title/title.jsx';
import Text from '../../../../components/text/text.jsx';
import Button from '../../../../components/button/button';
import Link from '../../../../components/link/link.jsx';
import Icons from '../../../../components/icons/icons.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useHomeContext} from '../../home-context.js';
import {useRequest} from '../../../../common/hooks';
import {
  updateMessageTemplateReq,
  deleteMessageTemplateReq
} from '../../../../common/api/api.js';
import {useProcessAttachmentList} from '../hooks.js';

const MessageTemplate = ({messageTemplate}) => {
  const {user} = useAppContext();
  const {messageTemplateList, setMessageTemplateList} = useHomeContext();

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {request} = useRequest();
  const processAttachmentList = useProcessAttachmentList();

  const handleSubmit = async ({values, attachmentList}) => {
    const res = await request(
      updateMessageTemplateReq(user.id, messageTemplate.id, values)
    );

    if (Object.keys(res).length) {
      const file_count = attachmentList.filter(
        item => !item.isRemovable
      ).length;

      const index = messageTemplateList.findIndex(
        item => item.id === messageTemplate.id
      );

      setMessageTemplateList(prevValue =>
        update(prevValue, {
          [index]: {$set: {...res, file_count}}
        })
      );

      processAttachmentList({
        messageTemplateId: res.id,
        attachmentList
      });
    }
  };

  const deleteMessageTemplate = async () => {
    const {success} = await request(
      deleteMessageTemplateReq(user.id, messageTemplate.id)
    );

    if (success) {
      const index = messageTemplateList.findIndex(
        item => item.id === messageTemplate.id
      );

      setMessageTemplateList(
        update(messageTemplateList, {
          $splice: [[index, 1]]
        })
      );
    }

    return true;
  };

  const trashStyle = {
    position: 'absolute',
    top: 8,
    right: 8
  };

  return (
    <>
      <div className="product product_removable">
        <Button
          color="transparent"
          style={trashStyle}
          onClick={() => setIsConfirmationOpen(true)}
        >
          <Icons.Trash />
        </Button>

        <Wrapper gap={16} isColumn>
          <Title.H2>{messageTemplate.title}</Title.H2>

          {messageTemplate.message && (
            <Text
              maxHeight={112}
              style={{
                overflowWrap: 'anywhere',
                whiteSpace: 'break-spaces'
              }}
            >
              {messageTemplate.message}
            </Text>
          )}

          <Attachments count={messageTemplate.file_count} />
          {/* <Buttons buttons={messageTemplate.buttons} /> */}
        </Wrapper>

        <div>
          <Line />

          <Link isFake isBold onClick={() => setIsEditorOpen(true)}>
            {t`Show all settings`}
          </Link>
        </div>
      </div>

      {isEditorOpen && (
        <Editor
          headline={t`Edit the message template`}
          messageTemplate={messageTemplate}
          onSubmit={handleSubmit}
          onClose={() => setIsEditorOpen(false)}
        />
      )}

      {isConfirmationOpen && (
        <Confirmation
          title={t`Are you sure you want to delete this message template?`}
          onClick={res =>
            res ? deleteMessageTemplate() : setIsConfirmationOpen(false)
          }
        />
      )}
    </>
  );
};

MessageTemplate.propTypes = {
  messageTemplate: PropTypes.shape({
    buttons: PropTypes.string,
    date_add: PropTypes.number,
    id: PropTypes.number,
    message: PropTypes.string,
    title: PropTypes.string,
    file_count: PropTypes.number
  })
};

MessageTemplate.defaultProps = {
  messageTemplate: {}
};

export default MessageTemplate;
