import React from 'react';
import moment from 'moment';
import {t} from 'ttag';

import * as Title from '../../../title/title.jsx';
import Advice from '../../../advice/advice';
import Preloader from 'components/preloader/preloader.jsx';
import Wrapper from '../../../wrapper/wrapper.jsx';

import {useConnectionContext} from '../connection-context.js';
import {useConnectionListContext} from '../../connection-list-context.js';

const Condition = () => {
  const {client, setOpenConnectionId, isConnector} = useConnectionListContext();
  const {connection, isConnected, setIsPaymentOpen} = useConnectionContext();

  let date =
    connection.condition === 'trial'
      ? connection.date_trial
      : connection.condition === 'paid'
        ? connection.date_subscription
        : null;

  date = moment.unix(date).format('L');

  const paymentStatus =
    connection.condition == 'premium'
      ? {label: t`Premium`, type: 'star'}
      : connection.condition == 'trial'
        ? {label: t`Trial until ${date}`, type: 'info'}
        : connection.condition == 'paid'
          ? {label: t`Paid until ${date}`, type: 'success'}
          : {label: t`Requires payment`, type: 'warning'};

  return (
    <Wrapper noWrap>
      <Advice
        type={paymentStatus.type}
        isSmall
        onClick={
          paymentStatus.type == 'warning' && !isConnector
            ? () => setIsPaymentOpen(true)
            : undefined
        }
      >
        <Title.H3>{paymentStatus.label}</Title.H3>
      </Advice>

      {typeof isConnected == 'boolean' ? (
        <Advice
          type={isConnected ? 'success' : 'warning'}
          isSmall
          onClick={
            isConnected || client.isCustomer
              ? undefined
              : () => setOpenConnectionId(connection.id)
          }
        >
          <Title.H3>
            {isConnected
              ? connection.version == 'whatcrm'
                ? t`WhatsApp is connected`
                : connection.version == 'telegram'
                  ? t`Telegram is connected`
                  : t`Connected`
              : connection.version == 'whatcrm'
                ? t`WhatsApp is not connected`
                : connection.version == 'telegram'
                  ? t`Telegram is not connected`
                  : t`Not connected`}
          </Title.H3>
        </Advice>
      ) : (
        <Advice r style={{padding: 4}}>
          <Preloader style={{padding: 0}} isMin />
        </Advice>
      )}
    </Wrapper>
  );
};

export default Condition;
