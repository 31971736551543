import React from 'react';

import FeatureList from './feature-list/feature-list';
import OptionList from './option-list/option-list';
import Transition from './transition/transition';

const TariffBody = () => (
  <div className="tariff-plan__body">
    <div>
      <FeatureList />
      <OptionList />
    </div>

    <Transition />
  </div>
);

export default TariffBody;
