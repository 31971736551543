export const getConnectionConfigReq = ({domain, id}) => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'get_user_phone', id}
});

export const getManagerListReq = domain => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'managers'}
});

export const getPipelineListReq = domain => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'pipelines'}
});

export const updateConnectionConfigReq = ({domain, id, data}) => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'set_pref', id},
  method: 'post',
  data
});
