import React from 'react';

import Wrapper from 'components/wrapper/wrapper';
import {useMainContext} from '../main-context';

import Check from './check/check';

const CheckList = () => {
  const {checkList} = useMainContext();

  return (
    <Wrapper gap={32} isColumn>
      {checkList?.map((item, i) => <Check check={item} key={i} />)}
    </Wrapper>
  );
};

export default CheckList;
