import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

export interface Client {
  crm: AppEnv.Crm;
  domain: string;
  is_integration?: 0 | 1;
  isCustomer?: boolean;
}

interface PaymentContext {
  client: Client;
  closePayment: () => void;
  connection: Partial<AppEnv.Connection> | undefined;
  connectionIdList: number[] | undefined;
  connectionList: AppEnv.Connection[] | undefined;
  isFeatureListOpen: boolean;
  selectedTariffId: number | undefined;
  setIsFeatureListOpen: AppEnv.SetState<boolean>;
  setSelectedTariffId: AppEnv.SetState<number | undefined>;
  tariffList: AppEnv.Tariffs | undefined;
}

const PaymentContext = React.createContext<PaymentContext>(
  {} as PaymentContext
);

export const usePaymentContext = () => useContext(PaymentContext);
export default PaymentContext;
