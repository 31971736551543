import React from 'react';
import {t} from 'ttag';
import Popup from '../../../../popup/popup.jsx';
import Wrapper from '../../../../wrapper/wrapper.jsx';
import * as Title from '../../../../title/title.jsx';
import Text from '../../../../text/text.jsx';
import Icons from '../../../../icons/icons.jsx';
import Line from '../../../../line/line.jsx';
import Button from '../../../../button/button';
import {useSettingsContext} from '../settings-context.js';

const Alert = () => {
  const {setIsAlertOpen} = useSettingsContext();

  return (
    <Popup onClick={() => setIsAlertOpen(false)}>
      <Wrapper alignItems="center">
        <Icons.Info />

        <div>
          <Title.H2 style={{marginBottom: 8}}>{t`Update your plan`}</Title.H2>

          <Text>{t`This feature is available on Pro and Enterprise plans.`}</Text>
        </div>
      </Wrapper>

      <Line />

      <Button onClick={() => setIsAlertOpen(false)}>{t`OK`}</Button>
    </Popup>
  );
};

export default Alert;
