import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface CheckerContext {
  data: AppEnv.CheckerData | undefined;
  setData: AppEnv.SetState<AppEnv.CheckerData | undefined>;
}

const CheckerContext = React.createContext<CheckerContext>(
  {} as CheckerContext
);

export const useCheckerContext = () => useContext(CheckerContext);
export default CheckerContext;
