import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import Option from './option/option.jsx';

const OptionList = ({matchingOptionIds, options, onChange, toggleAll}) => {
  const isAllSelected = !options.find(item => !item.isChecked);

  return (
    <div className="select__options" style={{gap: 12}}>
      {options.length > 1 && (
        <Option
          option={{
            label: t`Select all`,
            value: 'selectAll',
            isChecked: isAllSelected
          }}
          onChange={() => toggleAll(isAllSelected ? false : true)}
        />
      )}

      {options.map(item => (
        <Option
          key={item.value}
          option={item}
          matchingOptionIds={matchingOptionIds}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

OptionList.propTypes = {
  options: PropTypes.array,
  matchingOptionIds: PropTypes.array,
  onChange: PropTypes.func,
  toggleAll: PropTypes.func
};

OptionList.defaultProps = {
  options: [],
  matchingOptionIds: [],
  onChange: () => {},
  toggleAll: () => {}
};

export default OptionList;
