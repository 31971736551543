import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import * as Title from 'components/title/title.jsx';
import {MultiSelect} from 'components/form/form.jsx';

import {useConnectionListContext} from 'components/connection-list/connection-list-context.js';
import {useConnectionContext} from 'components/connection-list/connection/connection-context.js';
import {useSettingsContext} from '../../../settings-context.js';

const ManagerId = () => {
  const {managerList} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField('responsible_ids');
  const [pipelineIdField] = useField('pipeline_id');

  const handleChange = value => {
    const newValue = value.reduce((acc, item) => {
      if (item.isChecked) acc.push(item.value);
      return acc;
    }, []);

    helpers.setValue(newValue);
  };

  const options = managerList.map(item => ({
    label: item.fullname,
    value: item.crm_user_id,
    isChecked:
      pipelineIdField.value == 0
        ? false
        : field.value?.includes(item.crm_user_id) || false
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Managers`}</Title.H3>

      <MultiSelect
        options={options}
        value={
          connection.is_limited || pipelineIdField.value == 0
            ? undefined
            : field.value
        }
        placeholder={t`Managers`}
        isDisabled={
          connection.is_limited || !options.length || pipelineIdField.value == 0
        }
        isSearchAvailable={options.length > 7}
        onChange={handleChange}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
      />
    </div>
  );
};

export default ManagerId;
