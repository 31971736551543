import React from 'react';
import {t} from 'ttag';
import Text from '../../../../text/text.jsx';
import {useConnectionContext} from '../../connection-context.js';

const Username = () => {
  const {connection} = useConnectionContext();

  return <>{connection.name && <Text>{t`Name: ${connection.name}`}</Text>}</>;
};

export default Username;
