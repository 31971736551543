import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import EmojiMart from '@emoji-mart/react';
import data from '@emoji-mart/data';
import {useAppContext} from '../../../../../../app-context.js';
import {useOnClickOutside, useOnKeyDown} from '../../../../../../common/hooks';
import {definePosition} from '../../../../../../common/actions.js';

const EmojiPicker = ({triggerRef, onClose, onSelect}) => {
  const {lang} = useAppContext();

  const [position, setPosition] = useState({});

  const containerRef = useRef();
  const targetRef = useRef();

  useOnClickOutside({ref: targetRef, exception: triggerRef, onClick: onClose});
  useOnKeyDown({onEscape: onClose});

  const getPosition = () => {
    const triggerRect = triggerRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    const targetRect = targetRef.current.getBoundingClientRect();

    const res = definePosition({
      triggerPosition: {
        x: triggerRect.x + triggerRect.width,
        y: triggerRect.y
      },
      startingPoint: {
        x: containerRect.x,
        y: containerRect.y
      },
      targetSize: {
        width: targetRect.width,
        height: targetRect.height
      }
    });

    setPosition(res);
  };

  useEffect(() => {
    setTimeout(() => {
      getPosition();
    });
  }, []);

  // eslint-disable-next-line
  const i18n = require(`@emoji-mart/data/i18n/${lang}.json`);

  return (
    <div ref={containerRef} className="emoji-picker">
      <div
        ref={targetRef}
        className="emoji-picker__wrapper"
        style={{
          ...position,
          opacity: Object.keys(position).length > 0 ? 1 : 0
        }}
      >
        <EmojiMart
          i18n={i18n}
          data={data}
          onEmojiSelect={({native}) => onSelect(native)}
        />
      </div>
    </div>
  );
};

EmojiPicker.propTypes = {
  triggerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  onClose: PropTypes.func,
  onSelect: PropTypes.func
};

EmojiPicker.defaultProps = {
  triggerRef: {},
  onClose: () => {},
  onSelect: () => {}
};

export default EmojiPicker;
