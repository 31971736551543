import React from 'react';

import Button from '../../../button/button';
import Icons from '../../../icons/icons.jsx';
import * as AppEnv from 'app-env';

interface Eye {
  htmlType: string;
  inputRef:
    | React.RefObject<HTMLInputElement>
    | ((instance: HTMLInputElement | null) => void);
  setHtmlType: AppEnv.SetState<string>;
}

const Eye = ({htmlType, setHtmlType, inputRef}: Eye) => {
  const handleClick = () => {
    setHtmlType(htmlType === 'password' ? 'text' : 'password');

    if (!('current' in inputRef) || !inputRef.current) return;

    inputRef.current.focus();
    const {selectionEnd} = inputRef.current;

    setTimeout(() => {
      if (inputRef.current) inputRef.current.selectionStart = selectionEnd;
    });
  };

  return (
    <Button color="transparent" onClick={handleClick}>
      {htmlType === 'password' ? <Icons.OpenEye /> : <Icons.ClosedEye />}
    </Button>
  );
};

export default Eye;
