import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import Checker from 'pages/checker/checker';
import Connector from './pages/connector/connector.jsx';
import Error404 from './pages/error-404/error-404.jsx';
import Home from './pages/home/home.jsx';
import InstallationFailed from './pages/installation-failed/installation-failed.jsx';
import Login from './pages/login/login.jsx';
import Notices from './pages/notices/notices.jsx';
import Scanner from './pages/scanner/scanner.jsx';

import Footer from './components/footer/footer.jsx';
import Header from './components/header/header.jsx';

import {useAppContext} from './app-context.js';

const Router = () => {
  const {user} = useAppContext();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/checker/userfields" element={<Checker />} />
        <Route path="/connector/:chat_id" element={<Connector />} />

        <Route
          path="*"
          element={
            <>
              <Header />

              <div className="body">
                <Routes>
                  <Route path="/checker/*" element={<Checker />} />

                  <Route
                    path="/installation-failed"
                    element={<InstallationFailed />}
                  />

                  <Route path="/login" element={<Login />} />

                  <Route path="/scanner/:chat_key" element={<Scanner />} />

                  <Route
                    path="*"
                    element={
                      user.domain ? (
                        <Routes>
                          <Route path="/" element={<Home />} />

                          <Route path="/notices/:id" element={<Notices />} />

                          <Route path="*" element={<Error404 />} />
                        </Routes>
                      ) : (
                        <Navigate
                          to={{
                            pathname: '/login',
                            search: window.location.search
                          }}
                        />
                      )
                    }
                  />
                </Routes>
              </div>

              <Footer />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
