import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import Wrapper from '../../../../../../wrapper/wrapper.jsx';
import {Toggle} from '../../../../../../form/form.jsx';
import {useConnectionContext} from '../../../../connection-context.js';
import {useSettingsContext} from '../../../settings-context.js';

const CreateNewIfClose = () => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField('create_new_if_close');
  const [pipelineIdField] = useField('pipeline_id');

  return (
    <Wrapper alignItems="center">
      <Toggle
        label={t`Create deals if there are closed ones`}
        isChecked={
          connection.is_limited || pipelineIdField.value == 0
            ? false
            : field.value
        }
        isDisabled={connection.is_limited || pipelineIdField.value == 0}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
        onChange={helpers.setValue}
      />
    </Wrapper>
  );
};

export default CreateNewIfClose;
