import React, {useState, useEffect} from 'react';
import {t} from 'ttag';

import {Toggle} from 'components/form/form';
import Article from 'components/article/article';
import Text from 'components/text/text';
import {useAppContext} from 'app-context';
import {useCheckerContext} from 'pages/checker/checker-context';
import {useCheckerRequest} from 'common/hooks';

const Phone = () => {
  const {user} = useAppContext();
  const {data} = useCheckerContext();

  const [isChecked, setIsChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {postSettings} = useCheckerRequest();

  useEffect(() => {
    if (typeof data?.phone_update == 'boolean') setIsChecked(data.phone_update);
  }, [data?.phone_update]);

  const handleChange = async (value: boolean) => {
    setIsFetching(true);
    const res = await postSettings(user.domain, 'phone', value);

    setIsFetching(false);
    if (!res) return;

    setIsChecked(value);
  };

  return (
    <div>
      <Toggle
        label={t`Add a different phone number to the entity`}
        style={{marginBottom: 8}}
        isChecked={isChecked}
        isDisabled={isFetching}
        onChange={value => handleChange(value)}
      />

      <Article>
        <Text isSmall>
          {t`The found phone number will be added to the entity if it is different from the number being checked.`}
        </Text>
      </Article>
    </div>
  );
};

export default Phone;
