export const checkIsAccountExistReq = ({crm, domain}) => ({
  path: 'v3/integrations/prop',
  params: {crm, domain}
});

export const fetchChatResuscitate = id => ({
  path: 'v3/chat/resuscitate',
  params: {id}
});

export const sendVerificationCodeReq = email => ({
  path: 'v3/email/send-md5',
  method: 'post',
  data: {email, generate_code: 1}
});

export const checkVerificationCodeReq = ({email, code}) => ({
  path: 'v3/email/prop-code',
  method: 'post',
  data: {email, code}
});

export const registerUserReq = ({email, lang, widget_code}) => ({
  path: 'v3/integrations/install',
  params: {crm: 'LK'},
  method: 'post',
  data: {
    domain: email,
    email,
    lang,
    widget_code,
    users: [
      {
        id: Date.now(),
        name: email,
        email
      }
    ]
  }
});

export const getDashboardReq = (
  crm,
  domain,
  lang,
  crm_param_id,
  telphin_code,
  token
) => ({
  path: 'v3/dashboard',
  params: {crm, crm_param_id, domain, lang, telphin_code, token}
});

export const getConnectionListReq = ({crm, domain, integration_id}) => ({
  path: 'v3/integrations/instances',
  params: {crm, domain, integration_id}
});

export const createConnectionReq = (crm, domain, version, label) => ({
  path: 'v3/chat/spare',
  params: {crm, domain, version},
  method: 'post',
  data: {label}
});

export const getConnectionStatusReq = ({chat_key, full}) => ({
  path: `v3/instance${chat_key}/status`,
  params: full ? {full: 1} : {}
});

export const updateConnectionReq = ({chat_key, data}) => ({
  path: `v3/chat/update`,
  params: {id: chat_key},
  method: 'post',
  data
});

export const deleteConnectionReq = chat_key => ({
  path: 'v3/chat/free',
  params: {id: chat_key}
});

export const getTariffListReq = ({country, crm, domain, isPartner, lang}) => {
  const currency = lang === 'ru' && country !== 'UA' ? 'RUB' : 'USD';

  return {
    path: 'v3/tariffs',
    params: {
      crm: crm,
      currency: currency,
      domain,
      group: 1,
      lang: lang,
      partner: isPartner
    }
  };
};

export const checkAuthenticationPassword = ({chat_key, password}) => ({
  path: `v3/instance${chat_key}/checkAuthenticationPassword`,
  params: {password}
});

export const getEmployeeListReq = integration_id => ({
  path: 'v3/managers/list',
  params: {integration_id}
});

export const updateEmployeeReq = (integration_id, id, data) => ({
  path: 'v3/managers/update',
  params: {
    integration_id,
    id
  },
  method: 'post',
  data
});

export const deleteEmployeeReq = (integration_id, id) => ({
  path: 'v3/managers/delete',
  params: {
    integration_id,
    id
  }
});

export const createEmployeeReq = (integration_id, data) => ({
  path: 'v3/managers/create',
  params: {integration_id},
  method: 'post',
  data
});

export const getDenyListReq = integration_id => ({
  path: `v3/blacklist`,
  params: {integration_id}
});

export const createDeniedReq = (integration_id, phone) => ({
  path: `v3/blacklist/create`,
  params: {integration_id},
  data: {phone},
  method: 'post'
});

export const deleteDeniedReq = (integration_id, id) => ({
  path: `v3/blacklist/delete`,
  params: {integration_id, id}
});

export const getCustomerListReq = integration_id => ({
  path: 'v3/partners/list',
  params: {integration_id}
});

export const addCustomerReq = (integration_id, data) => ({
  path: 'v3/partners/add',
  params: {integration_id},
  method: 'post',
  data
});

export const deleteCustomerReq = (integration_id, data) => ({
  path: 'v3/partners/delete',
  params: {integration_id},
  method: 'post',
  data
});

export const cancelPartnershipReq = integration_id => ({
  path: 'v3/partners/cancel',
  params: {integration_id}
});

export const getPaymentHistoryReq = integration_id => ({
  path: 'v3/payments',
  params: {integration_id}
});

export const updateUserReq = (integration_id, data) => ({
  path: 'v3/integrations/update',
  params: {integration_id},
  method: 'post',
  data
});

export const createTelphinClientReq = (integration_id, data) => ({
  path: 'v3/telphin/create',
  params: {integration_id},
  method: 'post',
  data
});

export const getIntegrationListReq = () => ({path: 'v3/integrations/others'});

export const getIntegratorReq = integration_id => ({
  path: 'v3/integrators/index',
  params: {integration_id}
});

export const getIntegrationTokenReq = integration_id => ({
  path: 'v3/integrators/generation',
  params: {integration_id}
});

export const updateIntegratorReq = (integration_id, data) => ({
  path: 'v3/integrators/update',
  params: {integration_id},
  method: 'post',
  data
});

export const loadDataReq = ({file, category}) => ({
  path: 'v3/files/load-data',
  params: {category},
  method: 'post',
  data: {body: file},
  isFormData: true,
  url:
    window.location.host.slice(-2) == 'ru'
      ? process.env.REACT_APP_FILES
      : undefined
});

export const deleteMessageTemplateReq = (integration_id, id) => ({
  path: 'v3/templates/delete',
  params: {integration_id, id}
});

export const triggerReviewReq = integration_id => ({
  path: 'v3/integrations/review',
  params: {integration_id}
});

export const createMessageTemplateReq = (integration_id, data) => ({
  path: 'v3/templates/create',
  params: {integration_id},
  method: 'post',
  data
});

export const updateMessageTemplateReq = (integration_id, id, data) => ({
  path: 'v3/templates/update',
  params: {integration_id, id},
  method: 'post',
  data
});

export const getAttachmentListReq = (integration_id, template_id) => ({
  path: 'v3/templates/files',
  params: {integration_id, template_id}
});

export const addAttachmentReq = (integration_id, template_id, data) => ({
  path: 'v3/templates/file-add',
  params: {integration_id, template_id},
  method: 'post',
  data
});

export const deleteAttachmentReq = (integration_id, template_id, id) => ({
  path: 'v3/templates/file-delete',
  params: {
    integration_id,
    template_id,
    id
  }
});

export const getSubscriptionListReq = integration_id => ({
  path: 'v3/subscriptions',
  params: {integration_id}
});

export const getMessageTemplateAttachmentReq = (
  integration_id,
  template_id,
  id
) => ({
  path: 'v3/templates/file',
  params: {integration_id, template_id, id}
});

export const getNoticeReq = ({crm, id}) => ({
  path: 'v3/notifications/one',
  params: {crm, id}
});

export const getIntegratorClientListReq = integration_id => ({
  path: 'v3/integrators/clients',
  params: {integration_id}
});

export const createPartnerReq = (integration_id, partnerDomain) => ({
  path: 'v3/integrations/partner',
  params: {integration_id},
  method: 'post',
  data: {domain_partner: partnerDomain}
});

export const destroyInstanceReq = chat_key => ({
  path: `v3/instance${chat_key}/destroy`
});

export const setAuthenticationPhoneNumberReq = (chat_key, phone) => ({
  path: `v3/instance${chat_key}/setAuthenticationPhoneNumber`,
  params: {phone}
});

export const checkAuthenticationCodeReq = (chat_key, code) => ({
  path: `v3/instance${chat_key}/checkAuthenticationCode`,
  params: {code}
});

export const requestQrCodeAuthenticationReq = chat_key => ({
  path: `v3/instance${chat_key}/requestQrCodeAuthentication`
});

export const getInstanceStateReq = chat_key => ({
  path: `v3/instance${chat_key}/state`
});

export const getChatInfoReq = chat_key => ({
  path: `v3/chat/info`,
  params: {id: chat_key}
});
