import React, {useState} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import Button from 'components/button/button';
import Wrapper from 'components/wrapper/wrapper';
import {useAppContext} from 'app-context';
import {useCheckerRequest} from 'common/hooks';
import {useMainContext} from '../../../main-context';
import * as AppEnv from 'app-env';

interface Actions {
  check: AppEnv.Check;
}

const Actions = ({check}: Actions) => {
  const {user} = useAppContext();
  const {checkList, setCheckList} = useMainContext();

  const [isSubmitting, setIsSibmitting] = useState(false);
  const {postCreate} = useCheckerRequest();

  const handleClick = async (placement: 'CONTACT' | 'DEAL' | 'LEAD') => {
    setIsSibmitting(true);
    const res = await postCreate(user.domain, check.code.phone, placement);

    setIsSibmitting(false);
    if (!res) return;

    const index = checkList?.findIndex(
      item => item.code.phone == check.code.phone
    );

    if (typeof index != 'number' || index < 0) return;

    setCheckList(prevValue =>
      update(prevValue, {[index]: {entities: {$set: [res]}}})
    );
  };

  const isBitrix24 = user.domain.includes('bitrix24');

  return (
    <Wrapper gap={8}>
      <Button
        color="white"
        isDisabled={isSubmitting}
        onClick={() => handleClick('DEAL')}
      >
        {t`Create a deal`}
      </Button>

      <Button
        color="white"
        isDisabled={isSubmitting}
        onClick={() => handleClick(isBitrix24 ? 'LEAD' : 'CONTACT')}
      >
        {isBitrix24 ? t`Create a lead` : t`Create a contact`}
      </Button>
    </Wrapper>
  );
};

export default Actions;
