import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import * as Title from 'components/title/title.jsx';
import {MultiSelect} from 'components/form/form.jsx';

import {useConnectionListContext} from 'components/connection-list/connection-list-context.js';
import {useConnectionContext} from 'components/connection-list/connection/connection-context.js';
import {useSettingsContext} from '../../../settings-context.js';

const ManagerId = () => {
  const {managerList, pipelineList} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField('pipeline_settings');
  const {value: pipeline_settings} = field;
  const {setValue: setPipelineSettings} = helpers;

  const {pipe_id, status_id} = pipeline_settings[0] || {};

  const handleChange = managerIdList =>
    setPipelineSettings(
      managerIdList.reduce((acc, item) => {
        if (item.isChecked) {
          acc.push({
            pipe_id: pipe_id,
            status_id: status_id,
            manager_id: item.value
          });
        }

        return acc;
      }, [])
    );

  const {statuses} = pipelineList.find(item => item.id === pipe_id) || {};

  const isUnassembledStage = statuses
    ? !!statuses.find(item => item.id === status_id)?.unsorted
    : false;

  const options =
    managerList && !isUnassembledStage
      ? managerList.map(item => ({
          value: item[0],
          label: item[1],
          isChecked: !!pipeline_settings.find(
            instance => instance.manager_id === item[0]
          )
        }))
      : [];

  const isDisabled =
    connection.is_limited ||
    !managerList.length ||
    isUnassembledStage ||
    !status_id;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Managers`}</Title.H3>

      <MultiSelect
        options={options}
        placeholder={t`Managers`}
        isDisabled={isDisabled}
        isSearchAvailable
        onChange={handleChange}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
      />
    </div>
  );
};

export default ManagerId;
