import React from 'react';
import {useField} from 'formik';
import {Input} from '../../../../../../../form/form.jsx';

const Score = () => {
  const [field, meta, helpers] = useField('score');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      inputMode="tel"
      placeholder="Расчетный счет"
      isValid={isValid}
      onChange={value => setValue(value.toUpperCase())}
    />
  );
};

export default Score;
