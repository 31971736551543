import React from 'react';
import {useField} from 'formik';
import {SingleSelect} from '../../../../../../../form/form.jsx';

const Docs = () => {
  const [field, , helpers] = useField('docs');

  const {setValue} = helpers;

  const options = [
    {
      value: '3',
      label: '«Диадок-Контур»'
    },
    {
      value: '5',
      label: '«Сбис-Тензор»'
    },
    {
      value: '1',
      label: 'Другое'
    }
  ];

  return (
    <SingleSelect
      {...field}
      options={options}
      onChange={setValue}
    />
  );
};

export default Docs;
