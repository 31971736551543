import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {Formik, Form} from 'formik';
import Domains from './domains/domains.jsx';
import {t} from 'ttag';
import * as Title from '../title/title.jsx';

const Filter = ({domains, style, onChange}) => {
  const handleSubmit = values => {
    const res = {
      domains: values.domains.reduce((acc, item) => {
        if (item.isChecked) {
          acc.push(item.value);
        }

        return acc;
      }, [])
    };

    onChange(res);
    return true;
  };

  const domainOptions = domains.reduce((acc, domain) => {
    if (!acc.find(item => item.value === domain)) {
      acc.push({value: domain, label: domain});
    }

    return acc;
  }, []);

  const initialValues = {domains: domainOptions};
  const validationSchema = yup.object().shape({domains: yup.array()});

  return (
    <>
      {domainOptions.length > 1 && (
        <div style={style}>
          <Title.H2 style={{marginBottom: 8}}>{t`Filter`}</Title.H2>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Domains />
            </Form>
          </Formik>
        </div>
      )}
    </>
  );
};

Filter.propTypes = {
  domains: PropTypes.array,
  style: PropTypes.object,
  onChange: PropTypes.func
};

Filter.defaultProps = {
  domains: [],
  style: {},
  onChange: () => {}
};

export default Filter;
