import React from 'react';
import {t} from 'ttag';

import EmptyBox from 'components/empty-box/empty-box';
import {useCheckerContext} from 'pages/checker/checker-context';

const Placeholder = () => {
  const {data} = useCheckerContext();

  const title =
    data?.message == 'phone fields are empty'
      ? t`Could not to check the phone number`
      : undefined;

  return <EmptyBox title={title} />;
};

export default Placeholder;
