import React, {useState} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import Article from 'components/article/article';
import Text from 'components/text/text';
import Link from 'components/link/link';
import {useAppContext} from 'app-context';
import {useCheckerContext} from 'pages/checker/checker-context';
import {useCheckerRequest} from 'common/hooks';

const Bizproc = () => {
  const {user} = useAppContext();
  const {setData} = useCheckerContext();

  const [isFetching, setIsFetching] = useState(false);
  const {postSettings} = useCheckerRequest();

  const handleClick = async () => {
    setIsFetching(true);
    const res = await postSettings(user.domain, 'bizproc', true);

    setIsFetching(false);
    if (!res) return;

    setData(prevValue => update(prevValue, {bizproc: {$set: true}}));
  };

  return (
    <Article>
      <Text color="dark">
        {t`Business processes are not set up. Please note that business processes in Bitrix24 are available in the plan Professional and higher.`}{' '}
        <Link isDisabled={isFetching} isFake onClick={handleClick}>
          {t`Turn on`}
        </Link>
      </Text>
    </Article>
  );
};

export default Bizproc;
