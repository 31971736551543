import React from 'react';
import {t} from 'ttag';

import * as Title from 'components/title/title';
import Text from 'components/text/text';

interface Header {
  style?: React.CSSProperties;
}

const Header = ({style}: Header) => (
  <div style={style}>
    <Title.H1 style={{marginBottom: 8}}>{'Whatcrm Checker'}</Title.H1>
    <Text>{t`Phone number check in WhatsApp.`}</Text>
  </div>
);

export default Header;
