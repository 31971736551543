import React from 'react';

import CheckRes from '../components/check-res/check-res';
import Header from '../components/header/header';
import Placeholder from '../components/placeholder/placeholder';
import {useCheckerContext} from '../checker-context';

const Tab = () => {
  const {data} = useCheckerContext();

  return (
    <div className="page">
      <Header style={{marginBottom: 32}} />
      {data?.code ? <CheckRes codeList={data.code} /> : <Placeholder />}
    </div>
  );
};

export default Tab;
