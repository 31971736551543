import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import Wrapper from '../../../../../wrapper/wrapper.jsx';
import {Checkbox} from '../../../../../form/form.jsx';
import Tooltip from '../../../../../tooltip/tooltip.jsx';
import Text from '../../../../../text/text.jsx';
import Link from '../../../../../link/link.jsx';

const IsLink = () => {
  const [field, , helpers] = useField('is_link');

  return (
    <Wrapper alignItems="center">
      <Checkbox
        label={t`Shorten links`}
        isChecked={!!field.value}
        onChange={value => helpers.setValue(value ? 1 : 0)}
      />

      <Tooltip>
        <Text>
          {t`Every link you send will be replaced by a short link. This will allow you to track link clicks. When a person clicks it, a note will be added to the contact. You can create your own short links for individual sources at`}
          {' '}
          <Link
            href="https://dev.link.whatcrm.net"
            target="_blank"
          >
            link.whatcrm.net
          </Link>
          .
        </Text>
      </Tooltip>
    </Wrapper>
  );
};

export default IsLink;
