import React from 'react';
import {Formik, Form} from 'formik';
import {t} from 'ttag';
import * as yup from 'yup';

import {SaveButton} from '../../../components/button/button';
import * as Title from '../../../components/title/title.jsx';
import Email from './email/email.jsx';
import Line from '../../../components/line/line.jsx';
import Text from '../../../components/text/text.jsx';
import {useLoginContext} from '../login-context.js';
import {useRequest} from '../../../common/hooks';
import {checkIsAccountExistReq} from '../../../common/api/api.js';

const Start = () => {
  const {setEmail, setIsRegistered} = useLoginContext();

  const {request} = useRequest();

  const nextStep = (email, isRegistered) => {
    setEmail(email);
    setIsRegistered(isRegistered);
  };

  const handleSubmit = async (values, helpers) => {
    const email = values.email.toLowerCase().trim();

    const res = await request(
      checkIsAccountExistReq({
        crm: 'LK',
        domain: email
      })
    );

    if (!('integration' in res)) return;

    if (
      email.endsWith('.ru') ||
      email.endsWith('.рф') ||
      res.integration ||
      !window.location.host.endsWith('.ru')
    )
      return nextStep(email, res.integration);

    helpers.setFieldError('email', 'ru');
  };

  const initialValues = {email: ''};

  const validationSchema = yup.object().shape({
    email: yup.string().email().required()
  });

  return (
    <>
      <Title.H2 style={{marginBottom: 8}}>{t`Sign in to Whatcrm`}</Title.H2>

      <Text style={{marginBottom: 16}}>
        {t`Sign in to your account or create a new one.`}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Email />
          <Line />
          <SaveButton>{t`Continue`}</SaveButton>
        </Form>
      </Formik>
    </>
  );
};

export default Start;
